import React, { useState, useRef, useEffect } from "react";
import { Input, Select, Option, Button } from "@material-tailwind/react";
import CircularProgress from '@mui/material/CircularProgress';
import { GiCardExchange } from "react-icons/gi";
import { useGetCurrencyQuery, useEditCurrencyMutation } from "../../api/currencySlice";
import { useSelector, useDispatch } from "react-redux";
import { addLstCurrency } from "../../api/storeSlice";

const CurrencyExchange = () => {
    //API Calling
    const { data, isLoading , isSuccess, refetch} = useGetCurrencyQuery();
    const [ editCurrency, result] = useEditCurrencyMutation();

    const [lstCurrency, setLstCurrency] = useState([]);
    const rtkLstCurrency = useSelector((state) => state.store.lstCurrency);
    const dispatch = useDispatch();

    const [id, setId] = useState();
    const [currency, setCurrency] = useState();
    const [changePrice, setChangePrice] = useState();

    const refUs = useRef();
    const refYuan = useRef();
    const refBaht = useRef();

    const [isLoadingD , setIsLoadingD] = useState(false);
    const [isLoadingY , setIsLoadingY] = useState(false);
    const [isLoadingB , setIsLoadingB] = useState(false);

    useEffect(()=>{
        console.log(rtkLstCurrency)
        refUs.current.value = rtkLstCurrency[0].ChangePrice;
        refYuan.current.value = rtkLstCurrency[1].ChangePrice;
        refBaht.current.value = rtkLstCurrency[2].ChangePrice;
    },[])


    useEffect(()=>{
        setLstCurrency(rtkLstCurrency);
        refUs.current.value = rtkLstCurrency[0].ChangePrice;
        refYuan.current.value = rtkLstCurrency[1].ChangePrice;
        refBaht.current.value = rtkLstCurrency[2].ChangePrice;
    },[rtkLstCurrency])


    const editHandler =  async(e) =>{
        e.preventDefault();
        let body = {
            id: id,
            currency: currency,
            ChangePrice: changePrice,
          };
          console.log(body)
      
          //await editCurrency(body);
          refetch();
    }

    useEffect(()=>{
        if(isSuccess){
            dispatch(addLstCurrency(data?.data))
        }
    },[data?.data])

    
    return (
        <div className="flex flex-col gap-4">
          {/* Caption */}
          <div className="flex flex-row w-full justify-start">
            <p className="px-4 py-2 bg-[#57626c] rounded-lg text-white font-bold">
              Currency Exchange Rate
            </p>
          </div>
    
          {/* Add New Section */}
          
            <div className="flex flex-col w-full items-center border-2 rounded-lg shadow-sm p-8">
                
                <div className="grid grid-cols-1 gap-8 w-2/5 items-center">
                    <form encType="multipart/form-data" className="flex flex-row justify-between items-center w-full mb-4 gap-3"
                    onSubmit={(e)=>{
                        setIsLoadingD(true);
                        setId(rtkLstCurrency[0].id);
                        setCurrency(rtkLstCurrency[0].currency);
                        setChangePrice(refUs.current.value);
                        e.preventDefault();
                        let body = {
                            id: rtkLstCurrency[0].id,
                            currency: rtkLstCurrency[0].currency,
                            ChangePrice: refUs.current.value,
                        };
                        console.log(body)
                    
                         editCurrency(body);
                        refetch();
                        setIsLoadingD(false);
                    }}>
                        <div className=" w-44 ">1 Dollar - </div>
                        <Input size="lg" label="Kyats" className="w-16" inputRef={refUs} required />
                        <Button type="submit" variant="outlined">
                            {isLoadingD ? <CircularProgress />: "Change"}
                        </Button>
                    </form>
                </div>
                
                
                <div className="grid grid-cols-1 gap-8 w-2/5 items-center">
                    <form encType="multipart/form-data" className="flex flex-row items-center w-full mb-4 gap-3"
                        onSubmit={(e)=>{
                            setId(rtkLstCurrency[1].id);
                            setCurrency(rtkLstCurrency[1].currency);
                            setChangePrice(refYuan.current.value);
                            e.preventDefault();
                            let body = {
                                id: rtkLstCurrency[1].id,
                                currency: rtkLstCurrency[1].currency,
                                ChangePrice: refYuan.current.value,
                              };
                              console.log(body)
                          
                                 editCurrency(body);
                              refetch();
                        }}
                    >
                        <div className=" w-44 ">1 Yuan - </div>
                        <Input size="lg" label="Kyats" className="w-16" inputRef={refYuan} required />
                        <Button type="submit" variant="outlined" className="flex items-center gap-3">
                            Change
                            {/* <GiCardExchange strokeWidth={2} className="h-5 w-5" /> */}
                        </Button>
                    </form>
                </div>
                
                <div className="grid grid-cols-1 gap-8 w-2/5 items-center">
                    <form encType="multipart/form-data" className="flex flex-row items-center w-full mb-4 gap-3"
                        onSubmit={(e)=>{
                            setId(rtkLstCurrency[2].id);
                            setCurrency(rtkLstCurrency[2].currency);
                            setChangePrice(refBaht.current.value);
                            e.preventDefault();
                            let body = {
                                id: rtkLstCurrency[2].id,
                                currency: rtkLstCurrency[2].currency,
                                ChangePrice: refBaht.current.value,
                            };
                            console.log(body)
                        
                             editCurrency(body);
                            refetch();
                        }}
                    >
                        <div className=" w-44 ">1 Baht - </div>
                        <Input size="lg" label="Kyats" className="w-16" inputRef={refBaht} required />
                        <Button type="submit" variant="outlined" className="flex items-center gap-3">
                            Change
                            {/* <GiCardExchange strokeWidth={2} className="h-5 w-5" /> */}
                        </Button>
                    </form>
                </div>
            </div>
          
        </div>
      );
}

export default CurrencyExchange