import React, { useState, useEffect } from 'react'
import { useLocation,useNavigate, Link } from 'react-router-dom';
import { Button, Input, Tooltip, IconButton } from '@material-tailwind/react';
import { useGetSupplierStatementMutation } from '../../api/supplierSlice';
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { FaClone } from "react-icons/fa";
const SupplierStatement = () => {
    const [trigger , {data, isLoading, isSuccess, refetch}] = useGetSupplierStatementMutation();
    const {state} = useLocation();
    const navigate = useNavigate();
    const [lstStatment, setLstStatement] = useState([]);

    useEffect(()=>{
        trigger(state.id)
    },[]);

    useEffect(()=>{
        if(isSuccess){
            const rows = data.data.map((element,index) => { return {...element, no:index+1,cash: element.net - element.bill,   }})
            setLstStatement(rows);
        }
    },[data?.data])

    //Table columns
  const columnsDetails =  [
    { field: "no",
        headerName: "No.",
        width: 50,
        headerAlign: 'right',
        align: 'right', 
    },
    {
      field: "id",
      headerName: "Voucher ID",
        width: 100,
      editable: false,
      headerAlign: 'right',
        align: 'right', 
    },
    {
      field: "net",
      headerName: "Total",
      width: 150,
      editable: false,
      headerAlign: 'right',
      align: 'right',
      type: 'number'
    },
    {
      field: "discount",
      headerName: "Discount",
      width: 100,
      editable: false,
      headerAlign: 'right',
      align: 'right',
      type: 'number'
    },
    {
      field: "cash",
      headerName: "Cash",
      width: 150,
      editable: false,
      headerAlign: 'right',
      align: 'right',
      type: 'number'
    },
    {
      field: "bill",
      headerName: "Credit",
      width: 150,
      editable: false,
      headerAlign: 'right',
      align: 'right',
      type: 'number'
    },
    {
        field: "buy_date",
        headerName: "Date",
        width: 150,
        editable: false,
        headerAlign: 'right',
        align: 'right', 
      },
    {
      field: "prodId",
      headerName: "Action",
      flex: 1,
      editable: false,
      headerAlign: 'center',
      align: 'center', 
      renderCell: (params) => (
        <div className="flex flex-row gap-2">
          <Tooltip content="Details" className="flex-1 bg-green-500">
                
                <IconButton

                  onClick={() => {

                  }}
                  className="w-6 h-6 bg-green-200 p-4"
                >
                  <FaClone className="h-4 w-4 text-green-600 font-extrabold" />
                </IconButton>
              </Tooltip>
        </div>
      ),
    },
  ];
  return (
    <div className="flex flex-col gap-4 px-16">
      {/* Caption */}
      <div className="flex flex-row w-full justify-between">
        <p className="px-4 py-2 bg-[#57626c] rounded-lg text-white font-bold">
          Supplier Statement
        </p>
        <Link to={`/supplier`}className='flex flex-col items-center text-center'>
        <Button 
              variant="outlined">
              {"Supplier List"}
        </Button>
        </Link>
      </div>

      {/* Add New Section */}
      <div className="flex flex-col md:flex-row gap-2 px-6 pt-6">
          {/* Product Details */}
          <div className="flex-1 w-1/4 p-2 gap-4 items-end flex-col justify-start">
            <div className="flex-1 my-8">
                <Input
                  size="sm"
                  label="Supplier Name"
                  variant="standard"
                  value={state.name}
                  readOnly
                />
              </div>
              <div className="flex-1 my-8">
                <Input
                  size="sm"
                  label="Phone"
                  variant="standard"
                  value={state.phone}
                  readOnly
                />
              </div>
              <div className="flex-1 my-8">
                <Input
                  size="sm"
                  label="Address"
                  variant="standard"
                  value={state.address}
                  readOnly
                />
              </div>
              <div className="flex-1 my-8">
                <Input
                  size="lg"
                  label="Total Credit"
                  variant="standard"
                  value={0}
                  readOnly
                />
              </div>
          </div>

          {/* Product Details List Table */}
          <div className="w-3/4">
            <DataGrid
                rows={lstStatment}
                columns={columnsDetails}
                rowHeight={80}
                initialState={{
                    pagination: {
                    paginationModel: {
                        pageSize: 10,
                    },
                    },
                }}
                density="compact"
                pageSizeOptions={[5,10,25,50,100]}
                disableRowSelectionOnClick
                slots={{ toolbar: GridToolbar }}
                slotProps={{
                    toolbar: {
                    showQuickFilter: true,
                    quickFilterProps: { debounceMs: 200 },
                    },
                }}
                />
          </div>
          </div>
    </div>
  )
}

export default SupplierStatement