import { ApiSlice } from "./ApiSlice";

export const purchaseSlice = ApiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getPurchaseVoucher: builder.query({
            query: () => '/purchase-voucher',
            providesTags: ['purchaseVoc'],
        }),

        getPurchaseVoucherById: builder.query({
            query: (id) => `/purchase-voucher/${id}`,
            providesTags: ['purchaseVoc'],
        }),

        addPurchaseVoucher: builder.mutation({
            query: (body) =>{
                console.log(Object.fromEntries(body))
                return ({
                    url: '/purchase-voucher',
                    method: 'POST',
                    body: body,
                    // headers :{
                    //     'enctype': 'multipart/form-data'
                    // }
                })
            } ,
            invalidatesTags:['purchaseVoc'],
        }),

        editPurchaseVoucher: builder.mutation({
            query: (body) =>({
                url: `/purchase-voucher/${body.get("id")}`,
                method: 'POST',
                body
            }),
            invalidatesTags:['purchaseVoc'],
        }),

        deletePurchaseVoucher: builder.mutation({
            query: (id) =>({
                url: `/purchase-voucher/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags:['purchaseVoc'],
        }),
    })
});

export const {
    useGetPurchaseVoucherQuery,
    useGetPurchaseVoucherByIdQuery,
    useAddPurchaseVoucherMutation,
    useEditPurchaseVoucherMutation,
    useDeletePurchaseVoucherMutation
} = purchaseSlice