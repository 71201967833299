import React, { useEffect, useRef } from 'react'
import { useState} from 'react'
import { useGetUnitQuery } from '../../api/unitSlice';
import { useGetProductQuery } from '../../api/productSlice';
import  SkeletonTable from "../../components/SkeletonTable";
import Swal from "sweetalert2";
import { 
  Button, 
  Spinner, 
  Card, 
  Input, 
  IconButton,
  Tooltip,
  Typography,
  Drawer,
 } from '@material-tailwind/react';
import { IoAddCircleOutline } from "react-icons/io5";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { useGetUnitJoinQuery, useAddUnitJoinMutation, useEditUnitJoinMutation, useDeleteUnitJoinMutation } from '../../api/unitJoinSlice';
import Select from 'react-select';
import { BiTrash } from "react-icons/bi";
import { FaEdit } from "react-icons/fa";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";

const UnitJoin = () => {
    // Api Calling
    const { data, isLoading, isSuccess, refetch } = useGetProductQuery();
    const {data:unitData, isLoading:isLoadingUJ, isSuccess:isSuccessUJ, refetch:refetchUJ} = useGetUnitJoinQuery();
    const {
        data: lstUnit,
        isLoading: isLoadingUnit,
        isSuccess: isSuccessUnit,
        refetch: refetchUnit,
      } = useGetUnitQuery();
    const [addUnitJoin, result] = useAddUnitJoinMutation();
    const [editUnitJoin, editResult] = useEditUnitJoinMutation();
    const [deleteUnitJoin, deleteResult] = useDeleteUnitJoinMutation();


    const [basicProductId, setBasicProductId] = useState();
    const [relatedProductId, setRelatedProductId] = useState();
    const [lstUnitJoin, setLstUnitJoin] = useState([]);

    const [editUnitJoinItem, setEditUnitJoinItem] = useState();

   

    const UnitJoinHandler = async(e) =>{
      let body = {
        fromUnit: basicProductId,
        toUnit: relatedProductId,
        qty: refCount.current.value,
      };
  
      await addUnitJoin(body);
      refCount.current.value = "";
  
      refetchUJ();
    }


    const editCountRef = useRef();
    const editUnitJoinHandler = async() =>{
      let body = {
        id: editUnitJoinItem.id,
        fromUnit: editUnitJoinItem.fromUnit,
        toUnit: editUnitJoinItem.toUnit,
        qty: parseInt(editCountRef.current.value),
      };

      await editUnitJoin(body);
      setOpen(false);
      refetchUJ();
    }
    
    const refCount = useRef();
    

    const [unitOptions, setUnitOptions] = useState([]);
    useEffect(()=>{
      setUnitOptions([]);
      data?.data.map((product,i)=> {
        setUnitOptions(current => [...current, {value: product.id, label: product.name + ", " + product.barcode+ ", " + product.unit.name}])

      });
    },[data?.data])

    useEffect(()=>{
      if (isSuccessUJ) {
  
        const rows = unitData?.data.map((p, index) =>
          isSuccess
            ? {
                ...p,
                no: index +1,
                fromUnitName: p.main_unit.name +", "+ p.main_unit.barcode + ", " + p.main_unit.unit.name ,
                toUnitName: p.sub_unit.name +", "+ p.sub_unit.barcode + ", " + p.sub_unit.unit.name,
                unitJoinId: p.id,
              }
            : { ...p }
        );
        setLstUnitJoin([...rows]);
      }
    },[unitData?.data])


    const columns = isSuccess
    ? [
        { field: "no", headerName: "No.", width: 100 },
        {
          field: "fromUnitName",
          headerName: "Basic Unit",
          editable: true,
          flex: 1
        },
        {
          field: "toUnitName",
          headerName: "Related Unit",
          editable: true,
          flex: 1
        },
        {
          field: "qty",
          headerName: "Count",
          sortable: false,
          flex: 1
        },
        {
          field: "unitJoinId",
          headerName: "Action",
          editable: false,
          flex: 1,
          renderCell: (params) => (
            <div className="flex flex-row gap-2 justify-center items-center">
              <Tooltip content="Edit" className="flex-1 bg-green-500">
                <IconButton

                  onClick={() => {

                    openDrawer(params.row);
                  }}
                  className="w-6 h-6 bg-green-200 p-4"
                >
                  <FaEdit className="h-4 w-4 text-green-600 font-extrabold" />
                </IconButton>
              </Tooltip>
              <Tooltip content="Delete" className="flex-1 bg-red-500">
                <IconButton onClick={() => confirmDelete(params.row.id)} variant="text" className="w-6 h-6 bg-red-200 p-4">
                  <BiTrash className="h-4 w-4 text-red-600" />
                </IconButton>
              </Tooltip>
            </div>
          ),
        },
      ]
    : [];

    const [open , setOpen]= useState(false);
    const [addNew, setAddNew] = useState(false);

    const openDrawer = (unitJoin) =>{
      setEditUnitJoinItem(unitJoin);
      setOpen(true);
    }
    const closeDrawer = () => setOpen(false);

    const deleteUnitJoinHandler = async(id) =>{
        await deleteUnitJoin(id);
        refetchUJ();
    }

    const confirmDelete = (id) => {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          deleteUnitJoinHandler(id);
          Swal.fire("Deleted!", "Your Shop has been deleted.", "success");
        }
      });
    };

  return (
    <div className="flex flex-col gap-4 px-16">
      {/* Drawer to Edit */}
      <React.Fragment>
        <Drawer placement="right" open={open} onClose={closeDrawer}>
          <div className="mb-2 flex items-center justify-between p-4">
            <Typography variant="h5" color="blue-gray">
              Edit Unit Join
            </Typography>
            <IconButton variant="text" color="blue-gray" onClick={closeDrawer}>
              <XMarkIcon strokeWidth={2} className="h-5 w-5" />
            </IconButton>
          </div>
          <form className="flex flex-col gap-6 p-4">
            <Input
              type="text"
              label="From Unit"
              value={open ?  editUnitJoinItem.main_unit.name + " " + editUnitJoinItem.main_unit.unit.name: ""}
            />
            <Input
              type="text"
              label="To Unit"
              value={open ? editUnitJoinItem.sub_unit.name + " " + editUnitJoinItem.sub_unit.unit.name : ""}
            />
            <Input
              type="text"
              label="Qty"
              defaultValue={open ? editUnitJoinItem.qty : ""}
              inputRef={editCountRef}
            />
            <Button onClick={editUnitJoinHandler}>
              {editResult.isLoading ? <Spinner /> : "Save"}
            </Button>
          </form>
        </Drawer>
      </React.Fragment>


      {/* Caption */}
      <div className="flex flex-row w-full justify-between">
        <p className="px-4 py-2 bg-[#57626c] rounded-lg text-white font-bold">
          Unit Join
        </p>
        <Button 
              variant="outlined" onClick={()=> setAddNew(!addNew)}>
              {addNew ? "Close": "Add New"}
        </Button>
      </div>

      {/* Add New Section */}
      <div className={`${addNew==  true ? 'w-full px-6 py-2 h-20 border-2 border-gray-200 xl:flex xl:flex-row gap-2 items-end grid grid-cols-1 justify-center rounded-xl': 'hidden'}`}>
        {/* <form className="flex flex-row items-end" action={UnitJoinHandler}> */}
        <div className=" w-32 md:w-96 m-2">
              <Select
              placeholder="Basic Unit"
              classNamePrefix="select"
              defaultValue={unitOptions[0]}
              name="basicUnit"
              options={unitOptions}
              onChange={(e)=>{
                setBasicProductId(e.value)
              }}
            />
          </div>
          <div className=" w-32 md:w-96 m-2">
              <Select
              placeholder="Related Unit"
              classNamePrefix="select"
              defaultValue={unitOptions[0]}
              name="relationUnit"
              options={unitOptions}
              onChange={(e)=>{
                setRelatedProductId(e.value)
              }}
            />
          </div>

          <div className='w-32 md:w-96 m-2'>
          <Input
              size="md"
              label="Relation Count"
              inputRef={refCount}
              onKeyDown={(e)=>{if(e.key == 'Enter'){
                UnitJoinHandler();
              }}}
              required
            />
          </div>
          <div className="flex-none m-2">
          <Button
            onClick={UnitJoinHandler}
              type="submit"
              variant="outlined"
              className="flex items-center gap-3 px-4 py-2 m-1"
            >
              {result.isLoading ? (
                <Spinner color="indigo" />
              ) : (
                <IoAddCircleOutline strokeWidth={2} className="h-5 w-5" />
              )}
              Add
            </Button>
          </div>
        {/* </form> */}
      </div>

      {/* Unit List Table */}
      <div className="w-full">
        <Card className="overflow-scroll h-full w-full">
          {isLoadingUJ ? (
            <SkeletonTable/>
          ) : (<DataGrid
            rows={lstUnitJoin}
            columns={columns}
            rowHeight={80}
            density="compact"
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 5,
                },
              },
            }}
            pageSizeOptions={[5]}
            disableRowSelectionOnClick
            slots={{ toolbar: GridToolbar }}
            slotProps={{
              toolbar: {
                showQuickFilter: true,
                quickFilterProps: { debounceMs: 500 },
              },
            }}
          />
          )}
        </Card>
      </div>
    </div>
  )
}

export default UnitJoin