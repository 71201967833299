import { createSlice, current } from "@reduxjs/toolkit";

const initialState = {
    lstVoucher: []
}

export const purchaseDetailsSlice = createSlice({
    name: 'purchaseDetails',
    initialState,
    reducers: {
        addVoucher: (state, action) =>{
            state.lstVoucher.push(action.payload);
            console.log(current(state.lstVoucher));
        },
        removeVoucher: (state, action)=>{
            const newList = state.lstVoucher.filter(obj => obj.voucherId !== action.payload);
            state.lstVoucher = newList.map(obj => obj);
        },
        addProduct2Voucher: (state, action)=>{
            const voc = state.lstVoucher.find(obj => {
                return obj.voucherId === action.payload.voucherId;
            });

            const exitItem = voc.lstProduct.find(obj =>  obj.id == action.payload.product.id);

            if(exitItem != null){
                const newList = voc.lstProduct.map(obj =>{ 
                    if(obj.id == exitItem.id){
                        return {
                            ...obj, 
                            qty: parseInt(obj.qty , 10 ) + parseInt(action.payload.product.qty,10), 
                            amount: (parseInt(obj.qty , 10 ) + parseInt(action.payload.product.qty,10)) * parseInt(obj.price,10)
                        }
                    }
                    return {...obj}
                })
                voc.lstProduct = newList.map(obj => obj);

            }else{
                voc.lstProduct.push(action.payload.product);
            }

            console.log(voc.lstProduct)
        },
        removeProductFromVoucher: (state, action) =>{
            const voc = state.lstVoucher.find(obj => {
                return obj.voucherId === action.payload.voucherId;
            });
            const newList = voc.lstProduct.filter(obj => obj.id !== action.payload.product.id);
            voc.lstProduct = newList.map(obj => obj);

        }
    },
})

export const { addVoucher, 
    addProduct2Voucher, 
    removeProductFromVoucher,
    removeVoucher,
} = purchaseDetailsSlice.actions;

export default purchaseDetailsSlice.reducer;