import React, { useEffect, useState } from "react";
import MaterialTable from "../../components/MaterialTable";
import DashboardCard from "../../components/DashboardCard";
import StackChart from "../../components/StackChart";
import OrderStatus from "../../components/OrderStatus";
import NotificationList from "../../components/NotificationList";
import MessageList from "../../components/MessageList";

import { FaRegChartBar, FaTruckMoving } from "react-icons/fa";
import { MdOutlinePersonOutline, MdSupervisorAccount } from "react-icons/md";
import { BsShop, BsCashCoin, BsCashStack } from "react-icons/bs";
import { AiOutlineCodeSandbox } from "react-icons/ai";
import { useGetDashboardDataQuery } from "../../api/dashboardSlice";



const Dashboard = () => {

  const { data, isLoading, isSuccess, refetch } = useGetDashboardDataQuery();
  const [dashboardInfo, setDashboardInfo] = useState();
  useEffect(()=>{
    if(isSuccess){
      console.log("first")
      setDashboardInfo(data)
      console.log(data.account)
    }
  },[isSuccess])


  const cards = dashboardInfo == null ? []: [
    {
      name: "Account",
      icon: <MdOutlinePersonOutline size={50} />,
      count: dashboardInfo.account,
      desp: "No Changes",
      color: "bg-blue-300",
    },
    {
      name: "Shop",
      icon: <BsShop size={50} />,
      count: dashboardInfo.shop,
      desp: "Add One More",
      color: "bg-cyan-300",
    },
    {
      name: "Supplier",
      icon: <FaTruckMoving size={50} />,
      count: dashboardInfo.supplier,
      desp: "New Supplier",
      color: "bg-green-300",
    },
    {
      name: "Product",
      icon: <AiOutlineCodeSandbox size={50} />,
      count: dashboardInfo.product,
      desp: "New Arrival",
      color: "bg-yellow-300",
    },
    {
      name: "Manager",
      icon: <MdSupervisorAccount size={50} />,
      count: 0,
      desp: "Remaing",
      color: "bg-red-300",
    },
    {
      name: "Income",
      icon: <BsCashStack size={50} />,
      count: `${dashboardInfo.income} Ks`,
      desp: "Increase",
      color: "bg-indigo-200",
    },
    {
      name: "CashOut",
      icon: <BsCashCoin size={50} />,
      count: "44",
      desp: "Normal",
      color: "bg-pink-300",
    },
    {
      name: "Report",
      icon: <FaRegChartBar size={50} />,
      count: "48",
      desp: "New Status",
      color: "bg-lime-300",
    },
  ];

  return (
    <div className="flex flex-col gap-4">
      <div className="flex-1 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4">
        {cards.map((card, key) => (
            <DashboardCard data={card} key={key} />
        ))}
      </div>
      <div className="flex-1 grid grid-cols-1">
        <StackChart />
      </div>
      <div className="flex-1 grid grid-cols-1 lg:grid-cols-3 gap-4">
        <OrderStatus />
        <NotificationList />
        <MessageList />
      </div>
      <div className="flex-1">
        <MaterialTable />
      </div>
    </div>
  );
};

export default Dashboard;
