import React, { useState, useRef, useEffect } from 'react'
import { IconButton, Tooltip, } from "@material-tailwind/react";

import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { IoReceiptSharp } from "react-icons/io5";
import { BiTrash } from "react-icons/bi";
import { Link, useNavigate } from 'react-router-dom';

import { useGetPurchaseVoucherQuery, useAddPurchaseVoucherMutation } from '../../api/purchaseSlice';
;

const PurchaseRecords = () => {
    const {data:dataPV, isLoading:isLoadingPV, isSuccess:isSuccessPV, refetch:refetchPV} = useGetPurchaseVoucherQuery();

    const funShowVoucher = () =>{
        return false;
    }
    const [lstVoucher, setLstVoucher] = useState([]);
    useEffect(()=>{
        if(isSuccessPV){
            let voc = dataPV?.data.filter(obj => {return obj.status == 1});
            console.log(voc)
            const rows = voc?.map((obj, index) =>
            isSuccessPV
            ? {
                ...obj,
                no: index + 1,
                cash: obj.net - obj.bill,
                supplier: obj.supplier.name,
                }
            : { ...obj }
        );
        console.log(rows)

            setLstVoucher(rows);
        }
        

    },[dataPV?.data])

    //Table columns
    const columns = isSuccessPV
    ? [
        { field: "no", headerName: "No.", width: 100 },
        {
            field: "id",
            headerName: "Voucher No",
            flex: 1,
            editable: false,
        },
        {
            field: "supplier",
            headerName: "Supplier",
            flex: 1,
            editable: false,
        },
        {
            field: "buy_date",
            headerName: "Date",
            flex: 1,
            editable: false,
        },
        {
            field: "discount",
            headerName: "Discount(%)",
            flex: 1,
            editable: false,
        },
        {
            field: "cash",
            headerName: "Cash",
            flex: 1,
            editable: false,
        },
        {
            field: "bill",
            headerName: "Credit",
            flex: 1,
            editable: false,
        },
        {
            field: "net",
            headerName: "Total",
            flex: 1,
            editable: false,
        },
        {
            field: "prodId",
            headerName: "Action",
            flex: 1,
            editable: false,
            renderCell: (params) => (
            <div className="flex flex-row gap-2">
                <Tooltip content="Details" className="flex-1 bg-green-500">
                    <Link to={`/purchase-history/${params.row.id}`} state={{id: params.row.id}} key={params.row.id} className='flex flex-col items-center text-center'>
                        <IconButton className="w-6 h-6 bg-green-200 p-4">
                            <IoReceiptSharp className="h-4 w-4 text-green-600 font-extrabold" />
                        </IconButton>
                    </Link>
                </Tooltip>
                <Tooltip content="Delete" className="flex-1 bg-red-500">
                    <IconButton variant="text" className="w-6 h-6 bg-red-200 p-4">
                        <BiTrash className="h-4 w-4 text-red-600" />
                    </IconButton>
                </Tooltip>
            </div>
            ),
        },
        ]
    : [];
  return (
    <div className="flex flex-col gap-4 px-16">
      {/* Caption */}
      <div className="flex flex-row w-full justify-start">
        <p className="px-4 py-2 bg-[#57626c] rounded-lg text-white font-bold">
          Purchase Records
        </p>
      </div>


      {/* Supplier List Table */}
      <div className="w-full h-full">
        {(
          <DataGrid
            rows={lstVoucher}
            columns={columns}
            rowHeight={80}
            onRowDoubleClick={(params)=> <Link to={`/purchase-details/${params.row.id}`} key={params.row.id}></Link>}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 10,
                },
              },
            }}
            density="compact"
            pageSizeOptions={[5,10,25,50,100]}
            disableRowSelectionOnClick
            slots={{ toolbar: GridToolbar }}
            slotProps={{
              toolbar: {
                showQuickFilter: true,
                quickFilterProps: { debounceMs: 200 },
              },
            }}
          />
        ) }
      </div>

    </div>
  )
}

export default PurchaseRecords