import React, { useState, useRef, useEffect } from "react";
import {
  useGetSupplierQuery,
  useAddSupplierMutation,
  useEditSupplierMutation,
  useDeleteSupplierMutation,
} from "../../api/supplierSlice";
import Swal from "sweetalert2";

import {
  Button,
  Input,
  Card,
  Typography,
  Drawer,
  IconButton,
  Spinner,
  Tooltip,
} from "@material-tailwind/react";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { IoAddCircleOutline } from "react-icons/io5";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { BiTrash } from "react-icons/bi";
import { FaEdit,FaClone } from "react-icons/fa";
import withReactContent from "sweetalert2-react-content";
import  SkeletonTable from "../../components/SkeletonTable";
import { useNavigate } from "react-router-dom";

const Supplier = () => {
  // Api Calling
  const { data, isLoading, isSuccess, refetch } = useGetSupplierQuery();
  const [addSupplier, result] = useAddSupplierMutation();
  const [editSupplier, editResult] = useEditSupplierMutation();
  const [deleteSupplier, deleteResult] = useDeleteSupplierMutation();

  const [lstSupplier, setLstSupplier] = useState([]);

  const navigate = useNavigate();

  useEffect(()=>{
    if(!isLoading){
      setLstSupplier(data?.data)
    }
  })

  //Table columns
  const columns = isSuccess
    ? [
        { field: "id",
          headerName: "No", 
          align: 'center',
          headerAlign: 'center'
        },
        {
          field: "name",
          headerName: "Name",
          flex: 1,
          editable: false,
          align: 'center',
          headerAlign: 'center'
        },
        {
          field: "phone",
          headerName: "Phone",
          flex: 1,
          editable: false,
          align: 'center',
          headerAlign: 'center'
        },
        {
          field: "address",
          headerName: "Address",
          sortable: true,
          editable: false,
          flex: 1,
          align: 'center',
          headerAlign: 'center'
        },
        {
          field: "action",
          headerName: "Action",
          flex: 1,
          editable: false,
          align: 'center',
          headerAlign: 'center',
          renderCell: (params) => (
            <div className="flex flex-row gap-2 justify-center items-center">
              <Tooltip content="Statement" className="flex-1 bg-green-500">
                
                <IconButton

                  onClick={() => {
                    console.log(params);
                    navigate("/supplier-statement", {state: params.row})

                  }}
                  className="w-6 h-6 bg-green-200 p-4"
                >
                  <FaClone className="h-4 w-4 text-green-600 font-extrabold" />
                </IconButton>
              </Tooltip>
              <Tooltip content="Edit" className="flex-1 bg-green-500">
                <IconButton

                  onClick={() => {
                    editSupplierNameRef.current.value = params.row.name;
                    editSupplierPhoneRef.current.value = params.row.phone;
                    editSupplierAddressRef.current.value = params.row.address;
                    setStatus(params.row.status)
                    setNote(params.row.note)
                    setId(params.row.id)

                    openDrawer();
                  }}
                  className="w-6 h-6 bg-green-200 p-4"
                >
                  <FaEdit className="h-4 w-4 text-green-600 font-extrabold" />
                </IconButton>
              </Tooltip>
              <Tooltip content="Delete" className="flex-1 bg-red-500">
                <IconButton onClick={() => confirmDelete(params.row.id)} variant="text" className="w-6 h-6 bg-red-200 p-4">
                  <BiTrash className="h-4 w-4 text-red-600" />
                </IconButton>
              </Tooltip>
            </div>
          ),
        },
      ]
    : [];

  //Open Drawer to edit Supplier
  const [open, setOpen] = useState(false);
  const openDrawer = () => setOpen(true);
  const closeDrawer = () => setOpen(false);
  const [addNew, setAddNew] = useState(false);

  //add Supplier
  const supplierNameRef = useRef();
  const supplierPhoneRef = useRef();
  const supplierAddressRef = useRef();
  const supplierHandler = async () => {
    let body = {
      name: supplierNameRef.current.value,
      phone: supplierPhoneRef.current.value,
      address: supplierAddressRef.current.value,
    };

    await addSupplier(body);
    supplierNameRef.current.value = "";
    supplierPhoneRef.current.value = "";
    supplierAddressRef.current.value = "";

    refetch();
  };

  const pressEnterAddSupplier = (event) =>{
    if(event.key == 'Enter'){
      supplierHandler();
    }
  }

  //Edit Supplier
  const editSupplierNameRef = useRef();
  const editSupplierPhoneRef = useRef();
  const editSupplierAddressRef = useRef();
  const [status, setStatus] = useState();
  const [note, setNote] = useState();
  const [sId, setId] = useState();
  const editSupplierHandler = async () => {

    let body = {
      id: sId,
      name: editSupplierNameRef.current.value,
      phone: editSupplierPhoneRef.current.value,
      address: editSupplierAddressRef.current.value,
      status: status,
      note: note,
    };


    await editSupplier(body);
    closeDrawer();
    refetch();
  };

  //Delete Supplier
  const sweetAlert = withReactContent(Swal);
  const deleteSupplierHandler = async (id) => {
    await deleteSupplier(id);
    refetch();
  };

  const confirmDelete = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        deleteSupplierHandler(id);
        Swal.fire("Deleted!", "Your Supplier has been deleted.", "success");
      }
    });
  };
  return (
    <div className="flex flex-col gap-4 px-16">
      <React.Fragment>
        <Drawer placement="right" open={open} onClose={closeDrawer}>
          <div className="mb-2 flex items-center justify-between p-4">
            <Typography variant="h5" color="blue-gray">
              Edit Supplier
            </Typography>
            <IconButton variant="text" color="blue-gray" onClick={closeDrawer}>
              <XMarkIcon strokeWidth={2} className="h-5 w-5" />
            </IconButton>
          </div>
          <form className="flex flex-col gap-6 p-4">
            <Input
              type="text"
              label="Name"
              //defaultValue={open ? editSuppli.name : ""}
              inputRef={editSupplierNameRef}
            />
            <Input
              type="text"
              label="Phone"
              //defaultValue={open ? editSuppli.phone : ""}
              inputRef={editSupplierPhoneRef}
            />
            <Input
              type="text"
              label="Address"
              //defaultValue={open ? editSuppli.address : ""}
              inputRef={editSupplierAddressRef}
            />
            <Button onClick={editSupplierHandler}>
              {editResult.isLoading ? <Spinner /> : "Save"}
            </Button>
          </form>
        </Drawer>
      </React.Fragment>
      {/* Caption */}
      <div className="flex flex-row w-full justify-between">
        <p className="px-4 py-2 bg-[#57626c] rounded-lg text-white font-bold">
          Supplier
        </p>
        <Button 
              variant="outlined" onClick={()=> setAddNew(!addNew)}>
              {addNew ? "Close": "Add New"}
        </Button>
      </div>

      {/* Add New Section */}
      <div className={`${addNew==  true ? 'w-full px-6 py-2 h-20 border-2 border-gray-200 xl:flex xl:flex-row gap-2 items-end grid grid-cols-1 justify-center rounded-xl': 'hidden'}`}>
        {/* <form className="flex flex-row items-end" action={supplierHandler}> */}
        <div className="flex-1 m-2">
            <Input
              size="lg"
              label="Supplier Name"
              inputRef={supplierNameRef}
              required
            />
          </div>
          <div className="flex-1 m-2">
            <Input
              size="lg"
              label="Phone"
              inputRef={supplierPhoneRef}
              required
            />
          </div>
          <div className="flex-1 m-2">
            <Input
              size="lg"
              label="Address"
              inputRef={supplierAddressRef}
              onKeyDown={pressEnterAddSupplier}
              required
            />
          </div>
          <div className="flex-none m-2">
            <Button
            onClick={supplierHandler}
              type="submit"
              variant="outlined"
              className="flex items-center gap-3 px-4 py-2 m-1"
            >
              {result.isLoading ? (
                <Spinner color="indigo" />
              ) : (
                <IoAddCircleOutline strokeWidth={2} className="h-5 w-5" />
              )}
              Add
            </Button>
          </div>
        {/* </form> */}
      </div>

      {/* Supplier List Table */}
      <div className="w-full">
        <Card className="overflow-scroll h-full w-full">
          {isLoading ? (
            <SkeletonTable/>
          ) : (
            isSuccess ? (
              <DataGrid
                rows={lstSupplier}
                columns={columns}
                rowHeight={80}
                density="compact"
                initialState={{
                  pagination: {
                    paginationModel: {
                      pageSize: 10,
                    },
                  },
                }}
                pageSizeOptions={[5,10,25,50,100]}
                disableRowSelectionOnClick
                slots={{ toolbar: GridToolbar }}
                slotProps={{
                  toolbar: {
                    showQuickFilter: true,
                    quickFilterProps: { debounceMs: 200 },
                  },
                }}
              />
            ) : (
              <></>
            )
          )}
        </Card>
      </div>
    </div>
  )
}

export default Supplier