import React, { useRef, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useLoginMutation } from "../../api/AuthApiSlice";

import Button from "@mui/material/Button";
import { Spinner } from "@material-tailwind/react";
import AlertComponent from "./AlertComponent";
// import { PreLoadingPage } from "../../Components";
// import { useDispatch,  } from "react-redux";
// import { addProduct } from "../../ApiService/ProductSlice";
// import { useGetProdcutByStoreQuery } from "../../ApiService/ProductApiSlice";

const LoginPage = () => {
  const navigate = useNavigate();

  const [login, result] = useLoginMutation();

  const [open, setOpen] = useState(false);

  const nameRef = useRef("");
  const passwordRef = useRef("");

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const handleClick = () => {
    setOpen(true);
  };

  const checkAuth = async () => {
    const auth = {
      phone: nameRef.current.value,
      password: passwordRef.current.value,
      role_id: 1,
      saas:'futuretechmm',
      deviceId: 'TTMMCLIENT_APP'
    };

    // handleClick();
    await login(auth);
    // navigate("/dashboard");
  };


  useEffect(() => {
    if (result.isSuccess) {
      console.log(result.data);
      // const UserPermissions = JSON.parse(result.data.data.permissions);
      // localStorage.setItem("shop", result.data.shop.shop.name);
      // localStorage.setItem("shopdetails", JSON.stringify(result.data.shop.shop));
      // localStorage.setItem("shop_id", result.data.shop.shop.id);
      localStorage.setItem("auth", true);
      localStorage.setItem("token", result.data.token);
      // localStorage.setItem("user", JSON.stringify(result.data.data));
      handleClick();
      navigate("/splash");
    } else if (result.isError) {
      handleClick();
      console.log(result)
    }
    console.log('response login')
  }, [result]);

  return (
    
   <div className="flex flex-row w-full h-screen pt-12 md:pt-20 pb-6 px-2 md:px-0 items-center justify-center font-poppins select-none bg-gradient-radial bg-gradient-to-r from-green-400 to-yellow-100">
      <div className="bg-white mx-auto p-8 md:p-12 my-10 rounded-lg shadow-2xl">
        <section>
          <h3 className="font-bold text-2xl">Welcome to TTMM</h3>
          <p className="text-gray-600 pt-2">Login to your account.</p>
        </section>

        <section className="mt-10">
          <div className="mb-6 pt-3 rounded bg-gray-200">
            <label
              className="block text-gray-700 text-sm font-bold mb-2 ml-3"
              htmlFor="email"
            >
              Phone
            </label>
            <input
              type="text"
              id="name"
              className="bg-gray-200 rounded w-full text-gray-700 focus:outline-none border-b-4 border-gray-300 focus:border-purple-600 transition duration-500 px-3 pb-3"
              ref={nameRef}
            />
          </div>
          <div className="mb-6 pt-3 rounded bg-gray-200">
            <label
              className="block text-gray-700 text-sm font-bold mb-2 ml-3"
              htmlFor="password"
            >
              Password
            </label>
            <input
              type="password"
              id="password"
              className="bg-gray-200 rounded w-full text-gray-700 focus:outline-none border-b-4 border-gray-300 focus:border-purple-600 transition duration-500 px-3 pb-3"
              ref={passwordRef}
            />
          </div>

          <div className="flex flex-row justify-center">
            {result.isLoading ? (
              <Button variant="outlined">
                <Spinner />
              </Button>
            ) : (
              <Button variant="outlined" onClick={checkAuth}>
                LogIn
              </Button>
            )}
          </div>
        </section>
        <AlertComponent open={open} handleClose={handleClose} result={result}/>
      </div>
    </div>
    
  );
};

export default LoginPage;
