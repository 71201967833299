import React from 'react'
import CustomNavbar from './CustomNavbar';
import CustomSidebar from './CustomSidebar';
import MaterialSideBar from './MaterialSideBar';
import MaterialNavBar from './MaterialNavBar';
import { useLocation } from 'react-router-dom';
const Layout = ({children}) => {
  const location = useLocation();
  return (
    <>{
    (location.pathname.includes("/splash") || location.pathname.includes("/login")) ?
  
      <div className='flex flex-row justify-center items-center w-full h-screen '>
        {children}
      </div>:
        <div className='flex w-full h-screen select-none'>
          <div className='flex-1 overflow-y-auto mt-20 mx-4 w-full scrollbar-hide'>
            {
              children
            }
            
          </div>
          <div className='flex flex-row h-16 w-full absolute top-0 left-0 z-0 justify-end'>
            <MaterialNavBar/>
          </div> 
          
      </div>
      }
    </>
  )
}

export default Layout