import React, { useState, useRef, useEffect } from "react";
import {
  useGetUserQuery,
  useEditUserMutation,
  useDeleteUserMutation,
} from "../../api/userSlice";
import Swal from "sweetalert2";

import {
  Button,
  Input,
  Card,
  Typography,
  Drawer,
  IconButton,
  Spinner,
  Tooltip,
} from "@material-tailwind/react";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { IoAddCircleOutline } from "react-icons/io5";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { BiTrash } from "react-icons/bi";
import { FaEdit } from "react-icons/fa";
import withReactContent from "sweetalert2-react-content";
import  SkeletonTable from "../../components/SkeletonTable";

const User = () => {
    // Api Calling
    const { data, isLoading, isSuccess, refetch } = useGetUserQuery();
    const [editUser, editResult] = useEditUserMutation();
    const [deleteUser, deleteResult] = useDeleteUserMutation();
  
    const [lstUser, setLstUser] = useState([]);
  
    useEffect(()=>{
      if(!isLoading){
        setLstUser(data?.data)
      }
    })
  
    //Table columns
    const columns = isSuccess
      ? [
          { field: "id",
            headerName: "No", 
            align: 'center',
            headerAlign: 'center'
          },
          {
            field: "name",
            headerName: "Name",
            flex: 1,
            editable: false,
            align: 'center',
            headerAlign: 'center'
          },
          {
            field: "phone",
            headerName: "Phone",
            flex: 1,
            editable: false,
            align: 'center',
            headerAlign: 'center'
          },
          {
            field: "shopName",
            headerName: "Shop",
            sortable: true,
            editable: false,
            flex: 1,
            align: 'center',
            headerAlign: 'center'
          },
          {
            field: "action",
            headerName: "Action",
            flex: 1,
            editable: false,
            align: 'center',
            headerAlign: 'center',
            renderCell: (params) => (
              <div className="flex flex-row gap-2 justify-center items-center">
                <Tooltip content="Edit" className="flex-1 bg-green-500">
                  <IconButton
  
                    onClick={() => {
                      editUserNameRef.current.value = params.row.name;
                      editUserPhoneRef.current.value = params.row.phone;
                      editUserPassword.current.value = "";
                      setId(params.row.id)
  
                      openDrawer();
                    }}
                    className="w-6 h-6 bg-green-200 p-4"
                  >
                    <FaEdit className="h-4 w-4 text-green-600 font-extrabold" />
                  </IconButton>
                </Tooltip>
                <Tooltip content="Delete" className="flex-1 bg-red-500">
                  <IconButton onClick={() => confirmDelete(params.row.id)} variant="text" className="w-6 h-6 bg-red-200 p-4">
                    <BiTrash className="h-4 w-4 text-red-600" />
                  </IconButton>
                </Tooltip>
              </div>
            ),
          },
        ]
      : [];
  
    //Open Drawer to edit Supplier
    const [open, setOpen] = useState(false);
    const openDrawer = () => setOpen(true);
    const closeDrawer = () => setOpen(false);
    // const [addNew, setAddNew] = useState(false);
  
    //add User
    // const supplierNameRef = useRef();
    // const supplierPhoneRef = useRef();
    // const supplierAddressRef = useRef();
    // const supplierHandler = async () => {
    //   let body = {
    //     name: supplierNameRef.current.value,
    //     phone: supplierPhoneRef.current.value,
    //     address: supplierAddressRef.current.value,
    //   };
  
    //   await addSupplier(body);
    //   supplierNameRef.current.value = "";
    //   supplierPhoneRef.current.value = "";
    //   supplierAddressRef.current.value = "";
  
    //   refetch();
    // };
  
    const pressEnterAddUser = (event) =>{
      if(event.key == 'Enter'){
        // userHandler();
      }
    }
  
    //Edit User
    const editUserNameRef = useRef();
    const editUserPhoneRef = useRef();
    const editUserPassword = useRef();
    // const [status, setStatus] = useState();
    // const [note, setNote] = useState();
    const [Id, setId] = useState();
    const editUserHandler = async () => {
  
      let body = {
        id: Id,
        name: editUserNameRef.current.value,
        phone: editUserPhoneRef.current.value,
        password: editUserPassword.current.value,
      };
  
  
      await editUser(body);
      closeDrawer();
      refetch();
    };
  
    //Delete Supplier
    const sweetAlert = withReactContent(Swal);
    const deleteUserHandler = async (id) => {
      await deleteUser(id);
      refetch();
    };
  
    const confirmDelete = (id) => {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          deleteUserHandler(id);
          Swal.fire("Deleted!", "User account has been deleted.", "success");
        }
      });
    };
    return (
      <div className="flex flex-col gap-4 px-16">
        { <React.Fragment>
          <Drawer placement="right" open={open} onClose={closeDrawer}>
            <div className="mb-2 flex items-center justify-between p-4">
              <Typography variant="h5" color="blue-gray">
                Edit User
              </Typography>
              <IconButton variant="text" color="blue-gray" onClick={closeDrawer}>
                <XMarkIcon strokeWidth={2} className="h-5 w-5" />
              </IconButton>
            </div>
            <form className="flex flex-col gap-6 p-4">
              <Input
                type="text"
                label="Name"
                //defaultValue={open ? editSuppli.name : ""}
                inputRef={editUserNameRef}
              />
              <Input
                type="text"
                label="Phone"
                //defaultValue={open ? editSuppli.phone : ""}
                inputRef={editUserPhoneRef}
              />
              <Input
                type="text"
                label="Password"
                //defaultValue={open ? editSuppli.address : ""}
                inputRef={editUserPassword}
              />
              <Button onClick={editUserHandler}>
                {editResult.isLoading ? <Spinner /> : "Save"}
              </Button>
            </form>
          </Drawer>
        </React.Fragment> }
        {/* Caption */}
        <div className="flex flex-row w-full justify-between">
          <p className="px-4 py-2 bg-[#57626c] rounded-lg text-white font-bold">
            User
          </p>
          {/* <Button 
                variant="outlined" onClick={()=> setAddNew(!addNew)}>
                {addNew ? "Close": "Add New"}
          </Button> */}
        </div>
  
        {/* Supplier List Table */}
        <div className="w-full">
          <Card className="overflow-scroll h-full w-full">
            {isLoading ? (
              <SkeletonTable/>
            ) : (
              isSuccess ? (
                <DataGrid
                  rows={lstUser}
                  columns={columns}
                  rowHeight={80}
                  density="compact"
                  initialState={{
                    pagination: {
                      paginationModel: {
                        pageSize: 10,
                      },
                    },
                  }}
                  pageSizeOptions={[5,10,25,50,100]}
                  disableRowSelectionOnClick
                  slots={{ toolbar: GridToolbar }}
                  slotProps={{
                    toolbar: {
                      showQuickFilter: true,
                      quickFilterProps: { debounceMs: 500 },
                    },
                  }}
                />
              ) : (
                <></>
              )
            )}
          </Card>
        </div>
      </div>
    )
  }

export default User