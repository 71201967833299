import { createSlice, current } from "@reduxjs/toolkit";

const initialState = {
    lstCurrency: []
}

export const currencyStoreSlice = createSlice({
    name: 'currencyStore',
    initialState,
    reducers: {
        addCurrency: (state, action) =>{
            console.log(action.payload);
            state.lstCurrency = action.payload.map(obj => obj);
            //console.log(current(state.lstCurrency));
        },
    },
})

export const { 
    addCurrency,
} = currencyStoreSlice.actions;

export default currencyStoreSlice.reducer;