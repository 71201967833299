import React from "react";
import Stack from "@mui/material/Stack";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={10} ref={ref} variant="filled" {...props} />;
});

const AlertComponent = ({ result, open, handleClose }) => {
  console.log(result)
  return (
    <div>
      <Stack spacing={2} sx={{ width: "90%" }}>
        <Snackbar open={open} autoHideDuration={5000} onClose={handleClose}>
          {result.isSuccess ? (
            <Alert
              onClose={handleClose}
              severity="success"
              sx={{ width: "100%" }}
              variant={"filled"}
            >
             { "Success" }
            </Alert>
          ) : result.warning ? (
            <Alert
              onClose={handleClose}
              severity="warning"
              variant={"filled"}
              sx={{ width: "100%" }}
            >
               { "Success" }
            </Alert>
          ) : (
            <Alert
              onClose={handleClose}
              severity="error"
              variant={"filled"}
              sx={{ width: "100%" }}
            >
              {JSON.stringify(result?.error?.data)}
            </Alert>
          )}
        </Snackbar>
      </Stack>
    </div>
  );
};

export default AlertComponent;
