import React from 'react'

const SkeletonTable = () => {
  return (
    <div className='flex-col w-full min-w-max table-auto text-center h-fit gap-3 animate-pulse'>
        <div className='mb-2 w-full rounded-lg p-6 bg-gray-300'></div>
        <div className='mb-2 w-full rounded-lg p-3 bg-gray-300'></div>
        <div className='mb-2 w-full rounded-lg p-3 bg-gray-300'></div>
        <div className='mb-2 w-full rounded-lg p-3 bg-gray-300'></div>
        <div className='mb-2 w-full rounded-lg p-3 bg-gray-300'></div>
        <div className='mb-2 w-full rounded-lg p-3 bg-gray-300'></div>
        <div className='mb-2 w-full rounded-lg p-3 bg-gray-300'></div>
        <div className='mb-2 w-full rounded-lg p-3 bg-gray-300'></div>
    </div>
  )
}

export default SkeletonTable