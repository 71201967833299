import React, { useState, useRef, useEffect } from "react";
import { useGetDailyReportQuery } from "../../api/reportSlice";
import Swal from "sweetalert2";

import {
  Button,
  Input,
  Card,
  Typography,
  Drawer,
  IconButton,
  Spinner,
  Tooltip,
} from "@material-tailwind/react";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { IoAddCircleOutline } from "react-icons/io5";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { BiTrash } from "react-icons/bi";
import { FaEdit } from "react-icons/fa";
import withReactContent from "sweetalert2-react-content";
import  SkeletonTable from "../../components/SkeletonTable";



const DailyReport = () => {
  // Api Calling
  const { data, isLoading, isSuccess, refetch } = useGetDailyReportQuery();

  const [lstDailyReport, setLstDailyReport] = useState([]);

  useEffect(()=>{
      if(isSuccess){
        const rows = data?.data.map((r, index) =>
        r.expense
          ? {
              ...r,
              id: index + 1,
              saleamount: parseInt(r.saleamount),
              billamount: parseInt(r.billamount),
              income: parseInt(r.expense.income_amount),
              expense: parseInt(r.expense.expense_amount),
              remain: (parseInt(r.saleamount) + parseInt(r.expense.income_amount)) - (parseInt(r.billamount) + parseInt(r.expense.expense_amount) + parseInt(r.discountamount)),
            }
          : { 
            ...r ,
            id: index + 1,
            saleamount: parseInt(r.saleamount),
              billamount: parseInt(r.billamount),
            income: 0,
            expense: 0,
            remain: parseInt(r.saleamount) - parseInt(r.billamount)
            }
      );
        setLstDailyReport(rows);
      }
    

  },[data?.data])

  //Table columns
  const columns = isSuccess
    ? [
        { field: "id",
          headerName: "No", 
          align: 'center',
          headerAlign: 'center',
          flex: 1,
        },
        {
          field: "days",
          headerName: "Day",
          flex: 1,
          editable: false,
          align: 'center',
          headerAlign: 'center'
        },
        {
            field: "vouchercount",
            headerName: "Voucher Count",
            flex: 1,
            editable: false,
            align: 'center',
            headerAlign: 'center'
            },
            {
            field: "saleamount",
            headerName: "Sale Amount",
            type: 'number',
            flex: 1,
            editable: false,
            align: 'center',
            headerAlign: 'center'
            },
            {
            field: "billamount",
            headerName: "Bill Amount",
            type: 'number',
            flex: 1,
            editable: false,
            align: 'center',
            headerAlign: 'center'
            },
            {
                field: "discountamount",
                headerName: "Discount",
                type: 'number',
                flex: 1,
                editable: false,
                align: 'center',
                headerAlign: 'center'
                },
            {
                field: "income",
                headerName: "Income",
                type: 'number',
                flex: 1,
                editable: false,
                align: 'center',
                headerAlign: 'center'
                },
                {
                    field: "expense",
                    headerName: "Expense",
                    type: 'number',
                    flex: 1,
                    editable: false,
                    align: 'center',
                    headerAlign: 'center'
                    },
                    {
                        field: "remain",
                        headerName: "Remain",
                        type: 'number',
                        flex: 1,
                        editable: false,
                        align: 'center',
                        headerAlign: 'center'
                        },

        {
          field: "action",
          headerName: "Action",
          flex: 1,
          editable: false,
          align: 'center',
          headerAlign: 'center',
          renderCell: (params) => (
            <div className="flex flex-row gap-2 justify-center items-center">
              {/* <Tooltip content="Edit" className="flex-1 bg-green-500">
                <IconButton

                  onClick={() => {
                    // editCategoryRef.current.value = params.row.name;

                    // openDrawer(params.row);
                  }}
                  className="w-6 h-6 bg-green-200 p-4"
                >
                  <FaEdit className="h-4 w-4 text-green-600 font-extrabold" />
                </IconButton>
              </Tooltip>
              <Tooltip content="Delete" className="flex-1 bg-red-500">
                <IconButton onClick={()=>{}} variant="text" className="w-6 h-6 bg-red-200 p-4">
                  <BiTrash className="h-4 w-4 text-red-600" />
                </IconButton>
              </Tooltip> */}
            </div>
          ),
        },
      ]
    : [];


  


  return (
    <div className="flex flex-col gap-4 px-16 max-h-full">
      
      {/* Caption */}
      <div className="flex flex-row w-full justify-between">
        <p className="px-4 py-2 bg-[#57626c] rounded-lg text-white font-bold">
          Daily Reports
        </p>
        {/* <Button 
              variant="outlined" onClick={()=> setAddNew(!addNew)}>
              {addNew ? "Close": "Add New"}
        </Button> */}
      </div>


      {/* Category List Table */}
      <div className="w-full">
        <Card className="overflow-scroll h-full w-full">
          {isLoading ? (
            <SkeletonTable/>
          ) : (
            isSuccess ? (
              <DataGrid
                rows={lstDailyReport}
                columns={columns}
                rowHeight={80}
                density="compact"
                initialState={{
                  pagination: {
                    paginationModel: {
                      pageSize: 10,
                    },
                  },
                }}
                pageSizeOptions={[5,10,25,50,100]}
                disableRowSelectionOnClick
                slots={{ toolbar: GridToolbar }}
                slotProps={{
                  toolbar: {
                    showQuickFilter: true,
                    quickFilterProps: { debounceMs: 200 },
                  },
                }}
              />
            ) : (
              <></>
            )
          )}
        </Card>
      </div>
    </div>
  );
};

export default DailyReport;
