import { createSlice, current } from "@reduxjs/toolkit";
import { useGetProductQuery } from "./productSlice";

const initialState = {
    lstProduct: [],
    paginate: {
        current: 0,
        total: 0
    }

}

export const productListSlice = createSlice({
    name: 'productList',
    initialState,
    reducers: {
        addLstProduct: (state, action) =>{
            
            state.lstProduct = action.payload.map(obj => obj);
            console.log(state.lstProduct);
        },
        appendLstProduct: (state, action)=>{
            console.log(action.payload);
            state.lstProduct = [...state.lstProduct, ...action.payload];
        },
        appendNewProduct: (state, action)=>{
            state.lstProduct= [...state.lstProduct, action.payload];
        },
        setPaginate: (state, action)=>{
            state.paginate.current = action.payload.paginate.current;
            state.paginate.total = action.payload.paginate.current;
        },
        addStock: (state, action)=>{
            const existItem = state.lstProduct.find(obj =>  obj.id == action.payload.product.id);
            console.log(current(existItem))
            const newList = state.lstProduct.map(obj =>{ 
                if(obj.id == existItem.id){
                    return {
                        ...obj, 
                        qty: parseInt(obj.stock , 10 ) + parseInt(action.payload.product.qty,10), 
                      }
                }
                return {...obj}
            })
            state.lstProduct = newList.map(obj => obj);
            console.log(state.lstProduct)

            
        },
        reduceStock: (state, action)=>{
            const existItem = state.lstProduct.find(obj =>  obj.id == action.payload.product.id);
            
            const newList = state.lstProduct.map(obj =>{ 
                if(obj.id == existItem.id){
                    return {
                        ...obj, 
                        qty: parseInt(obj.stock , 10 ) - parseInt(action.payload.product.qty,10), 
                      }
                }
                return {...obj}
            })
            state.lstProduct = newList.map(obj => obj);
        }
       
        // editList: (state, action)=>{
        //     const pdt = state.lstProduct.find(obj => {
        //         return obj.voucherId === action.payload.voucherId;
        //     });

        //     voc.lstProduct.push(action.payload.product);
        //     console.log(voc)
        // }
    },
})

export const { addLstProduct, appendLstProduct, appendNewProduct, setPaginate, addStock, reduceStock} = productListSlice.actions;

export default productListSlice.reducer;