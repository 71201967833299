import { ApiSlice } from "./ApiSlice";

export const purchaseCheckoutSlice = ApiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getPurchaseCheckout: builder.query({
            query: () => '/purchase-item',
        }),

        getPurchaseCheckoutById: builder.query({
            query: (id) => '/purchase-item/{id}',
        }),

        addPurchaseCheckout: builder.mutation({
            query: (body) => ({
                url: '/purchase-item',
                method: 'POST',
                body,
            })
        }),

        editPurchaseCheckout: builder.mutation({
            query: (body) =>({
                url: `/purchase-item/${body.id}`,
                method: 'POST',
                body
            }),
        
        }),

        editPurchaseQty: builder.mutation({
            query: (body) =>({
                url: '/qty-update',
                method: 'POST',
                body
            })
        }),

        deletePurchaseCheckout: builder.mutation({
            query: (id) =>({
                url: `/purchase-item/${id}`,
                method: 'DELETE',
            })
        }),
    })
});

export const {
    useGetPurchaseCheckoutQuery,
    useGetPurchaseCheckoutByIdQuery,
    useAddPurchaseCheckoutMutation,
    useEditPurchaseCheckoutMutation,
    useEditPurchaseQtyMutation,
    useDeletePurchaseCheckoutMutation
} = purchaseCheckoutSlice