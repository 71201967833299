import { ApiSlice } from "./ApiSlice";

export const currencySlice = ApiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getCurrency: builder.query({
            query: () => '/currency',
            providesTags: ['currency']
        }),

        addCurrency: builder.mutation({
            query: (body) => ({
                url: '/currency',
                method: 'POST',
                body,
            }),
            invalidatesTags: ['currency']

        }),

        editCurrency: builder.mutation({
            query: (body) =>({
                url: `/currency/${body.id}`,
                method: 'POST',
                body
            }),
            invalidatesTags: ['currency']
        
        }),

    })
});

export const {
    useGetCurrencyQuery,
    useAddCurrencyMutation,
    useEditCurrencyMutation,

} = currencySlice