import React, {useEffect, useRef} from "react";
import { Input, Select, Option, Button, Spinner } from "@material-tailwind/react";
import { useState } from "react";
import { BsPlusSquare } from "react-icons/bs";
import Alert from '@mui/material/Alert';
import Collapse from '@mui/material/Collapse';
import { AiOutlineCloseCircle } from "react-icons/ai";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { useGetCategoryQuery } from "../../api/categorySlice";
import { useGetUnitQuery } from "../../api/unitSlice";
import { useAddProductMutation } from "../../api/productSlice";
import { useSelector, useDispatch} from 'react-redux';
import { appendNewProduct } from "../../api/productListSlice";
import { Link } from 'react-router-dom';

const AddProducts = () => {

  const rtkLstProduct = useSelector((state)=> state.productList.lstProduct);
  const dispatch = useDispatch();
  // Api Calling
  const { data:lstCategory, isLoading:isLoadingCategory, isSuccess:isSuccessCategory, refetch:refetchCategory } = useGetCategoryQuery();
  const { data:lstUnit, isLoading:isLoadingUnit, isSuccess:isSuccessUnit, refetch:refetchUnit } = useGetUnitQuery();
  const [addProduct, result] = useAddProductMutation();

  //Select Image
  const [sPriceCount, setSPriceCount] = useState(1);
  const [preview, setPreview] = useState(null);
  const [selectedImage, setSelectedImage] = useState();

  const [openAlert, setOpenAlert] = useState(false);
  const [alertInfo, setAlertInfo] = useState("Product name already exists.")

  //get category id and unit id from Material Select components
  const [value, setValue] = useState(null);
  const [categoryId, setCategoryId] = useState(1);
  const [unitId, setUnitId] = useState(1);
  const [currency, setCurrency] = useState(4);

  const initValue = 0;
  //add Shop
  const refProductName = useRef();
  const refProductCode = useRef();
  const refProductAlertQty = useRef();
  // const refProductSellPrice1 = useRef();
  // const refProductSellPrice2 = useRef();
  // const refProductSellPrice3 = useRef();
  const refProfitPercent = useRef();

  useEffect(()=>{
     refProductAlertQty.current.value = 0;
    //  refProductSellPrice1.current.value = 0;
    //  refProductSellPrice2.current.value = 0;
    //  refProductSellPrice3.current.value = 0;
     refProfitPercent.current.value = 0;
  },[])

  useEffect(()=>{
    
    if(result.isSuccess){
      let cateName = "-";
      let unitName = "-";
      lstCategory.data.map(obj => {
        if(obj.id === categoryId){
          cateName =  obj.name;
        }
      });
      lstUnit.data.map(obj => {
      if(obj.id === unitId){
        unitName = obj.name;
      }
    });
      let newProduct = {
      "id": rtkLstProduct[rtkLstProduct.length -1].id + 1,
      "category_id": categoryId,
      "unit_id": unitId,
      "barcode": refProductCode.current.value,
      "img": "image/product/default.png",
      "name": refProductName.current.value,
      "alert_qty": refProductAlertQty.current.value,
      "sell_price1": 0,
      "sell_price2": 0,
      "sell_price3": 0,
      "status": "0",
      "note": currency,
      "remark": refProfitPercent.current.value,
      "stock": 0,
      "currency": 1,
      "categoryName": cateName,
      "unitName": unitName,
      "prodId": rtkLstProduct[rtkLstProduct.length -1].id + 1,
      "sell_price": 0,
      "category": {
          "id": categoryId,
          "name": cateName,
      },
      "unit": {
          "id": unitId,
          "name": unitName,
      },
      "last_purchase": null
    };
    console.log(newProduct);
    dispatch(appendNewProduct(newProduct));
    refProductCode.current.value = "";
    refProductName.current.value = "";
    refProductAlertQty.current.value = "";
    refProfitPercent.current.value = "";
  
    }
  },[result])

  //Create FormData to send data with file
  let formData = new FormData();

  //Sending data to the server
  const productHandler = async (e) => {
    setOpenAlert(false);
    setValue(null);
    e.preventDefault();
    const prodName = refProductName.current.value.replace(/ /g,'');
    const check = rtkLstProduct.find((obj) => prodName == obj.name.replace(/ /g,''));
    if(check){
      setOpenAlert(true);
      return;
    }

    formData.append("category_id", categoryId);
    formData.append("unit_id", unitId);
    formData.append("barcode", refProductCode.current.value);
    formData.append("name", refProductName.current.value.replace(/ /g,''));
    formData.append("alert_qty", refProductAlertQty.current.value);
    formData.append("sell_price1", 0);
    formData.append("sell_price2", 0);
    formData.append("sell_price3", 0);
    formData.append("remark", refProfitPercent.current.value)
    formData.append('note',currency);
    formData.append("file", selectedImage);

    await addProduct(formData);

  };

  //set Preview Image and set selected Image
  const PreviewUpload = (e)=>{
    const file = e.target.files[0]
    setSelectedImage(file);
    setPreview(URL.createObjectURL(file));
  }
  return (
    <div className="flex flex-col gap-4">
      <Collapse in={openAlert}>
        <Alert 
          severity="warning" 
          action={
            <div className='flex-1 items-center justify-centerw-12'  onClick={() => {
              setOpenAlert(false);
            }}>
                <AiOutlineCloseCircle size={25}/>
              </div>
          }>{alertInfo}</Alert>
      </Collapse>
      {/* Caption */}
      <div className="flex flex-row w-full justify-between">
        <p className="px-4 py-2 bg-[#57626c] rounded-lg text-white font-bold">
          New Product
        </p>
        <Link to={`/product`}className='flex flex-col items-center text-center'>
          <Button 
                variant="outlined">
                {"Product Table"}
          </Button>
        </Link>
      </div>

      {/* Add New Section */}
      <form onSubmit={productHandler} encType="multipart/form-data">
        <div className="flex flex-col w-full border-2 rounded-lg shadow-xl p-8">
          <div className="grid grid-cols-1 lg:grid-cols-3 gap-8 w-full">
            <div className="flex-1 flex-col">
              <div className="mb-6">
                <div class="flex items-center justify-center w-full px-6 py-6">
                  <label
                    for="dropzone-file"
                    class="flex flex-col items-center justify-center w-full h-64 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600"
                  >
                    {preview != null ? <img src={preview} alt="Preview Image" className="object-fit w-full h-full p-4"/> : <div class="flex flex-col items-center justify-center pt-5 pb-6">
                      <svg
                        aria-hidden="true"
                        class="w-10 h-10 mb-3 text-gray-400"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
                        ></path>
                      </svg>
                      <p class="mb-2 text-sm text-gray-500 dark:text-gray-400">
                        <span class="font-semibold">Click to upload</span> or
                        drag and drop
                      </p>
                      <p class="text-xs text-gray-500 dark:text-gray-400">
                        SVG, PNG, JPG or GIF (MAX. 800x800px)
                      </p>
                    </div>}
                    <input id="dropzone-file" type="file" class="hidden" onChange={(e)=> PreviewUpload(e)}/>
                  </label>
                </div>
              </div>
            </div>
            <div className="flex-1 flex-col">
              <div className="mb-4">
                <Input variant="standard" size="lg" label="Barcode" inputRef={refProductCode} required />
              </div>
              <div className="mb-4">
                <Input
                  variant="standard"
                  size="lg"
                  label="Product Name"
                  inputRef={refProductName}
                  required
                />
              </div>
              <div className="mb-4">
                <Select variant="standard" value={value} label="Category" size="lg" onChange={(e) => setCategoryId(e)}>
                  {
                    isSuccessCategory? lstCategory.data.map((cate, i) => (
                      <Option value={cate.id}>{cate.name}</Option>
                    )): <Option>No Data</Option>
                    
                    }
                </Select>
              </div>
              
              
            </div>

            <div className="flex-1 flex-col">
            <div className="mb-4">
                <Select variant="standard" label="Unit" size="lg" onChange={(e)=> setUnitId(e)}>
                {
                    isSuccessUnit? lstUnit.data.map((unit, i) => (
                      <Option value={unit.id}>{unit.name}</Option>
                    )): <Option>No Data</Option>
                    
                    }
                </Select>
              </div>
              <div className="mb-4">
                <Input variant="standard" size="lg" label="Alert Quantity" type="number" inputRef={refProductAlertQty}/>
              </div>
              <div className="mb-4">
                <Input variant="standard" size="lg" label="Profit Percent(%)" type="number" inputRef={refProfitPercent}/>
              </div>
              <div className="mb-4">
              <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={currency}
                  onChange={(event)=>{
                    setCurrency(event.target.value);
                  }}
                >
                  <FormControlLabel value="1" control={<Radio />} label="USSD" labelPlacement="end"/>
                  <FormControlLabel value="2" control={<Radio />} label="Yuan" labelPlacement="end"/>
                  <FormControlLabel value="3" control={<Radio />} label="Baht" labelPlacement="end"/>
                  <FormControlLabel value="4" control={<Radio />} label="Kyats" labelPlacement="end"/>
                </RadioGroup>
              </div>
              
            </div>
          </div>
          <div className="flex flex-row w-full justify-end items-center">
            <Button
              type="submit"
              variant="outlined"
              className="flex items-center gap-3"
            >
              {result.isLoading ? <Spinner/> :"Add Product"}
              <BsPlusSquare strokeWidth={2} className="h-5 w-5" />
            </Button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default AddProducts;
