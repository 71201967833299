import { ApiSlice } from "./ApiSlice";

export const shopSlice = ApiSlice.injectEndpoints({
    endpoints: (builder) =>({
        getMonthlyReport: builder.query({
            query: ()=> '/monthly-report',
            providesTags: ['monthly-report'],
        }),

        getDailyReport: builder.query({
            query: ()=> "/daily-profit-loss",
            providesTags: ['daily-report'],
        }),
        

        getDailySaleProducts: builder.query({
            query: ()=> "/daily-report",
            providesTags: ['daily-sale-products'],
        }),

        getMonthlyReportById: builder.mutation({
            query: (id)=> `/month-report/${id}`,
            method: 'GET',
        }),

        getDailyReportById: builder.mutation({
            query: (id)=> `/daily-profit-loss/${id}`,
            method: 'GET',
        }),
        

        getDailySaleProductsById: builder.mutation({
            query: (id)=> `/daily-report/${id}`,
            method: 'GET',
        }),

        // getMonthlyReportById: builder.query({
        //     query: (id) => `/purchase-voucher/${id}`,
        //     providesTags: ['purchaseVoc'],
        // }),

        // getShopById: builder.query({
        //     query: (id) => `/shop/${id}`,
        //     providesTags: ['shop'],
        // }),

        // getStockByShop: builder.mutation({
        //     query: (id) => `/shop-store/product/${id}`,
        // }),

      
        
        

    })
})

export const {
    useGetMonthlyReportQuery,
    useGetDailyReportQuery,
    useGetDailySaleProductsQuery,
    useGetDailyReportByIdMutation,
    useGetDailySaleProductsByIdMutation,
    useGetMonthlyReportByIdMutation,
} = shopSlice



// (Math.ceil( ( (p.last_purchase.price + ( p.last_purchase.price * (p.remark/100)) ) * p.currency)/100)*100   );

// ( Math.ceil(((p.last_purchase.price + ( p.last_purchase.price * (p.remark/100)) )* p.currency)/100) *100 );
            