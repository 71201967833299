import React, { useState, useRef, useEffect } from "react";
import {
  useGetUnitQuery,
  useAddUnitMutation,
  useEditUnitMutation,
  useDeleteUnitMutation,
} from "../../api/unitSlice";
import Swal from "sweetalert2";

import {
  Button,
  Input,
  Card,
  Typography,
  Drawer,
  IconButton,
  Spinner,
  Tooltip,
} from "@material-tailwind/react";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { IoAddCircleOutline } from "react-icons/io5";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { BiTrash } from "react-icons/bi";
import { FaEdit } from "react-icons/fa";
import withReactContent from "sweetalert2-react-content";
import  SkeletonTable from "../../components/SkeletonTable";

const TABLE_HEAD = ["Unit ID", "Name", "Action"];
const Unit = () => {
  // Api Calling
  const { data, isLoading, isSuccess, refetch } = useGetUnitQuery();
  const [addUnit, result] = useAddUnitMutation();
  const [editUnit, editResult] = useEditUnitMutation();
  const [deleteUnit, deleteResult] = useDeleteUnitMutation();

  const [lstUnit, setLstUnit] = useState([]);
  useEffect(()=>{
    if(isSuccess){
      setLstUnit(data?.data);
    }
  },[data?.data])

  //Table columns
  const columns = isSuccess
    ? [
        { field: "id",
          headerName: "No", 
          align: 'center',
          headerAlign: 'center',
          flex: 1,
        },
        {
          field: "name",
          headerName: "Name",
          flex: 1,
          editable: false,
          align: 'center',
          headerAlign: 'center'
        },
        {
          field: "action",
          headerName: "Action",
          flex: 1,
          editable: false,
          align: 'center',
          headerAlign: 'center',
          renderCell: (params) => (
            <div className="flex flex-row gap-2 justify-center items-center">
              <Tooltip content="Edit" className="flex-1 bg-green-500">
                <IconButton

                  onClick={() => {
                    openDrawer(params.row);
                  }}
                  className="w-6 h-6 bg-green-200 p-4"
                >
                  <FaEdit className="h-4 w-4 text-green-600 font-extrabold" />
                </IconButton>
              </Tooltip>
              <Tooltip content="Delete" className="flex-1 bg-red-500">
                <IconButton onClick={() => confirmDelete(params.row.id)} variant="text" className="w-6 h-6 bg-red-200 p-4">
                  <BiTrash className="h-4 w-4 text-red-600" />
                </IconButton>
              </Tooltip>
            </div>
          ),
        },
      ]
    : [];

  //Open Drawer to edit Unit
  const [editUnitData, setEditUnitData] = useState();
  const [open, setOpen] = useState(false);
  const [addNew, setAddNew] = useState(false);
  const openDrawer = (unit) => {
    setEditUnitData(unit);
    editUnitRef.current.value = unit.name;
    setOpen(true);
  };
  const closeDrawer = () => setOpen(false);

  //add Unit
  const unitRef = useRef();
  const unitHandler = async () => {
    let body = {
      name: unitRef.current.value,
      status: 1,
      note: 1,
    };

    await addUnit(body);
    unitRef.current.value = "";

    refetch();
  };

  const pressEnterAddUnit = (event) =>{
    if(unitRef.current.value == ""){

    }
    if(event.key == 'Enter'){
      unitHandler();
    }
  }

  //Edit Unit
  const editUnitRef = useRef();
  const editUnitHandler = async () => {
    let body = {
      id: editUnitData.id,
      name: editUnitRef.current.value,
      status: editUnitData.status,
      note: editUnitData.note,
    };

    await editUnit(body);
    closeDrawer();
    refetch();
  };

  //Delete Unit
  const sweetAlert = withReactContent(Swal);
  const deleteUnitHandler = async (id) => {
    await deleteUnit(id);
    refetch();
  };

  const confirmDelete = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        deleteUnitHandler(id);
        Swal.fire("Deleted!", "Your Unit has been deleted.", "success");
      }
    });
  };


  return (
    <div className="flex flex-col gap-4 px-16">
      <React.Fragment>
        <Drawer placement="right" open={open} onClose={closeDrawer}>
          <div className="mb-2 flex items-center justify-between p-4">
            <Typography variant="h5" color="blue-gray">
              Edit Unit
            </Typography>
            <IconButton variant="text" color="blue-gray" onClick={closeDrawer}>
              <XMarkIcon strokeWidth={2} className="h-5 w-5" />
            </IconButton>
          </div>
          <form className="flex flex-col gap-6 p-4">
            <Input
              type="text"
              label="Name"
              defaultValue={open ? editUnitData.name : ""}
              inputRef={editUnitRef}
            />
            <Button onClick={editUnitHandler}>
              {editResult.isLoading ? <Spinner /> : "Save"}
            </Button>
          </form>
        </Drawer>
      </React.Fragment>
      {/* Caption */}
      <div className="flex flex-row w-full justify-between">
        <p className="px-4 py-2 bg-[#57626c] rounded-lg text-white font-bold">
          Unit
        </p>
        <Button 
              variant="outlined" onClick={()=> setAddNew(!addNew)}>
              {addNew ? "Close": "Add New"}
        </Button>
      </div>

      {/* Add New Section */}
      <div className={`${addNew==  true ? 'w-full px-6 py-2 h-20 border-2 border-gray-200 xl:flex xl:flex-row gap-2 items-end grid grid-cols-1 justify-center rounded-xl': 'hidden'}`}>
        {/* <form className="flex flex-row items-end" action={UnitHandler}> */}
          <div className=" w-32 md:w-96 m-2">
            <Input
              size="lg"
              label="Unit Name"
              inputRef={unitRef}
              onKeyDown={pressEnterAddUnit}
              required
            />
          </div>
          <div className="flex-none m-2">
            <Button
            onClick={unitHandler}
              type="submit"
              variant="outlined"
              className="flex items-center gap-3 px-4 py-2 m-1"
            >
              {result.isLoading ? (
                <Spinner color="indigo" />
              ) : (
                <IoAddCircleOutline strokeWidth={2} className="h-5 w-5" />
              )}
              Add
            </Button>
          </div>
        {/* </form> */}
      </div>

      {/* Unit List Table */}
      <div className="w-full">
        <Card className="overflow-scroll h-full w-full">
          {isLoading ? (
            <SkeletonTable/>
          ) : (
            isSuccess ? (
              <DataGrid
                rows={lstUnit}
                columns={columns}
                rowHeight={80}
                density="compact"
                initialState={{
                  pagination: {
                    paginationModel: {
                      pageSize: 10,
                    },
                  },
                }}
                pageSizeOptions={[5,10,25,50,100]}
                disableRowSelectionOnClick
                slots={{ toolbar: GridToolbar }}
                slotProps={{
                  toolbar: {
                    showQuickFilter: true,
                    quickFilterProps: { debounceMs: 200 },
                  },
                }}
              />
            ) : (
              <></>
            )
          )}
        </Card>
      </div>
    </div>
  );
}

export default Unit