import { ApiSlice } from "./ApiSlice";

export const dashboardSlice = ApiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getDashboardData: builder.query({
            query: () => '/dashboard',
        }),

        // adddashboard: builder.mutation({
        //     query: (body) => ({
        //         url: '/dashboard',
        //         method: 'POST',
        //         body,
        //     })
        // }),

    })
});

export const {
    useGetDashboardDataQuery,

} = dashboardSlice