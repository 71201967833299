import React, { useState, useRef, useEffect } from "react";
import { useGetDailySaleProductsQuery } from "../../api/reportSlice";
import Swal from "sweetalert2";

import {
  Button,
  Input,
  Card,
  Typography,
  Drawer,
  IconButton,
  Spinner,
  Tooltip,
} from "@material-tailwind/react";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { IoAddCircleOutline } from "react-icons/io5";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { BiTrash } from "react-icons/bi";
import { FaEdit } from "react-icons/fa";
import withReactContent from "sweetalert2-react-content";
import  SkeletonTable from "../../components/SkeletonTable";



const DailySaleProduct = () => {
  // Api Calling
  const { data, isLoading, isSuccess, refetch } = useGetDailySaleProductsQuery();

  const [lstDailyProduct, setLstDailyProductReport] = useState([]);

  useEffect(()=>{
      if(isSuccess){
        const rows = data?.data.map((r, index) =>
        true
          ? {
              ...r,
              no: index + 1,
              product_name: r.product.name,
              barcode: r.product.barcode,
              amount: r.sell_price * r.qty,
              shop_name: r.shop.name,

            }
          : { ...r }
      );
      setLstDailyProductReport(rows);
      }
    

  },[data?.data])

  //Table columns
  const columns = isSuccess
    ? [
        { field: "no",
          headerName: "No", 
          align: 'center',
          headerAlign: 'center',
          flex: 1,
        },
        {
          field: "product_name",
          headerName: "Product Name",
          flex: 1,
          editable: false,
          align: 'center',
          headerAlign: 'center'
        },
        {
            field: "barcode",
            headerName: "Barcode",
            flex: 1,
            editable: false,
            align: 'center',
            headerAlign: 'center'
            },
            {
            field: "sell_price",
            headerName: "Sell Price",
            flex: 1,
            editable: false,
            align: 'center',
            headerAlign: 'center'
            },
            {
            field: "qty",
            headerName: "Qty",
            flex: 1,
            editable: false,
            align: 'center',
            headerAlign: 'center'
            },
            {
                field: "amount",
                headerName: "Amount",
                flex: 1,
                editable: false,
                align: 'center',
                headerAlign: 'center'
                },
                {
                    field: "shop_name",
                    headerName: "Shop",
                    flex: 1,
                    editable: false,
                    align: 'center',
                    headerAlign: 'center'
                    },
        {
          field: "action",
          headerName: "Action",
          flex: 1,
          editable: false,
          align: 'center',
          headerAlign: 'center',
          renderCell: (params) => (
            <div className="flex flex-row gap-2 justify-center items-center">
              {/* <Tooltip content="Edit" className="flex-1 bg-green-500">
                <IconButton

                  onClick={() => {
                    // editCategoryRef.current.value = params.row.name;

                    // openDrawer(params.row);
                  }}
                  className="w-6 h-6 bg-green-200 p-4"
                >
                  <FaEdit className="h-4 w-4 text-green-600 font-extrabold" />
                </IconButton>
              </Tooltip>
              <Tooltip content="Delete" className="flex-1 bg-red-500">
                <IconButton onClick={()=>{}} variant="text" className="w-6 h-6 bg-red-200 p-4">
                  <BiTrash className="h-4 w-4 text-red-600" />
                </IconButton>
              </Tooltip> */}
            </div>
          ),
        },
      ]
    : [];


  


  return (
    <div className="flex flex-col gap-4 px-16 max-h-full">
      
      {/* Caption */}
      <div className="flex flex-row w-full justify-between">
        <p className="px-4 py-2 bg-[#57626c] rounded-lg text-white font-bold">
          Daily Sale Products
        </p>
        {/* <Button 
              variant="outlined" onClick={()=> setAddNew(!addNew)}>
              {addNew ? "Close": "Add New"}
        </Button> */}
      </div>


      {/* Category List Table */}
      <div className="w-full">
        <Card className="overflow-scroll h-full w-full">
          {isLoading ? (
            <SkeletonTable/>
          ) : (
            isSuccess ? (
              <DataGrid
                rows={lstDailyProduct}
                columns={columns}
                rowHeight={80}
                density="compact"
                initialState={{
                  pagination: {
                    paginationModel: {
                      pageSize: 10,
                    },
                  },
                }}
                pageSizeOptions={[5,10,25,50,100]}
                disableRowSelectionOnClick
                slots={{ toolbar: GridToolbar }}
                slotProps={{
                  toolbar: {
                    showQuickFilter: true,
                    quickFilterProps: { debounceMs: 200 },
                  },
                }}
              />
            ) : (
              <></>
            )
          )}
        </Card>
      </div>
    </div>
  );
};

export default DailySaleProduct;
