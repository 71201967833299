import React from 'react'
import { useReactToPrint } from 'react-to-print';
import { useState, useEffect, useRef, forwardRef } from 'react';
import Logo from "../../assets/ttmm.png";

const PrintPurchase = forwardRef((props, ref) => {

    const { voucher, items } = props;
    console.log(props.items)
    var today = new Date();
    var myanmarNumbers = require("myanmar-numbers");
    const date =
      today.getFullYear() +
      "-" +
      (today.getMonth() + 1) +
      "-" +
      today.getDate() +
      "-" +
      today.getHours() +
      ":" +
      today.getMinutes() +
      ":" +
      today.getSeconds();
  
    
      function currencyFormat(num) {
        return num.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
     }
  
    return (
      <>
        <div id='divToPrint' className="flex flex-col h-fit text-center" ref={ref}>
  
          <div className="flex flex-row h-fit pt-2 justify-center items-center">
            <img src={Logo} alt="slip" width={150} height={50} />
          </div>
          <div className="flex flex-col w-full h-fit py-2 justify-center items-center">
          
            <p className="font-bold font-poppins text-xs px-8">
              စပါးရိတ်သိမ်းခြွေလှေ့စက် ၊ ထွန်စက် နှင့် အပိုပစ္စည်း အရောင်းဆိုင်
            </p>
            <p className="font-bold font-poppins text-xs">09-264672327, 09-771026957</p>
            {/* <p className="font-bold font-poppins text-xs">{shop.phone}</p> */}
          </div>
          <div className='flex flex-row w-full justify-between px-10'>
            {/* Voucher Information */}
            <div className='flex flex-row w-200 justify-start'>
              <div className="flex-none flex-col w-50 px-2 justify-between items-start">
                <p className="font-poppins text-left">Voucher ID</p>
                <p className="font-poppins text-left">Cashier</p>
                <p className="font-poppins text-left">Date</p>
              </div>

              <div className="flex-1 flex-col px-2 justify-between items-start">
                <p className="font-poppins text-left">- {voucher.voc.id}</p>
                <p className="font-poppins text-left">- {voucher.cashier}</p>
                <p className="font-poppins text-left">- {voucher.voc.buy_date}</p>
              </div>
            </div>
            {/* Supplier information */}
            <div className='flex flex-row w-200 justify-start px-10'>
              <div className="flex-none flex-col w-50 h-fit px-2 justify-between items-start">
                <p className="font-poppins text-left">Supplier</p>
                <p className="font-poppins text-left">Address</p>
                <p className="font-poppins text-left">Phone</p>
              </div>
              <div className="flex flex-col h-fit px-2 justify-between items-start">
                <p className="font-poppins text-left">- {voucher.voc.supplier.name}</p>
                <p className="font-poppins text-left">- {voucher.voc.supplier.address}</p>
                <p className="font-poppins text-left">- {voucher.voc.supplier.phone}</p>
              </div>
            </div>
          </div>
  
      
          <div className="w-full px-10">
                <table className="w-full text-base text-left text-black border border-black border-collapse py-5">
                  <thead className="text-xs text-white uppercase bg-blue-400 dark:text-white">
                    <tr>
                      <th scope="col" className="px-6 py-3 border border-black border-collapse">
                        စဥ်
                      </th>
                      <th scope="col" className="px-6 py-3 border border-black border-collapse">
                        အမျိုးအမည်
                      </th>
                      <th scope="col" className="px-3 py-3 border border-black border-collapse">
                       စျေးနှုန်း
                      </th>
                      <th scope="col" className="px-6 py-3 border border-black border-collapse">
                        အရေအတွက်
                      </th>
                      <th scope="col" className="px-6 py-3 border border-black border-collapse">
                        သင့်ငွေ
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {props.items.map((obj,index) => <tr className="border border-black border-collapse">
                      <td scope="row" className="px-6 py-1 border border-black border-collapse text-right">
                      { obj.no}
                      </td>
                      <td className="px-2 py-1 border border-black border-collapse">{obj.name}</td>
                      <td className="px-2 py-1 border border-black border-collapse text-right">{currencyFormat(parseInt(obj.price))}</td>
                      <td className="px-2 py-1 border border-black border-collapse text-center">{currencyFormat(parseInt(obj.qty))}</td>
                      <td className="px-2 py-1 border border-black border-collapse text-right">{currencyFormat(parseInt(obj.amount))}</td>
                    </tr>)}
                    
                  </tbody>
                </table>
              </div>
            {/* <div className='flex flex-col justify-end px-10'> */}
              {/* Voucher Summary Section */}
              <div className='flex flex-row w-full px-12 py-3 justify-end items-end'>
                <div className="flex-initial flex-col px-2 justify-between items-start text-left">
                  <p className="font-poppins">ကျသင့်ငွေ</p>
                  <p className="font-poppins">ပေးချေငွေ</p>
                  <p className="font-poppins">အကြွေးကျန်</p>
                </div>
                <div className="flex-initial flex-col justify-between items-end text-right whitespace-pre">
                  <p className="font-poppins"> - </p>
                  <p className="font-poppins"> - </p>
                  <p className="font-poppins"> - </p>
                </div>
                <div className="flex-initial flex-col justify-between items-end text-right">
                  <p className="font-poppins">{currencyFormat(parseInt(voucher.total))} Kyats</p>
                  <p className="font-poppins">{currencyFormat(parseInt(voucher.cash))} Kyats</p>
                  <p className="font-poppins">{currencyFormat(parseInt(voucher.bill))} Kyats</p>
                </div>
              {/* </div> */}

                {/* <div className="flex flex-row w-full px-5 pr-2 py-2 justify-end font-poppins text-base">
                    <span>စုစုပေါင်း - </span>{currencyFormat(parseInt(voucher.total))} Kyats
                </div>
                <div className="flex flex-row w-full px-5 pr-2 py-2 justify-end font-poppins text-base">
                    <span>ပေးချေငွေ - </span>{currencyFormat(parseInt(voucher.cash))} Kyats
                </div>
                <div className="flex flex-row w-full px-5 pr-2 py-2 justify-end font-poppins text-base">
                    <span>ကျန်ငွေ - </span>{currencyFormat(parseInt(voucher.bill))} Kyats
                </div> */}
            </div>
          <div className="w-full h-fit text-base text-center mt-4">
          🤝  အထူးကျေးဇူးတင်ရှိပါသည်။  🤝
          </div>
          <div className="flex flex-row w-full py-2 px-10 justify-start font-poppins text-xs mb-10">
            <span>Print Time -</span>
            {date}
          </div>
          <div>
            --------------------------<span className="transform rotate-270 font-bold">✂</span>---------------------------------
          </div>
        </div>
      </>
    );
  });

  export default PrintPurchase