import React, { useState, useRef, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { addLstProduct} from '../../api/productListSlice';
import { addCurrency } from "../../api/currencyStoreSlice";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { useEditProductMutation, useGetProductByIdMutation, useGetProductQuery, useDeleteProductMutation, useChangeProfitPercentMutation } from "../../api/productSlice";
import { useGetCategoryQuery } from "../../api/categorySlice";
import { useGetUnitQuery } from "../../api/unitSlice";
import { useEditPurchaseQtyMutation } from "../../api/purchaseCheckoutSlice";
import { BiTrash, BiDetail } from "react-icons/bi";
import { FaEdit } from "react-icons/fa";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { Link, useNavigate } from 'react-router-dom';
import {
  Drawer,
  Button,
  Select,
  Avatar,
  Tooltip,
  Input,
  Option,
  Spinner,
  IconButton,
  Typography,
} from "@material-tailwind/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import {
  LinearProgress,
  Dialog,
  AppBar,
  Toolbar,
  Slide,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
} from '@mui/material';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Product = () => {

  const rtkLstCurrency = useSelector((state) => state.store.lstCurrency);
  const rtkLstProduct = useSelector((state) => state.productList.lstProduct);

  
  const dispatch = useDispatch();
  const [lstCurrency, setLstCurrency] = useState([]);
  const [lstProduct, setLstProduct] = useState([]);
  //get category id and unit id from Material Select components
  const [cateId, setCateId] = useState("");
  const [cateN, setCateN] = useState("");
  const [unitId, setUnitId] = useState("");
  const [unitN, setUnitN] = useState("");
  const [curId, setCurId] = useState(4);
  const [curN, setCurN] = useState("Kyats");
  const [changePrice, setChangePrice]= useState(1);
  const [lastPurchase, setLastPurchase] = useState(0);
  //To edit image file
  const [editImage, setEditImage] = useState();

  //Handle the drawer
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [openDialog, setOpenDialog] = useState(false);
  const [openQty, setOpenQty] = useState(false);
  
  

  //Product Details Section
  const [selectItem, setSelectItem] = useState(null);
  const [lstdetails, setLstDetails] = useState([]);
  const [purchaseId, setPurchaseId] = useState();
  const [itemQty, setItemQty] = useState(0);
  const [itemPrice, setItemPrice] = useState(0);
  const [qtyDiff, setQtyDiff] = useState(0);
  const [ purchaseQty, setPurchaseQty ] = useState(0);
  const [ distributeQty, setDistributeQty] = useState(0);

  //Api Calling
  const { data, isLoading, isSuccess, refetch } = useGetProductQuery();
  const [trigger , {data:dataDetails, isLoading:isLoadingDetails, isSuccess:isSuccessDetails, refetch:refetchDetails}] = useGetProductByIdMutation();
  //const {data:dataDetails, isLoading:isLoadingDetails, isSuccess:isSuccessDetails, refetch:refetchDetails} = useGetProductByIdQuery(itemId);
  const {
    data: lstCategory,
    isLoading: isLoadingCategory,
    isSuccess: isSuccessCategory,
    refetch: refetchCategory,
  } = useGetCategoryQuery();
  const {
    data: lstUnit,
    isLoading: isLoadingUnit,
    isSuccess: isSuccessUnit,
    refetch: refetchUnit,
  } = useGetUnitQuery();
  const [editProductItem, editResult] = useEditProductMutation();
  const [editPurchaseQty, resultQty] = useEditPurchaseQtyMutation();
  const [deleteProduct, resultDelete] = useDeleteProductMutation();
  const [editProfitPercent, resultProfitPercent] = useChangeProfitPercentMutation();


  const [editProduct, setEditProduct] = useState();

  const refEditName = useRef();
  const refEditBarcode = useRef();
  const refEditAlertQty = useRef();
  const refEditLocation = useRef();
  // const refEditSellPrice1 = useRef();
  // const refEditSellPrice2 = useRef();
  // const refEditSellPrice3 = useRef();
  const refProfitPercent = useRef();
  const refSellPrice = useRef();
  const refEditQty = useRef();
  const refEditPrice = useRef();

  useEffect(()=>{
    // setLstCurrency(rtkLstCurrency);
    refEditAlertQty.current.value = 0;
    //refEditQty.current.value = 0;
    refProfitPercent.current.value = 0;
    refSellPrice.current.value = 0;
    
  },[])

  // useEffect(()=>{
  //   setLstCurrency(rtkLstCurrency);
    
  // },[rtkLstCurrency])




  useEffect(()=>{
    if (isSuccessDetails) {
    const rows = dataDetails?.data.purchase.map((p, index) =>
        true
          ? {
              ...p,
              no:index+1,
              name: selectItem.name
            }
          : { ...p }
      );
    setLstDetails([...rows])
    setPurchaseQty(dataDetails.data.purchase_qty);
    setDistributeQty(dataDetails.data.distribute_qty);

    console.log(lstdetails)
          }
  },[dataDetails?.data])

  //Table columns
  const columns = [
        { field: "no", headerName: "No.", width: 100 },
        {
          field: "img",
          headerName: "Image",
          width: 100,
          editable: false,
          renderCell: (params) => (
            <Avatar
              src={`https://warehouse.futuretechmm.net/pos-vendor-backend/public/${params.value}`}
              className="w-16 h-16 border-blue-gray-50 bg-blue-gray-50/50 object-contain"
            />
          ),
        },
        {
          field: "name",
          headerName: "Product Name",
          flex: 1,
          editable: false,
        },
        {
          field: "categoryName",
          headerName: "Category",
          width:130,
          editable: false,
        },
        {
          field: "unitName",
          headerName: "Unit",
          width: 80,
          editable: false,
        },
        {
          field: "stock",
          headerName: "Stock",
          width: 80,
          editable: false,
        },
        {
          field: "locate",
          headerName: "Location",
          width: 80,
          editable: false,
        },
        {
          field: "barcode",
          headerName: "Barcode",
          width: 60,
          editable: false,
        },
        {
          field: "purchase_price",
          headerName: "Purchase Price",
          type: 'number',
          width:100,
          editable: false,
        },
        {
          field: "remark",
          headerName: "Profit Percent",
          type: 'number',
          width:100,
          editable: false,
        },
        {
          field: "sell_price",
          headerName: "Sell Price",
          type: 'number',
          width:100,
          editable: false,
        },
        {
          field: "prodId",
          headerName: "Action",
          width:150,
          editable: false,
          renderCell: (params) => (
            <div className="flex flex-row gap-2">
              <Tooltip content="Details" className="flex-1 bg-green-500">
                <IconButton className="w-6 h-6 bg-green-200 p-4" onClick={()=> {
                  console.log(params.row.id)
                  setSelectItem(params.row);
                  setLstDetails([]);
                  trigger(params.row.id);
                  setOpenDialog(true);
                  }}>
                  <BiDetail className="h-4 w-4 text-green-600" />
                </IconButton>
              </Tooltip>
              <Tooltip content="Edit" className="flex-1 bg-blue-500">
                <IconButton
                  onMouseOver={()=>setCateId(params.row.category_id)}

                  onClick={() => {
                    console.log(params.row.alert_qty);
                    setCateId(params.row.category_id)
                    setCateN(params.row.categoryName)
                    setEditImage(params.row.img);
                    setUnitId(params.row.unit_id);
                    setUnitN(params.row.unitName);
                    setEditProduct(params.row.id);
                    setCurId(params.row.note);
                    setCurN(rtkLstCurrency[params.row.note-1].currency);
                    setChangePrice(params.row.currency);
                    setLastPurchase(params.row.purchase_price);
                    refEditName.current.value = params.row.name;
                    refEditBarcode.current.value = params.row.barcode;
                    // refEditLocation.current.value = params.row.locate;
                    refEditAlertQty.current.value = params.row.alert_qty;
                    refProfitPercent.current.value = params.row.remark;
                    refSellPrice.current.value = params.row.sell_price;
                    // refEditSellPrice1.current.value = params.row.sell_price1;
                    // refEditSellPrice2.current.value = params.row.sell_price2;
                    // refEditSellPrice3.current.value = params.row.sell_price3;

                    openDrawer(params.row);
                  }}
                  className="w-6 h-6 bg-blue-200 p-4"
                >
                  <FaEdit className="h-4 w-4 text-blue-600 font-extrabold" />
                </IconButton>
              </Tooltip>
              <Tooltip content="Delete" className="flex-1 bg-red-500">
                <IconButton className="w-6 h-6 bg-red-200 p-4" onClick={()=>confirmDelete(params.row.id)}>
                  <BiTrash className="h-4 w-4 text-red-600" />
                </IconButton>
              </Tooltip>
            </div>
          ),
        },
      ];

    //Table columns
  const columnsDetails =  [
      { field: "no", headerName: "No.", width: 50 },
      {
        field: "name",
        headerName: "Product Name",
        flex: 1,
        editable: false,
      },
      {
        field: "price",
        headerName: "Price",
        width:100,
        editable: false,
      },
      {
        field: "qty",
        headerName: "Qty",
        width: 100,
        editable: false,
      },
      {
        field: "amount",
        headerName: "Amount",
        width: 130,
        editable: false,
      },
      {
        field: "expire_date",
        headerName: "Expire Date",
        width: 100,
        editable: false,
      },
      {
        field: "prodId",
        headerName: "Action",
        width:100,
        editable: false,
        renderCell: (params) => (
          <div className="flex flex-row gap-2">
            <Tooltip content="Edit" className="flex-1 bg-blue-500">
              <IconButton
                onClick={() => {
                  setPurchaseId(params.row.id);
                  setItemQty(params.row.qty);
                  setItemPrice(params.row.price);
                  setOpenQty(true);

                }}
                className="w-6 h-6 bg-blue-200 p-4"
              >
                <FaEdit className="h-4 w-4 text-blue-600 font-extrabold" />
              </IconButton>
            </Tooltip>
            {/* <Tooltip content="Delete" className="flex-1 bg-red-500">
              <IconButton className="w-6 h-6 bg-red-200 p-4">
                <BiTrash className="h-4 w-4 text-red-600" />
              </IconButton>
            </Tooltip> */}
          </div>
        ),
      },
    ];


  //Select Image
  const [preview, setPreview] = useState(null);
  const [selectedImage, setSelectedImage] = useState();

  //set Preview Image and set selected Image
  const PreviewUpload = (e) => {
    const file = e.target.files[0];
    setSelectedImage(file);
    setPreview(URL.createObjectURL(file));
  };
  //Open and Close the Drawer
  const openDrawer = () => setOpen(true);
  const closeDrawer = () => setOpen(false);
  const handleCloseDialog = () => setOpenDialog(false);
  const handleCloseQty = () => setOpenQty(false);

  //Edit Product
  let formData = new FormData();
  const editProductHandler = async () => {
    console.log(cateId)
    formData.append("id", editProduct);
    formData.append("category_id", cateId);
    formData.append("unit_id", unitId);
    formData.append("barcode", refEditBarcode.current.value);
    // formData.append("location",refEditLocation.current.value);
    formData.append("name", refEditName.current.value);
    formData.append("alert_qty", refEditAlertQty.current.value);
    formData.append("sell_price1", 0);
    formData.append("sell_price2", 0);
    formData.append("sell_price3", 0);
    formData.append("file", selectedImage);
    formData.append("status", "1");
    formData.append("note", curId);
    formData.append("remark", refProfitPercent.current.value);

    await editProductItem(formData);
    
    closeDrawer();
  };

  useEffect(()=>{
    if(editResult.isSuccess){
      
    let newList = lstProduct.map((obj) => {
      if(obj.id == editProduct){
        console.log(obj.id)
        return{
          ...obj,
          category_id: cateId,
          unit_id: unitId,
          barcode: refEditBarcode.current.value,
          // locate: refEditLocation.current.value,
          name: refEditName.current.value,
          alert_qty: refEditAlertQty.current.value,
          sell_price1: 0,
          sell_price2: 0,
          sell_price3: 0,
          remark: refProfitPercent.current.value,
          categoryName: cateN,
          sell_price: obj.last_purchase != null ? (Math.ceil(((obj.last_purchase.price + ( obj.last_purchase.price * (refProfitPercent.current.value/100)))* obj.currency)/100)*100): 0,
          unitName: unitN,
        };
        
      }
      return {...obj}
      
    })
    //setLstProduct(newList);
    dispatch(addLstProduct(newList));
    console.log(newList)
    }
  },[editResult])

  const editPurchaseHandler = async () =>{
    let body = {
      id: purchaseId,
      qty: refEditQty.current.value,
      price: refEditPrice.current.value,
    }
    await editPurchaseQty(body);
    
    trigger(selectItem.id);
    handleCloseQty();
    await refetch();
  }

  const refEditProfitPercent = useRef(0);
  const [openPPDialog, setOpenPPDialog] = useState(false);
  const closePPDialog = () => setOpenPPDialog(false);
  const editProfitPercentHandler = async()=>{
    let body = {
      percent: refEditProfitPercent.current.value
    }

    await editProfitPercent(body);
    refetch();
    const rows = lstProduct.map((p, index) =>
      true
        ? {
            ...p,
            categoryName: p.category.name,
            unitName: p.unit.name,
            prodId: p.id,
            locate: p.location != null ? p.location.location: "Unknown",
            remark: parseInt(p.remark) + parseInt(refEditProfitPercent.current.value),
            sell_price: p.last_purchase != null ? (Math.ceil(((p.last_purchase.price + ( p.last_purchase.price * ((parseInt(p.remark)+ parseInt(refEditProfitPercent.current.value))/100)))* p.currency)/100)*100): 0,
            purchase_price: p.last_purchase != null ? p.last_purchase.price : 0,
          }
        : { ...p }
    );
    dispatch(addLstProduct(rows))
    setOpenPPDialog(false);
  }

  useEffect(()=>{
    console.log("resultQty")
    if(resultQty.isSuccess){
      console.log("IN")
      console.log(refEditPrice.current.value)
      const diff = refEditQty.current.value - itemQty;
      //setQtyDiff(diff);
      const newList = lstProduct.map((item, index)=>{
        if(item.id == selectItem.id){
          console.log(refEditPrice.current.value * (item.remark/100))
          return{
            ...item,
            stock: parseInt(item.stock) + diff,
            sell_price: (Math.ceil(((parseInt(refEditPrice.current.value) + parseInt(refEditPrice.current.value * (item.remark/100)) ) * item.currency)/100)*100),
          };
        }
        return item;
      });
      setLstProduct(newList);
      console.log(lstProduct);
      resultQty.isSuccess = false;
    }
  },[resultQty])

  //Create list of product data to show on table
  useEffect(() => {
      const rows = rtkLstProduct.map((p, index) =>
        true
          ? {
              ...p,
              no: index + 1,
            }
          : { ...p }
      );
      setLstProduct(rows);
      setLoading(false);
  }, [rtkLstProduct]);

  useEffect(()=>{
    if(isSuccess){
      const rows = data?.data.map((p, index) =>
      true
        ? {
            ...p,
            categoryName: p.category.name,
            unitName: p.unit.name,
            prodId: p.id,
            locate: p.location != null ? p.location.location: "Unknown",
            sell_price: p.last_purchase != null ? (Math.ceil(((p.last_purchase.price + ( p.last_purchase.price * (p.remark/100)))* p.currency)/100)*100): 0,
            purchase_price: p.last_purchase != null ? p.last_purchase.price : 0,
          }
        : { ...p }
    );
      dispatch(addLstProduct(rows))
    }
  },[data?.data])

    //Delete Category
    const sweetAlert = withReactContent(Swal);
    const deleteProductHandler = async (id) => {
      await deleteProduct(id);
      //refetch();
    };
  
    const confirmDelete = (id) => {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          deleteProductHandler(id);
          Swal.fire("Deleted!", "Your product has been deleted.", "success");
        }
      });
    };

  return (
    <div className="flex flex-col gap-4">
      <>
      {/* Drawer to Edit product */}
        <Drawer size={300} placement="right" open={open} onClose={closeDrawer}>
          
            <div className="mb-2 flex items-center justify-between p-4">
              <Typography variant="lead" color="blue-gray">
                Edit Product
              </Typography>
              <IconButton variant="text" color="blue-gray" onClick={closeDrawer}>
                <XMarkIcon strokeWidth={2} className="h-5 w-5" />
              </IconButton>
            </div>
            <div className="h-full overflow-y-auto pb-20">
            <form className="flex flex-col gap-6 p-4 items-center">
              <label
                for="dropzone-file"
                class="flex flex-col items-center justify-center cursor-pointer"
              >
                {preview != null ? (
                  <Avatar
                    src={`${open ? preview : ""}`}
                    alt={open ? editImage : ""}
                    className="border w-24 h-24 border-blue-gray-50 bg-blue-gray-50/50 object-contain p-1"
                  />
                ) : (
                  <Avatar
                    src={`https://warehouse.futuretechmm.net/pos-vendor-backend/public/${open ? editImage : ""}`}
                    alt={open ? editImage : ""}
                    className="border w-24 h-24 border-blue-gray-50 bg-blue-gray-50/50 object-contain p-1"
                  />
                )}
                <input
                  id="dropzone-file"
                  type="file"
                  class="hidden"
                  onChange={(e) => PreviewUpload(e)}
                />
              </label>
              <Input
                type="text"
                label="Name"
                defaultValue={open ? refEditName.current.value : ""}
                inputRef={refEditName}
              />
              <Input
                type="text"
                label="Barcode"
                defaultValue={open ? refEditBarcode.current.value : ""}
                inputRef={refEditBarcode}
              />
              {/* <Input
                type="text"
                label="Location"
                defaultValue={open ? refEditLocation.current.value : ""}
                inputRef={refEditLocation}
              /> */}
              <Select
                value={cateN}
                className="text-black"
                label="Category"
                size="lg"
                // onChange={(e) =>{
                //   setCateId(e)
                // }}
              >
                {isSuccessCategory ? (
                  lstCategory.data.map((cate, i) => (
                    <Option onClick={()=>
                      { setCateId(cate.id)
                      setCateN(cate.name)}
                      
                    } value={cate.id.toString()}> {cate.name} </Option>
                  ))
                ) : (
                  <Option>No Data</Option>
                )}
              </Select>
              <Select
                className="text-black"
                label="Unit"
                size="lg"
                value={unitN}
                // onChange={(e) => setUnitId(e)}
              >
                {isSuccessUnit ? (
                  lstUnit.data.map((unit, i) => (
                    <Option onClick={()=>{
                      setUnitId(unit.id)
                      setUnitN(unit.name)
                    }} value={unit.id}> {unit.name} </Option>
                  ))
                ) : (
                  <Option>No Data</Option>
                )}
              </Select>
              <Select
                value={curN}
                className="text-black"
                label="Currency"
                size="lg"
                // onChange={(e) =>{
                //   setCateId(e)
                // }}
              >
                {isSuccessCategory ? (
                  rtkLstCurrency.map((cur, i) => (
                    <Option onClick={()=>
                      { setCurId(cur.id)
                      setCurN(cur.currency)}
                      
                    } value={cur.id.toString()}> {cur.currency} </Option>
                  ))
                ) : (
                  <Option>No Data</Option>
                )}
              </Select>
              <Input
                type="text"
                label="ALert Qty"
                defaultValue={open ? refEditAlertQty.current.value : ""}
                inputRef={refEditAlertQty}
              />
              <Input
                type="text"
                label="Purchase Price"
                defaultValue={open ? lastPurchase : ""}
                readOnly={true}
              />
              <Input
                type="text"
                label="Profit Percent"
                inputRef={refProfitPercent}
                onChange={(e)=>{
                  if(e.target.value != "")
                  {
                    console.log(changePrice)
                    refSellPrice.current.value = (Math.ceil(((parseInt(lastPurchase) + ( parseInt(lastPurchase) * (parseInt(e.target.value)/100)))* changePrice)/100)*100)
                  }else{
                    refSellPrice.current.value = lastPurchase
                  }
                  
                }}
              />
              <Input
                type="text"
                pattern="[0-9]*"
                label="Sell Price"
                inputRef={refSellPrice}
                onChange={(e)=>{
                  if(e.target.value != "")
                  {
                    refProfitPercent.current.value = Math.floor((((parseInt(e.target.value)/changePrice) - parseInt(lastPurchase))/parseInt(lastPurchase))*100)
                  }else{
                    refProfitPercent.current.value = -100
                  }
                }}
              />
              <Button onClick={editProductHandler}>
                {editResult.isLoading ? <Spinner /> : "Save"}
              </Button>
            </form>
          </div>
        </Drawer>
        {/* End of Drawer to Edit product */}
      </>

      <>
      {/* Product Details Dialog */}
      <Dialog
        fullScreen
        open={openDialog}
        onClose={handleCloseDialog}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: 'relative' }} color='default'>
          <Toolbar className="flex flex-row justify-between px-6">
            <Typography edge="start" sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              {selectItem?.name}
            </Typography>
            <Button autoFocus variant="outlined"  color="blue" onClick={handleCloseDialog}>
              Close
            </Button>
          </Toolbar>
        </AppBar>
        <div className="flex flex-col md:flex-row gap-2 px-6 pt-6">
          {/* Product Details */}
          <div className="flex-1 w-1/4 p-2 gap-4 items-end grid grid-cols-1 justify-center">
            <div className="flex-1">
                <Input
                  size="sm"
                  label="Barcode"
                  variant="standard"
                  value={selectItem?.barcode}
                  readOnly
                />
              </div>
              <div className="flex-1">
                <Input
                  size="sm"
                  label="Category"
                  variant="standard"
                  value={selectItem?.category.name}
                  readOnly
                />
              </div>
              <div className="flex-1">
                <Input
                  size="sm"
                  label="Unit"
                  variant="standard"
                  value={selectItem?.unit.name}
                  readOnly
                />
              </div>
              <div className="flex-1">
                <Input
                  size="sm"
                  label="Total"
                  variant="standard"
                  value={purchaseQty}
                  readOnly
                />
              </div>
              <div className="flex-1">
                <Input
                  size="sm"
                  label="Distribute"
                  variant="standard"
                  value={distributeQty}
                  readOnly
                />
              </div>
              <div className="flex-1">
                <Input
                  size="sm"
                  label="In Stock"
                  variant="standard"
                  value={purchaseQty - distributeQty}
                  readOnly
                />
              </div>
              <div className="flex-1">
                <Input
                  size="sm"
                  label="Alert Qty"
                  variant="standard"
                  value={selectItem?.alert_qty}
                  readOnly
                />
              </div>
              <div className="flex-1">
                <Input
                  size="sm"
                  label="Profit Percent"
                  variant="standard"
                  value={selectItem?.remark}
                  readOnly
                />
              </div>
              <div className="flex-1">
                <Input
                  size="sm"
                  label="Sell Price"
                  variant="standard"
                  value={selectItem?.sell_price}
                  readOnly
                />
              </div>
          </div>

          

          {/* Product Details List Table */}
          <div className="w-3/4">
            <DataGrid
              rows={lstdetails}
              columns={columnsDetails}
              rowHeight={80}
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 5,
                  },
                },
              }}
              density="compact"
              pageSizeOptions={[5, 10, 25, 50, 100]}
              disableRowSelectionOnClick
              slots={{ toolbar: GridToolbar }}
              slotProps={{
                toolbar: {
                  showQuickFilter: true,
                  quickFilterProps: { debounceMs: 200 },
                },
              }}
            />
          </div>
        </div>
      </Dialog>

      <Dialog open={openQty} onClose={ (e,t) =>{
        if(t == "backdropClick" && resultQty.isSuccess){
          handleCloseQty();
        }
      }}>
        <DialogTitle>Change Quantity</DialogTitle>
        <DialogContent>
        <TextField
            defaultValue={itemPrice}
            inputRef={refEditPrice}
            autoFocus
            margin="dense"
            id="name"
            label="Price"
            type="number"
            fullWidth
            variant="standard"
          />
          <TextField
            defaultValue={itemQty}
            inputRef={refEditQty}
            autoFocus
            margin="dense"
            id="name"
            label="Qty"
            type="number"
            fullWidth
            variant="standard"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={!resultQty.isLoading?  handleCloseQty : ()=>{}}>Cancel</Button>
          <Button onClick={()=>editPurchaseHandler()}>{resultQty.isLoading ? <Spinner /> : "Save"}</Button>
        </DialogActions>
      </Dialog>
      {/* End of Product Details Dialog */}
      </>
      {/* Caption */}
      <div className="flex flex-row w-full justify-between">
        <p className="px-4 py-2 bg-[#57626c] rounded-lg text-white font-bold">
          Product
        </p>
        <div className='flex flex-row items-center text-center'>
          <Button 
                  variant="filled" color="green" className="mx-5" onClick={() =>setOpenPPDialog(true)} >
                  {"Change All Profit Percent"}
          </Button>
          <Link to={`/addProduct`}>
            <Button 
                  variant="outlined">
                  {"Add New"}
            </Button>
          </Link>
        </div>
        
      </div>

      {/* Change All Profit Percent */}
      <Dialog open={openPPDialog} onClose={ (e,r) =>{
            if(r == "backdropClick" && resultProfitPercent.isSuccess){
              setOpenPPDialog(false);
            }
          }}>
            <DialogTitle>Update All Profit Percent</DialogTitle>
            <DialogContent>
            <TextField
                defaultValue={0}
                inputRef={refEditProfitPercent}
                autoFocus
                margin="dense"
                id="percent"
                label="Percent(%)"
                type="number"
                fullWidth
                variant="standard"
              />
              
            </DialogContent>
            <DialogActions>
              <Button onClick={!resultProfitPercent.isLoading?  closePPDialog : ()=>{}}>Cancel</Button>
              <Button onClick={()=>editProfitPercentHandler()}>{resultProfitPercent.isLoading ? <Spinner /> : "Change"}</Button>
             
            </DialogActions>
          </Dialog>
          {/*End of change all profit percent */}

     

      {/* Product List Table */}
      <div className="w-full h-full">
        { (
          <DataGrid
            rows={lstProduct}
            columns={columns}
            rowHeight={80}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 10,
                },
              },
              aggregation: {
                model: {
                  stock: 'sum',
                },
              },
            }}
            density="compact"
            pageSizeOptions={[5, 10, 25, 50, 100]}
            disableRowSelectionOnClick
            slots={{ toolbar: GridToolbar,loadingOverlay: LinearProgress, }}
            loading={loading}
            slotProps={{
              toolbar: {
                showQuickFilter: true,
                quickFilterProps: { debounceMs: 500 },
              },
            }}
          />
        ) }
      </div>
    </div>
  );
};

export default Product;
