import React, { useState, useRef, useEffect } from "react";
import Swal from "sweetalert2";

import {
  Button,
  Input,
  Card,
  Typography,
  Drawer,
  IconButton,
  Spinner,
  Tooltip,
} from "@material-tailwind/react";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { IoAddCircleOutline } from "react-icons/io5";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { BiTrash } from "react-icons/bi";
import { FaEdit, FaClone } from "react-icons/fa";
import withReactContent from "sweetalert2-react-content";
import SkeletonTable from "../../components/SkeletonTable";
import { useNavigate } from "react-router-dom";
import { useGetProductTransferQuery } from "../../api/productSlice";

const ProductTransfer = () => {
  // Api Calling
  const { data, isLoading, isSuccess, refetch } = useGetProductTransferQuery();

  const [lstTransfer, setLstTransfer] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    if (isSuccess) {
      const rows = data.data.map((element, index) => {
        return {
          ...element,
          no: index + 1,
          productName: element.product.name,
          contributer: element.contributer.name,
          taker: element.taker.name,
        };
      });
      setLstTransfer(rows);
    }
  }, [data?.data]);

  //Table columns
  const columns = [
    {
      field: "no",
      headerName: "No.",
      width: 100,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "productName",
      headerName: "Product",
      flex: 1,
      editable: false,
      headerAlign: "left",
      align: "left",
    },
    {
      field: "qty",
      headerName: "Qty",
      width: 200,
      editable: false,
      headerAlign: "right",
      align: "right",
      type: "number",
    },
    {
      field: "contributer",
      headerName: "Contributer",
      width: 200,
      editable: false,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "taker",
      headerName: "Taker",
      width: 200,
      editable: false,
      headerAlign: "center",
      align: "center",
    },
  ];

  return (
    <div className="flex flex-col gap-4 px-4">
      {/* Caption */}
      <div className="flex flex-row w-full justify-between">
        <p className="px-4 py-2 bg-[#57626c] rounded-lg text-white font-bold">
          Product Transfer
        </p>
        {/* <Button 
                  variant="outlined" onClick={()=> setAddNew(!addNew)}>
                  {addNew ? "Close": "Add New"}
            </Button> */}
      </div>

      {/* Product Transfer List Table */}
      <div className="w-full">
        <Card className="overflow-scroll h-full w-full">
          {isLoading ? (
            <SkeletonTable />
          ) : isSuccess ? (
            <DataGrid
              rows={lstTransfer}
              columns={columns}
              rowHeight={80}
              density="compact"
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 10,
                  },
                },
              }}
              pageSizeOptions={[5]}
              disableRowSelectionOnClick
              slots={{ toolbar: GridToolbar }}
              slotProps={{
                toolbar: {
                  showQuickFilter: true,
                  quickFilterProps: { debounceMs: 500 },
                },
              }}
            />
          ) : (
            <></>
          )}
        </Card>
      </div>
    </div>
  );
};

export default ProductTransfer;
