import { ApiSlice } from "./ApiSlice";

export const shopSlice = ApiSlice.injectEndpoints({
    endpoints: (builder) =>({
        getShop: builder.query({
            query: ()=> '/shop',
            providesTags: ['shop'],
        }),

        // getShopById: builder.query({
        //     query: (id) => `/shop/${id}`,
        //     providesTags: ['shop'],
        // }),

        getStockByShop: builder.mutation({
            query: (id) => `/shop-store/product/${id}`,
        }),

        addShop: builder.mutation({
            query: (body) => ({
                url: '/shop',
                method: 'POST',
                body,
            }),
            invalidatesTags: ['shop'],
        }),

        editShop: builder.mutation({
            query: (body) =>({
                url: `/shop/${body.id}`,
                method: 'POST',
                body
            }),
            invalidatesTags: ['shop'],
        }),

        deleteShop: builder.mutation({
            query: (id) =>({
                url: `/shop/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['shop'],
        }),
        
        

    })
})

export const {
    useGetShopQuery,
    useGetShopByIdQuery,
    useAddShopMutation,
    useEditShopMutation,
    useDeleteShopMutation,
    useGetStockByShopMutation,
} = shopSlice