import React, { useEffect, useState, useRef } from "react";
import {
  useGetProductQuery,
  useAddProductTransferMutation,
} from "../../api/productSlice";
import { useGetShopQuery } from "../../api/shopSlice";
import Select from "react-select";
import {
  Button,
  Input,
  Option,
  Spinner,
  IconButton,
  Typography,
  Tooltip,
} from "@material-tailwind/react";
import AlertComponent from "../../components/Warning";

const ProductTransfer = () => {
  const { data, isSuccess, isLoading } = useGetProductQuery();
  const {
    data: shop,
    isLoading: shopLoading,
    isSuccess: shopSuccess,
  } = useGetShopQuery();

  const [createProductTransfer, result] = useAddProductTransferMutation();

  const [lstProduct, setLstProduct] = useState([]);
  const [productId, setProductId] = useState(0);
  const [pay_shop, setPay_shop] = useState([]);
  const [take_shop, setTake_shop] = useState([]);
  const [pay_shop_id, setPay_shop_id] = useState(0);
  const [take_shop_id, setTake_shop_id] = useState(0);
  const [warn, setWarn] = useState(false);
  const [res, setRes] = useState({});

  const qtyRef = useRef();

  const handleWarnClick = () => {
    setWarn(true);
  };

  const handleWarnClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setWarn(false);
  };

  const transferProduct = async () => {
    const body = {
      product_id: productId,
      contribute_shop_id: pay_shop_id,
      taken_shop_id: take_shop_id,
      qty: qtyRef.current.value,
    };
    await createProductTransfer(body);
  };

  useEffect(() => {
    if (result?.isSuccess) {
      setRes(result);
      handleWarnClick();
    } else if (result?.isError) {
      console.log(result);
      setRes(result);
      handleWarnClick();
    }
  }, [result]);

  useEffect(() => {
    if (isSuccess) {
      data.data.map((shop, i) => {
        return setLstProduct((current) => [
          ...current,
          { value: shop, label: shop.name },
        ]);
      });
    }
  }, []);

  useEffect(() => {
    if (shopSuccess) {
      shop.data.map((shop, i) => {
       return setPay_shop((current) => [
          ...current,
          { value: shop, label: shop.name },
        ]);
      });
      shop.data.map((shop, i) => {
        return setTake_shop((current) => [
           ...current,
           { value: shop, label: shop.name },
         ]);
       });
    }
  }, [shopSuccess]);

  return (
    <div className="flex flex-col gap-4 px-4">
      {/* Caption */}
      <div className="flex flex-row w-full justify-between">
        <p className="px-4 py-2 bg-[#57626c] rounded-lg text-white font-bold">
          Product Transfer
        </p>
        {/* <Button 
                  variant="outlined" onClick={()=> setAddNew(!addNew)}>
                  {addNew ? "Close": "Add New"}
            </Button> */}
      </div>
      <div className="w-full border-2 border-blue-gray-600 rounded-lg">
        <div className="w-full p-2 rounded-lg border-2 border-gray-400">
          <Select
            classNamePrefix="select"
            className="mb-4"
            name="Product"
            options={lstProduct}
            onChange={(e) => {
              console.log(e.value.id);
              setProductId(e.value.id);
            }}
          />
          <Select
            classNamePrefix="select"
            className="mb-4"
            name="Contribute Shop"
            options={pay_shop}
            onChange={(e) => {
              console.log(e.value.id);
              setPay_shop_id(e.value.id);
            }}
          />
          <Select
            classNamePrefix="select"
            className="mb-4"
            name="Take Shop"
            options={take_shop}
            onChange={(e) => {
              console.log(e.value.id);
              setTake_shop_id(e.value.id);
            }}
          />

          <div className="mb-4">
            <Input
              variant="outlined"
              size="lg"
              label="Alert Qty"
              inputRef={qtyRef}
              required
              type="number"
            />
          </div>
          <div className="mb-4">
            {result.isLoading ? (
              <Button>
                <Spinner />
              </Button>
            ) : (
              <Button onClick={transferProduct}>Product Transfer</Button>
            )}
          </div>
        </div>
        <AlertComponent
          result={res}
          open={warn}
          handleClose={handleWarnClose}
        />
      </div>
    </div>
  );
};

export default ProductTransfer;
