import { ApiSlice } from "./ApiSlice";

export const distributeCheckoutSlice = ApiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getDistributeCheckout: builder.query({
            query: () => '/shop-store',
        }),


        addDistributeCheckout: builder.mutation({
            query: (body) => ({
                url: '/shop-store',
                method: 'POST',
                body,
            })
        }),

        editDistributeCheckout: builder.mutation({
            query: (body) =>({
                url: `/shop-store/${body.get("id")}`,
                method: 'POST',
                body
            }),
        
        }),

    })
});

export const {
    useGetDistributeCheckoutQuery,
    useAddDistributeCheckoutMutation,
    useEditDistributeCheckoutMutation,
} = distributeCheckoutSlice