import React, {useState, useEffect, useCallback} from "react";
import { useNavigate,useLocation } from 'react-router-dom'
import {
  Navbar,
  MobileNav,
  Typography,
  Button,
  Menu,
  MenuHandler,
  MenuList,
  MenuItem,
  Avatar,
  Card,
  IconButton,
} from "@material-tailwind/react";
import {
  CubeTransparentIcon,
  UserCircleIcon,
  CodeBracketSquareIcon,
  Square3Stack3DIcon,
  ChevronDownIcon,
  Cog6ToothIcon,
  InboxArrowDownIcon,
  LifebuoyIcon,
  PowerIcon,
  RocketLaunchIcon,
  Bars2Icon,
} from "@heroicons/react/24/outline";
import Drawer from '@mui/material/Drawer';
import MaterialSideBar from './MaterialSideBar';
import {AiOutlineMenuUnfold} from "react-icons/ai"
import { useLogOutMutation } from "../api/AuthApiSlice";
 
// profile menu component
const profileMenuItems = [
  {
    label: "My Profile",
    icon: UserCircleIcon,
    link: "/account"
  },
  {
    label: "Sign Out",
    icon: PowerIcon,
    link: "/logout"
  },
  // {
  //   label: "Edit Profile",
  //   icon: Cog6ToothIcon,
  // },
  // {
  //   label: "Inbox",
  //   icon: InboxArrowDownIcon,
  // },
  // {
  //   label: "Help",
  //   icon: LifebuoyIcon,
  // },
  
];


 
function ProfileMenu() {
  const navigate = useNavigate();
  const [isMenuOpen, setIsMenuOpen] = React.useState(false);
  const auth = localStorage.getItem('auth');
  if(!auth){
    navigate("/login");
  }

  const [ logout, result] = useLogOutMutation();

  const closeMenu = async(url) =>{ 
    
    setIsMenuOpen(false)
    if(url == "/logout"){

      // const body = {
      //    id: 7,
      // }
      
      // await logout(body);
      localStorage.clear();
      navigate("/login");
    }
    else{ 
      navigate('/account')
    };
};
  
 
  return (
    <Menu open={isMenuOpen} handler={setIsMenuOpen} placement="bottom-end">
      <MenuHandler>
        <Button
          variant="text"
          color="blue-gray"
          className="flex items-center gap-1 rounded-full py-0.5 pr-2 pl-0.5 lg:ml-auto"
        >
          <Avatar
            variant="circular"
            size="sm"
            alt="candice wu"
            className="border border-blue-500 p-0.5"
            src="https://images.unsplash.com/photo-1633332755192-727a05c4013d?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1480&q=80"
          />
          <ChevronDownIcon
            strokeWidth={2.5}
            className={`h-3 w-3 transition-transform ${
              isMenuOpen ? "rotate-180" : ""
            }`}
          />
        </Button>
      </MenuHandler>
      <MenuList className="p-1">
        {profileMenuItems.map(({ label, icon, link }, key) => {
          const isLastItem = key === profileMenuItems.length - 1;
          return (
            <MenuItem
              key={label}
              onClick={()=> closeMenu(link)}
              className={`flex items-center gap-2 rounded ${
                isLastItem
                  ? "hover:bg-red-500/10 focus:bg-red-500/10 active:bg-red-500/10"
                  : ""
              }`}
            >
              {React.createElement(icon, {
                className: `h-4 w-4 ${isLastItem ? "text-red-500" : ""}`,
                strokeWidth: 2,
              })}
              <Typography
                as="span"
                variant="small"
                className="font-normal"
                color={isLastItem ? "red" : "inherit"}
              >
                {label}
              </Typography>
            </MenuItem>
          );
        })}
      </MenuList>
    </Menu>
  );
}
 
 


 
const MaterialNavBar = () => {
  const [isNavOpen, setIsNavOpen] = React.useState(false);
  const toggleIsNavOpen = () => setIsNavOpen((cur) => !cur);
  const [openDrawer, setOpenDrawer] = useState();
  const toggleOpenDrawer = () => setOpenDrawer((cur) => !cur);
  // const prefetchProduct = usePrefetch('getProduct')

  const location = useLocation();
 
  useEffect(() => {
    window.addEventListener(
      "resize",
      () => window.innerWidth >= 960 && setIsNavOpen(false)
    );
    
  }, []);

  useEffect(()=>{
    setOpenDrawer(false);
    //console.log( prefetchProduct)
  },[location])

 
  return (
    <Navbar className="flex flex-row justify-between w-screen px-6 h-16 scrollbar-hide" fullWidth>
      <Drawer
        anchor="left"
        open={openDrawer}
        onClose={toggleOpenDrawer}
        PaperProps={{
          sx: {
            backgroundColor: "#1b2c3e"
          }
        }}
      >
        <MaterialSideBar/>
      </Drawer>

      <div className="flex-1">
        <IconButton onClick={toggleOpenDrawer} className="bg-[#1b2c3e]"> <AiOutlineMenuUnfold className="h-6 w-6" /></IconButton>
      </div>
      <div className="flex-1 items-center text-blue-gray-900">
        
        <IconButton
          size="sm"
          color="blue-gray"
          variant="text"
          onClick={toggleIsNavOpen}
          className="ml-auto mr-2 lg:hidden"
        >
          <Bars2Icon className="h-6 w-6" />
        </IconButton>
        <ProfileMenu />
      </div>
    </Navbar>
  );
}


export default MaterialNavBar