import { ApiSlice } from "./ApiSlice";

export const userSlice = ApiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getUser: builder.query({
            query: () => '/account/user-list',
            providesTags: ['user'],
        }),

        getUserById: builder.query({
            query: (id) => `/user/${id}`,
            providesTags: ['user'],
        }),

        // login: builder.mutation({
        //     query: (body) =>({
        //         url: '/login',
        //         method: 'POST',
        //         body
        //     }),
        // }),

        // logout: builder.mutation({
        //     query: (id) =>({
        //         url: `/logout/${id}`,
        //         method: 'POST'
        //     }),
        // }),

        addUser: builder.mutation({
            query: (body) => ({
                url: '/signup',
                method: 'POST',
                body,
            }),
            invalidatesTags: ['user'],
        }),

        editUser: builder.mutation({
            query: (body) =>({
                url: `/user/${body.id}`,
                method: 'POST',
                body
            }),
            invalidatesTags: ['user'],
        }),

        updatePermission: builder.mutation({
            query: (body) => ({
                url: '/admin/update-permissions',
                method:'POST',
                body,
            }),
            invalidatesTags: ['user'],
        }),
        adminChangePassword: builder.mutation({
            query: (body) => ({
                url: '/admin/account/password',
                method: 'POST',
                body,
            }),
        }),

        deleteUser: builder.mutation({
            query: (id) =>({
                url: `/admin/account/delete/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['user'],
        }),

        addDevice: builder.mutation({
            query: (body) => ({
                url: '/device-permit',
                method: 'POST',
                body,
            }),
            invalidatesTags: ['user'],
        }),
        getDevice: builder.query({
            query: () => '/device-permit',
            providesTags: ['device'],
        }),

        deleteDevice:builder.mutation({
            query: (id) =>({
                url: `/device-permit/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['device'],
        }),

    })
});

export const {
    useGetUserQuery,
    useGetUserByIdQuery,
    useAddUserMutation,
    useEditUserMutation,
    useDeleteUserMutation,
    useUpdatePermissionMutation,
    useAdminChangePasswordMutation,
    useGetDeviceQuery,
    useAddDeviceMutation,
    useDeleteDeviceMutation,
} = userSlice