import React, { useState, useRef, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { addLstProduct} from '../../api/productListSlice';
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { useAddProductTransferMutation, useGetStockEachShopMutation } from "../../api/productSlice";
import { useGetCategoryQuery } from "../../api/categorySlice";
import { useGetUnitQuery } from "../../api/unitSlice";
import { useEditPurchaseQtyMutation } from "../../api/purchaseCheckoutSlice";
import { useGetShopQuery } from "../../api/shopSlice";
import { BiTrash, BiDetail } from "react-icons/bi";
import { FaEdit } from "react-icons/fa";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import {
  Drawer,
  Button,
  Avatar,
  Tooltip,
  Input,
  Option,
  Spinner,
  IconButton,
  Typography,
} from "@material-tailwind/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import Select from 'react-select';
import {
  LinearProgress,
  Dialog,
  AppBar,
  Toolbar,
  Slide,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
} from '@mui/material';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

const Stock = () => {
  
    const [trigger , {data, isLoading, isSuccess, refetch}] = useGetStockEachShopMutation();
    const { data:dataShop, isLoading:isLoadingShop, isSuccess:isSuccessShop, refetch:refetchShop } = useGetShopQuery();
    const [ addProductTransfer, reslutPTransfer] = useAddProductTransferMutation();
    
    const rtkLstProduct = useSelector((state) => state.productList.lstProduct);
    const dispatch = useDispatch();
    const [lstProduct, setLstProduct] = useState([]);
    const [lstStock, setLstStock] = useState([]);
    const [lstShop, setLstShop] = useState([]);
    const [triggerId, setTriggerId] = useState();
    const [ contriShop, setContriShop] = useState();
    const [ takenShop, setTakenShop] = useState();
    const refTransferQty = useRef();
  
  
    //Product Details Section
    const [selectItem, setSelectItem] = useState(null);
    const [openDialog, setOpenDialog] = useState(false);
    const handleCloseDialog = () => setOpenDialog(false);
    
    
  
    useEffect(()=>{
        setLstProduct([]);
        rtkLstProduct.map((product,i)=> {
        setLstProduct(current => [...current, {value: product, label: product.name + ", " + product.barcode+ ", " + product.unit.name}])
        console.log(lstProduct)

        });
    },[rtkLstProduct]);

    useEffect(()=>{
      if(isSuccess){
        setLstStock([]);
        const temp = [{shop_id:0,name: "Warehouse", address: "Yangon", phone: "09-771026957", stock: data?.data.purchase_qty - data?.data.distribute_qty}, ...data?.data.shops  ];

        const rows = temp.map((obj, index)=> index == 0 ? ({...obj, id: obj.shop_id, no 
        : index+1}):({...obj,id: obj.shop_id, name: obj.shop.name, address: obj.shop.address, phone: obj.shop.phone, no: index+1}));
        
        setLstStock([...rows]);
      }
    },[data?.data])

    useEffect(()=>{
      setLstShop([]);
      dataShop?.data.map((shop,i)=> {
      setLstShop(current => [...current, {value: shop, label: shop.name}])

      });

    
    },[dataShop?.data]);

    const handleProductTransfer = async () =>{
      let body = {
        'product_id': triggerId,
        'contribute_shop_id': contriShop,
        'taken_shop_id':takenShop,
        'qty':refTransferQty.current.value
      };
  
  
      await addProductTransfer(body);
      trigger(triggerId)
      refTransferQty.current.value = 1;
      handleCloseDialog();
    }



    

    // //Create list of product data to show on table
    // useEffect(() => {
    //     const rows = rtkLstProduct.map((p, index) =>
    //       true
    //         ? {
    //             ...p,
    //             no: index + 1,
    //             categoryName: p.category.name,
    //             unitName: p.unit.name,
    //             prodId: p.id,
    //           }
    //         : { ...p }
    //     );
    //     setLstProduct([...rows]);
    //     setLoading(false);
      
      
    // }, [rtkLstProduct]);
  
    
  
    //Table columns
    const columns = [
          { field: "no", headerName: "No.", width: 100,
          headerAlign: 'center',
          align: 'center', },
          {
            field: "name",
            headerName: "Shop",
            flex: 1,
            editable: false,
            headerAlign: 'center',
            align: 'center',
          },
          {
            field: "address",
            headerName: "Address",
            flex: true,
            editable: false,
            headerAlign: 'center',
            align: 'center',
          },
          {
            field: "phone",
            headerName: "Phone",
            flex: true,
            editable: false,
            headerAlign: 'center',
            align: 'center',
          },
          {
            field: "stock",
            headerName: "Stock",
            flex: true,
            editable: false,
            headerAlign: 'center',
            align: 'center',
          },
          {
            field: "prodId",
            headerName: "Product Transfer",
            flex: true,
            editable: false,
            headerAlign: 'center',
            align: 'center',
            renderCell: (params) => (
                params.row.id == 0 ? null : <div className="flex flex-row gap-2">
                <Tooltip content="Transfer" className="flex-1 bg-green-500">
                  <IconButton className="w-6 h-6 bg-green-200 p-4" onClick={()=> {
                    setContriShop(params.row.id)
                    setOpenDialog(true);

                    }}>
                    <BiDetail className="h-4 w-4 text-green-600" />
                  </IconButton>
                </Tooltip>
              </div>
            ),
          },
        ];
  

  
    return (
      <div className="flex flex-col gap-4">
        
        <Dialog open={openDialog} onClose={ (e,r) =>{
          console.log("Click other")
        if(r == "backdropClick" && reslutPTransfer.isSuccess){
          handleCloseDialog();
        }
      }}>
        <DialogTitle>Product Transfer</DialogTitle>
        <DialogContent>
          <div className="w-96 flex flex-col justify-center">
            <div className="my-5">
              <Select
                classNamePrefix="select"
                name="Shop"
                options={lstShop}
                onChange={(e)=> {
                    // console.log(e.value)
                    setTakenShop(e.value.id)
                }}
                />
            </div>
            <div className="mb-10">
              <TextField
                defaultValue={1}
                inputRef={refTransferQty}
                autoFocus
                margin="dense"
                id="name"
                label="Qty"
                type="number"
                fullWidth
                variant="standard"
              />
            </div>
          
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={!reslutPTransfer.isLoading?  handleCloseDialog : ()=>{}}>Cancel</Button>
          <Button onClick={()=>{ handleProductTransfer()}}>{reslutPTransfer.isLoading ? <Spinner /> : "Transfer"}</Button>
        </DialogActions>
      </Dialog>
        {/* Caption */}
        <div className="flex flex-row w-full justify-center">
          <div className="w-1/3">
                <Select
                classNamePrefix="select"
                name="Product"
                options={lstProduct}
                onChange={(e)=> {
                  console.log(e.value.id)
                  trigger(e.value.id)
                  setTriggerId(e.value.id)

                }}
                />
            </div>
        </div>
  
        {/* Product List Table */}
        <div className="w-full h-full">
          { (
            <DataGrid
              rows={lstStock}
              columns={columns}
              rowHeight={80}
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 10,
                  },
                },
              }}
            //   filterModel={{
            //     items: [{ field: 'stock', operator: '>', value: 1000 }],
            //   }}
              density="compact"
              pageSizeOptions={[5, 10, 25, 50, 100]}
              disableRowSelectionOnClick
              slots={{ toolbar: GridToolbar,loadingOverlay: LinearProgress, }}
              slotProps={{
                toolbar: {
                  showQuickFilter: true,
                  quickFilterProps: { debounceMs: 500 },
                },
              }}
            />
          ) }
        </div>
      </div>
    );
}

export default Stock