import React from 'react'
import { useLocation } from 'react-router-dom'
import { useState, useEffect, useRef,forwardRef } from 'react';
import {  Button, Drawer,Input, IconButton,  Spinner, Typography, Tooltip } from '@material-tailwind/react';
import Select from 'react-select';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { BiTrash } from "react-icons/bi";
import { IoAddCircleOutline } from "react-icons/io5";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { useSelector, useDispatch} from 'react-redux';
import { addProduct2Voucher, removeProductFromVoucher } from '../../api/purchaseDetailsSlice';
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
//Date picker
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useAddPurchaseCheckoutMutation } from '../../api/purchaseCheckoutSlice';
import { useEditPurchaseVoucherMutation } from '../../api/purchaseSlice';
import { useGetPurchaseVoucherQuery } from '../../api/purchaseSlice';
import { useGetProductQuery } from '../../api/productSlice';
import { addLstProduct, addStock } from '../../api/productListSlice';
import { removeVoucher } from '../../api/purchaseDetailsSlice';
import { useReactToPrint } from "react-to-print";
import PrintPurchase from "./PrintPurchase";


const PurchaseDetails = () => {
    // Api Calling
    const rtkLstProduct = useSelector((state) => state.productList.lstProduct);
    const rtkLstCurrency = useSelector((state) => state.store.lstCurrency);

    const { data, isLoading, isSuccess, refetchPd} = useGetProductQuery();
    const [ addPurchaseItems, result] = useAddPurchaseCheckoutMutation();
    const [ editPurchaseVoucher, reslutPV] = useEditPurchaseVoucherMutation();
    const { refetch } = useGetPurchaseVoucherQuery();
    //get object parameter from previous page
    const {state} = useLocation();

    const lstVoucher = useSelector((state) => state.purchaseDetails.lstVoucher);
    const [cartProduct, setCartProduct] = useState([]);
    

    useEffect(()=>{
      if(isSuccess){
        const rows = data?.data.map((p, index) =>
        true
          ? {
              ...p,
              categoryName: p.category.name,
              unitName: p.unit.name,
              prodId: p.id,
              sell_price: p.last_purchase != null ? ((p.last_purchase.price + ( p.last_purchase.price * (p.remark/100)))* p.currency): 0,
            }
          : { ...p }
      );
        dispatch(addLstProduct(rows))
      }
    },[data?.data])


    useEffect(()=>{
        const lstCart = lstVoucher.find(obj => {return obj.voucherId === state.voucher.id});
        console.log(lstCart)
        if(lstCart !== undefined){
          setCartProduct(lstCart.lstProduct);
        let tAmount = 0;
        lstCart.lstProduct.map(obj => {
          tAmount += obj.amount
        })
        console.log(tAmount)
        setTotalAmount(tAmount)
        refCash.current.value = tAmount;
        setNet(tAmount);
        refSelect.current?.focus();
        }
        
        
    },[lstVoucher])

    const purchaseCheckoutHandler = async() =>{
      let body = {
        "purchase_voucher_id": state.voucher.id,
        "supplier_id": state.voucher.supplier_id,
        "discount": refDis.current.value,
        "net": net,
        "bill": bill,
        "status":1,
        "lstProduct": cartProduct
      };
      console.log(body);
      await addPurchaseItems(body);
      console.log(result);

      

      console.log(lstVoucher)
    
      refetch();

    }

    const componentRef = useRef();
    const handlePrint = useReactToPrint({
      content: () => componentRef.current,
    });

    useEffect(()=>{
      if(result.isSuccess){
        handlePrint();
        dispatch(removeVoucher(state.voucher.id));
        setNet(0);
        setBill(0);
        setCartProduct([]);
        refDis.current.value = 0;
        refCash.current.value = 0;
        setTotalAmount(0);

      }
    },[result])

//Create FormData to send data with file
let formData = new FormData()
const updateVoucher = async() =>{
  formData.append('supplier_id', supplierId)
  formData.append('buy_date',refDate.current.value)
  formData.append('file', selectedImage)

  await editPurchaseVoucher(formData)
  setOpen(false);
}

    

    // useEffect(()=>{
    //   console.log(cartProduct?.length)
    // },[cartProduct])
    const dispatch = useDispatch();

    const [open , setOpen] = useState(false);

    const [supplierId, setSupplierId] = useState(state.voucher.supplier_id);
    const [purchaseDate, setPurchaseDate] = useState(state.voucher.buy_date);
    const [preview, setPreview] = useState(null);
    const [selectedImage, setSelectedImage] = useState();
    const [selectedProduct, setSelectedProduct] = useState();
    
    //Get expired date from user
    const [expiredDate, setExpiredDate] = useState("2050-12-30");
    const [alertDate, setAlertDate] = useState("2050-12-30");
    const [net, setNet] = useState(0);
    const [bill, setBill] = useState(0);
    const [totalAmount, setTotalAmount] = useState(0);

    const refPBarcode = useRef(0);
    const refCurr = useRef("-");
    const refPCate = useRef(0);
    const refPUnit = useRef(0);
    const refPAQty = useRef(0);
    const refPSPrice1 = useRef(0);
    const refBPrice = useRef(0);
    const refQty = useRef(0);
    const refDis = useRef(0);
    const refCash = useRef(0);

    const refSelect = useRef();
    const refName = useRef(state.voucher.supplier.name);
    const refDate = useRef(state.voucher.buy_date);


    useEffect(()=>{
      refDis.current.value = 0
      refCash.current.value = 0
    },[])

   



    const funaddProductList = () =>{
        const pdt = {
            voucherId: state.voucher.id,
            product: {...selectedProduct,no: cartProduct.length +1  ,qty: refQty.current.value, price: refBPrice.current.value, amount: refQty.current.value * refBPrice.current.value, exp: expiredDate , alert:alertDate, note: "0" }
        }
        
        dispatch(addStock(pdt));
        dispatch(addProduct2Voucher(pdt));


    }


    
    const [lstProduct, setListProduct] = useState([]);
    useEffect(()=>{
        setListProduct([]);
        rtkLstProduct.map((product,i)=> {
        setListProduct(current => [...current, {value: product, label: product.name + ", " + product.barcode+ ", " + product.unit.name}])

      });
    },[rtkLstProduct]);

    
    // const componentDidMount = () =>{
    //   this.nameInput.focus();
    // }


  //Table columns
  const [columnVisibilityModel, setColumnVisibilityModel] = React.useState({
    exp: false,
    alert: false,
  });
  const columns = [
      { field: "no", headerName: "No", width: 50 },
      {
        field: "name",
        headerName: "Product Name",
        flex: 1,
      },
      {
        field: "price",
        headerName: "Buy Price",
        type:'number',
        width: 80,
        editable: true,
      },
      {
        field: "qty",
        headerName: "Qty",
        type:'number',
        width:70,
        editable: true,
      },
      {
        field: "amount",
        headerName: "Amount",
        type:'number',
        width: 80,
      },
      {
        field: "exp",
        headerName: "Exp Date",
        width:100,
        editable: true,
        hide: true,
      },
      {
        field: "alert",
        headerName: "Alert Date",
        width:100,
        editable: true,
        hide: true,
      },
      {
        field: "id",
        headerName: "Action",
        width:70,
        editable: false,
        renderCell: (params) => (
          <div className="flex flex-row justify-center gap-2">
            <Tooltip content="Delete" className="flex-1 bg-red-500">
              <IconButton className="w-6 h-6 bg-red-200 p-4" onClick={()=>{
                
                const pdt = {
                  voucherId: state.voucher.id,
                  product: params.row
              }
                dispatch(removeProductFromVoucher(pdt))
                }}>
                <BiTrash className="h-4 w-4 text-red-600" />
              </IconButton>
            </Tooltip>
          </div>
        ),
      },
    ];

      //set Preview Image and set selected Image
  const PreviewUpload = (e) => {
    const file = e.target.files[0];
    setSelectedImage(file);
    setPreview(URL.createObjectURL(file));
  };

    function handleEnter(event) {
      if (event.keyCode === 13) {
        const form = event.target.form;
        const index = Array.prototype.indexOf.call(form, event.target);
        form.elements[index + 1].focus();
        event.preventDefault();
      }
    }
    
  return (
    <div className="flex flex-col gap-2 px-2 pb-4">
      <React.Fragment>
        <Drawer placement="right" open={open} onClose={()=> setOpen(false)}>
          <div className="mb-2 flex items-center justify-between p-4">
            <Typography variant="h5" color="blue-gray">
              Edit Voucher
            </Typography>
            <IconButton variant="text" color="blue-gray" onClick={() => setOpen(false)}>
              <XMarkIcon strokeWidth={2} className="h-5 w-5" />
            </IconButton>
          </div>
          <form className="flex flex-col gap-6 items-center p-4">
            <label
                  for="dropzone-file"
                  class="flex flex-col items-center justify-center cursor-pointer"
                >
              {preview != null ? (
                      <img
                        src={`${open ? preview : ""}`}
                        alt={open ? state.voucher.img : ""}
                        className="border w-full h-64 border-blue-gray-50 bg-blue-gray-50/50 object-contain p-1"
                      />
                    ) : (
                      <img
                        src={`${localStorage.getItem('url')}/${open ? state.voucher.img : ""}`}
                        alt={open ? state.voucher.img : ""}
                        className="border w-full h-64 border-blue-gray-50 bg-blue-gray-50/50 object-contain p-1"
                      />
                    )}
                    <input
                        id="dropzone-file"
                        type="file"
                        class="hidden"
                        onChange={(e) => PreviewUpload(e)}
                      />
              </label>
              <Input 
                label="Supplier"
                value={state.voucher.supplier.name}
                readOnly/>
            <input
              type="date"
              label="Phone"
              defaultValue={purchaseDate}
              inputRef={refDate}
              className='border-2 w-full h-10 rounded-xl border-gray-300'
            />
            <Button onClick={()=> updateVoucher()}>
              {result.isLoading ? <Spinner /> : "Save"}
            </Button>
          </form>
        </Drawer>
      </React.Fragment>
      {/* Caption */}
      <div className="flex flex-row w-full justify-between">
        <p className="px-4 py-2 bg-[#57626c] rounded-lg text-white font-bold">
          Purchase Voucher
        </p>
        {/* <Button type="submit" size='sm' className='flex-none' color="green" onClick={handlePrint}>
                Print
        </Button> */}
      </div>

      {/* Add New Section */}
      <div className="w-full px-6 py-2 h-22 border-2 border-gray-200 xl:flex xl:flex-row gap-2 items-end grid grid-cols-1 justify-center rounded-xl">
        {/* <form className="flex flex-row items-end" action={supplierHandler}> */}
        <div className="flex-1 m-2">
            <Input 
                label="Supplier"
                value={state.voucher.supplier.name}
                readOnly/>
          </div>
          <div className="flex-1 m-2">
            <Input
              label="Date"
              value={state.voucher.buy_date}
              readOnly
            />
          </div>
          <div className="flex-none m-2">
            <Button
              type="submit"
              variant="outlined"
              className="flex items-center gap-3 px-4 py-2"
              onClick={()=> setOpen(true)}
            >
             <IoAddCircleOutline strokeWidth={2} className="h-5 w-5" />Edit
            </Button>
          </div>
        {/* </form> */}
      </div>

      {/* Supplier List Table */}
      <div className="w-full grid grid-cols-1 lg:grid-cols-2 gap-2">
        <div className='flex flex-col border-2 border-gray-200 rounded-xl p-4 items-center justify-start'>
            <div className="flex-initial w-32 xl:w-96">
                <Select
                classNamePrefix="select"
                name="basicUnit"
                options={lstProduct}
                ref={refSelect}
                onChange={(e)=> {
                    console.log(e.value)
                    setSelectedProduct(e.value);
                    refPBarcode.current.value = e.value.barcode;
                    refCurr.current.value = rtkLstCurrency[e.value.note-1].currency;
                    refPCate.current.value = e.value.category.name;
                    refPUnit.current.value = e.value.unit.name;
                    refPAQty.current.value = e.value.alert_qty;
                    refPSPrice1.current.value = e.value.sell_price1;
                    refBPrice.current.value = e.value.last_purchase != null? e.value.last_purchase.price:0;
                    refQty.current.value = 1;
                    
                    const nextEle = document.querySelector("#price");
                    nextEle.focus();
                    //refBPrice.current?.focus();

                }}
                />

            </div>

            <div className="flex-initial flex-col w-full gap-2 py-4">
              {/* <div className="flex lg:flex-row flex-col gap-4 justify-center">
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={currency}
                  onChange={(event)=>{
                    setCurrency(event.target.value);
                  }}
                >
                  <FormControlLabel value="1" control={<Radio />} label="USSD" labelPlacement="end"/>
                  <FormControlLabel value="2" control={<Radio />} label="Yuan" labelPlacement="end"/>
                  <FormControlLabel value="3" control={<Radio />} label="Baht" labelPlacement="end"/>
                  <FormControlLabel value="4" control={<Radio />} label="Kyats" labelPlacement="end"/>
                </RadioGroup>
              </div> */}
                <div className="flex lg:flex-row flex-col gap-4 mb-4 justify-between items-end">
                    <Input variant="standard" label="Buying Price" id="price" type="number" onKeyDown={(event)=>{
                      handleEnter(event);
                      if(event.key === 'Enter'){
                        // refQty.current?.focus();
                        // const nextEle1 = document.querySelector("#qty");
                        // nextEle1.focus();
                      }
                    }} inputRef={refBPrice}/>
                    <Input variant="standard" label="qty" id="qty" type="number" onKeyDown={(event)=>handleEnter(event)} inputRef={refQty}/>
                    
                </div>
                <div className='flex lg:flex-row flex-col gap4 mb-4 justify-between'>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker format="YYYY-MM-DD" onChange={(date) => setExpiredDate(date.format('YYYY-MM-DD'))} label="Expired Date" className="pr-2" slotProps={{ textField: { size: 'small' } }}/>
                    </LocalizationProvider>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker format="YYYY-MM-DD" onChange={(date) => setAlertDate(date.format('YYYY-MM-DD'))} label="Alert Date" className="pr-2" slotProps={{ textField: { size: 'small' } }}/>
                    </LocalizationProvider>
                    <Button variant="outlined" onClick={funaddProductList}>Add</Button>
                </div>
            </div>

            <div className="flex-initial flex-col w-full gap-2">
                <div className='w-32 text-bold border border-dashed border-gray-500 rounded-lg p-2 mb-2'>
                    Product Details
                </div>
                <div className="flex lg:flex-row flex-col gap-2 mb-2 justify-between">
                    <Input variant="standard" label="Barcode" ref={refPBarcode} defaultValue={refPBarcode.current.value} readOnly/>
                    <Input variant="standard" label="Currency" className='text-red-500 text-xl' ref={refCurr} defaultValue={refCurr.current.value} readOnly/>
                </div>
                <div className="flex lg:flex-row flex-col gap-2 mb-2 justify-between">
                    <Input variant="standard" label="Category" ref={refPCate} defaultValue={refPCate.current.value} readOnly/>
                    <Input variant="standard" label="Unit" ref={refPUnit} defaultValue={refPUnit.current.value} readOnly/>
                    
                </div>
                <div className="flex lg:flex-row flex-col gap-2 mb-2 justify-between">
                <Input variant="standard" label="Alert Quantity" type="number" ref={refPAQty} defaultValue={refPAQty.current.value} readOnly/>
                    <Input variant="standard" label="Selling Price" type="number" ref={refPSPrice1} defaultValue={refPSPrice1.current.value} readOnly/>
                </div>
            </div>
            
            
        </div>
        <div className=''>
          { cartProduct?.length > 0 ?  (
              <DataGrid
                rows={cartProduct}
                columns={columns}
                rowHeight={80}
                editMode="row"
                density='Compact'
                initialState={{
                  pagination: {
                    paginationModel: {
                      pageSize: 5,
                    },
                  },
                }}
                columnVisibilityModel={columnVisibilityModel}
                  onColumnVisibilityModelChange={(newModel) =>
                    setColumnVisibilityModel(newModel)
                  }
                pageSizeOptions={[5,10,25,50,100]}
                disableRowSelectionOnClick
                slots={{ 
                  toolbar: GridToolbar,
                }}
                slotProps={{
                  toolbar: {
                    showQuickFilter: true,
                    quickFilterProps: { debounceMs: 500 },
                  },
                }}
              />
            ) : (
              <div className='flex flex-row w-full h-full justify-center items-center border-2 border-gray rounded-lg'>No items in current voucher</div>
            )
          }
        </div>
        
      </div>

      <div className='flex flex-row w-full px-6 py-2 mb-10 h-22 gap-2 shadow-md rounded-lg'>
        <Input label="Discount" type='number' inputRef={refDis} defaultValue={refDis.current.value} onChange={(e)=>{
          setNet(totalAmount - (e.target.value* 0.01 * totalAmount));
        refCash.current.value = totalAmount - (e.target.value* 0.01 * totalAmount);
        }} className='flex-1'/>
        <Input label="Net" value={net} defaultValue={net} className='flex-1'/>
        <Input label="Cash" inputRef={refCash} onChange={(e)=>{
          setBill(net - e.target.value);

        }} className='flex-1'/>
        <Input label="Total amount" value={totalAmount} defaultValue={0} className='flex-1'/>
        <Button type="submit"
                variant="outlined"
                size='sm'
                className='flex-none'
                onClick={purchaseCheckoutHandler}
              >
                {result.isLoading ? <Spinner name="Loading"/> :"Checkout"
                }
        </Button>

      </div>
      <div style={{ display: "none" }}> 
        <PrintPurchase ref={componentRef} voucher={{ voc: state.voucher, cashier:"Admin", total: totalAmount, cash: refCash.current.value , net: net, bill: bill  }} items={cartProduct}/>
      </div>
    </div>
  )
}



export default PurchaseDetails

