import React, { useEffect, useRef, useState } from "react";
import {
  Input,
  Select,
  Option,
  Button,
  IconButton,
  Card,
  Tooltip
} from "@material-tailwind/react";
import { useGetShopQuery } from "../../api/shopSlice";
import { useSelector, useDispatch } from "react-redux";
import  SkeletonTable from "../../components/SkeletonTable";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { BiTrash } from "react-icons/bi";
import withReactContent from "sweetalert2-react-content";
import { addLstShop } from "../../api/storeSlice";
import Swal from "sweetalert2";
import { useGetDeviceQuery,useAddDeviceMutation,useDeleteDeviceMutation } from "../../api/userSlice";
import Alert from '@mui/material/Alert';
import Collapse from '@mui/material/Collapse';
import { AiOutlineCloseCircle } from "react-icons/ai";


const DevicePermit = () => {

    const { data, isLoading, isSuccess, refetch } = useGetShopQuery();
    const rtkLstShop = useSelector((state) => state.store.lstShop);
    const dispatch = useDispatch();

    const { data:device, isLoading:deviceloading, isSuccess:deviceSuccess, refetch:deviceRefetch} = useGetDeviceQuery();
    const [ addDevice , resultDevice] = useAddDeviceMutation();
    const [ deleteDevice , resultDeviceDel ] = useDeleteDeviceMutation();


  const [shopId, setShopId] = useState(0);
  const deviceNameRef = useRef();
  const deviceIdRef = useRef();

  const [alertInfo, setAlertInfo] = useState("");
  
  const [openAlert, setOpenAlert] = useState(false);

  const [lstDevice,setLstDevice] = useState([]);

  const columns = isSuccess
  ? [
      { field: "id",
        headerName: "No", 
        align: 'center',
        headerAlign: 'center'
      },
      {
        field: "shopName",
        headerName: "Shop",
        flex: 1,
        editable: false,
        align: 'center',
        headerAlign: 'center'
      },
      {
        field: "deviceId",
        headerName: "Device ID",
        flex: 1,
        editable: false,
        align: 'center',
        headerAlign: 'center'
      },
      {
        field: "deviceName",
        headerName: "Device Name",
        sortable: true,
        editable: false,
        flex: 1,
        align: 'center',
        headerAlign: 'center'
      },
      {
        field: "action",
        headerName: "Action",
        flex: 1,
        editable: false,
        align: 'center',
        headerAlign: 'center',
        renderCell: (params) => (
          <div className="flex flex-row gap-2 justify-center items-center">
            <Tooltip content="Delete" className="flex-1 bg-red-500">
              <IconButton onClick={() => confirmDelete(params.row.id)} variant="text" className="w-6 h-6 bg-red-200 p-4">
                <BiTrash className="h-4 w-4 text-red-600" />
              </IconButton>
            </Tooltip>
          </div>
        ),
      },
    ]
  : [];


  //Add Device
  const deviceHandler =async () => {
    if(shopId == 0 || deviceIdRef.current.value == "" || deviceNameRef.current.value == ""){
        setAlertInfo("Please enter device data")
        setOpenAlert(true);
        return;
    }
    const body = {
        "shop_id": shopId,
        "deviceId" : deviceIdRef.current.value,
        "deviceName" : deviceNameRef.current.value,
        "saas" : "futuretechmm"
    }

    await addDevice(body);
    deviceRefetch();
    deviceIdRef.current.value = "";
    deviceNameRef.current.value = "";
    setShopId(0);
  };

      //Delete Device
      const sweetAlert = withReactContent(Swal);

      const deleteDeviceHandler = async (id) => {
        await deleteDevice(id);
        refetch();
      };
    

      const confirmDelete = (id) => {
        Swal.fire({
          title: "Are you sure?",
          text: "You won't be able to revert this!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, delete it!",
        }).then((result) => {
          if (result.isConfirmed) {
            deleteDeviceHandler(id);
            Swal.fire("Deleted!", "User account has been deleted.", "success");
          }
        });
      };

      
  useEffect(()=>{
    if(!isLoading){
      // const rows = [{"id":0,"name":"Warehouse/Yangon"},...data?.data];
      dispatch(addLstShop(data?.data));
    }
  },[data?.data])

  useEffect(()=>{
    if(deviceSuccess){
        setLstDevice(device.data.map((d,i)=> ({...d,no:i+1,shopName: d.shop.name}) ))
    }else{

    }

  },[device,deviceSuccess]);

  return (
    <div className="flex flex-col gap-4">
      {/* Caption */}
      <div className="flex flex-row w-full justify-between">
        <p className="px-4 py-2 bg-[#57626c] rounded-lg text-white font-bold">
          Device Permission
        </p>
      </div>
      <Collapse in={openAlert}>
        <Alert 
          severity="warning" 
          action={
            <div className='flex-1 items-center justify-centerw-12'  onClick={() => {
              setOpenAlert(false);
            }}>
                <AiOutlineCloseCircle size={25}/>
              </div>
          }>{alertInfo}</Alert>
      </Collapse>
      <div className="flex flex-col w-full">
        <div className="flex flex-row border-gray-500 border-2 shadow-xl p-4 rounded-lg gap-4">
          <div className="w-full">
            <Select
              variant="outlined"
              label="Select Shop"
              size="lg"
              onChange={(e) => {
                setShopId(e);
              }}
            >
              {rtkLstShop.map((shop, i) => (
                <Option value={shop.id}>{shop.name}</Option>
              ))}
            </Select>
          </div>
          <div className="w-full">
            <Input
              variant="outlined"
              size="lg"
              label="Device ID"
              inputRef={deviceIdRef}
              required
            />
          </div>
          <div className="w-full">
            <Input
              variant="outlined"
              size="lg"
              label="Device Name"
              inputRef={deviceNameRef}
              required
            />
          </div>
          <div className="flex flex-row w-full justify-end items-center">
            <Button
              type="submit"
              variant="outlined"
              className="flex items-center gap-3"
              onClick={deviceHandler}
            >
              Add Device
              {/* {result.isLoading ? <Spinner/> :"Add Product"} */}
            </Button>
          </div>
        </div>
        <div className="flex flex-row mt-4">
        <div className="w-full">
          <Card className="overflow-scroll h-full w-full">
            {isLoading ? (
              <SkeletonTable/>
            ) : (
              isSuccess ? (
                <DataGrid
                  rows={lstDevice}
                  columns={columns}
                  rowHeight={80}
                  density="compact"
                  initialState={{
                    pagination: {
                      paginationModel: {
                        pageSize: 10,
                      },
                    },
                  }}
                  pageSizeOptions={[5,10,25,50,100]}
                  disableRowSelectionOnClick
                  slots={{ toolbar: GridToolbar }}
                  slotProps={{
                    toolbar: {
                      showQuickFilter: true,
                      quickFilterProps: { debounceMs: 500 },
                    },
                  }}
                />
              ) : (
                <></>
              )
            )}
          </Card>
        </div>
        </div>
      </div>
    </div>
  );
};

export default DevicePermit;
