import React from 'react'
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
const Home = () => {
    const navigate = useNavigate();
  
    const authKey = localStorage.getItem("auth");
    const token = localStorage.getItem("token");
    
  useEffect(()=>{
    if(authKey && token != null){
      navigate('/splash');
    }else{
      navigate('/login')
    }
  },[])
  return (
    <div>Home Page</div>
  )
}

export default Home
