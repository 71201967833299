import React, { useState, useRef, useEffect } from 'react'
import {
    Button,
    Card,
    Typography,
    Spinner,
    Select,
    Option,
  } from "@material-tailwind/react";

import { IoAddCircleOutline } from "react-icons/io5";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Link, useNavigate } from 'react-router-dom';


import { useGetProductQuery } from '../../api/productSlice';
import { useGetSupplierQuery } from '../../api/supplierSlice';
import { useGetPurchaseVoucherQuery, useAddPurchaseVoucherMutation } from '../../api/purchaseSlice';
import { useSelector, useDispatch} from 'react-redux';
import { addVoucher } from '../../api/purchaseDetailsSlice';
import { Bars } from 'react-loader-spinner';


const Purchase = () => {
  //Api Calling
  const { data:dataSuppli, isLoading:isLoadingSuppli, isSuccess:isSuccessSuppli, refetch:refetchSuppli } = useGetSupplierQuery();
  const { data:dataPVoucher, isLoading:isLoadingPVoucher, isSuccess:isSuccessPVoucher } = useGetPurchaseVoucherQuery();
  
  const [addPurchaseVoucher, result] = useAddPurchaseVoucherMutation();
  const [supplierId, setSupplierId] = useState();
  const [purchaseDate, setPurchaseDate] = useState();
  const [preview, setPreview] = useState(null);
  const [selectedImage, setSelectedImage] = useState();
  const [createVc, setCreateVc] = useState(false);

  const dispatch = useDispatch();


  const navigate = useNavigate();

  const funEditVoucher = (vId) =>{
    const voucher = {
        voucherId: vId,
        lstProduct: []
    }
    dispatch(addVoucher(voucher));


}


  //Create FormData to send data with file
  // let formData = new FormData()
  
  let formData = new FormData();

  const handleVoucher = async() =>{

    formData.append("supplier_id", supplierId)
    formData.append("buy_date",purchaseDate)
    formData.append("file", selectedImage)

    // console.log(Object.fromEntries(formData))
    await addPurchaseVoucher(formData)
    setCreateVc(false)
  }

  const [lstPVoucher, setLstPVoucher] = useState([]);
  const [lstSupplier, setLstSupplier] = useState([]);
    //Create list of product data to show on table
    useEffect(() => {
        if (!isLoadingSuppli) {
            console.log(dataSuppli.data)
            setLstSupplier(dataSuppli?.data);
        }
    },[dataSuppli?.data]);

    //filter the list of voucher
    useEffect(()=>{
      let filterVoc = dataPVoucher?.data.filter(obj => { return obj.status == 0; });
      setLstPVoucher(filterVoc);
    },[dataPVoucher?.data])

    //set Preview Image and set selected Image
  const PreviewUpload = (e)=>{
    const file = e.target.files[0]
    setSelectedImage(file);
    setPreview(URL.createObjectURL(file));
  }



  return (
    <div className="flex flex-col gap-4 px-16">
      {/* Caption */}
      <div className="flex flex-row w-full justify-start">
        <p className="px-4 py-2 bg-[#57626c] rounded-lg text-white font-bold">
          Purchase
        </p>
      </div>

      {/* Add New Section */}
      <div className="flex flex-col w-full px-6 py-2 h-22 border-2 border-gray-200 gap-2 items-center justify-center rounded-xl">
        <div className='flex w-full'>
          <Button
                type="submit"
                variant="outlined"
                className="flex items-center gap-3 px-4 py-2 m-1 w-48"
                onClick={()=> setCreateVc(true)}
                >
                {(
                    <IoAddCircleOutline strokeWidth={2} className="h-5 w-5" />
                )}
                Create Voucher
                </Button>
        </div>
      </div>

      {/* Purchase Voucher List Table */}
      <div className='flex flex-row items-center justify-center w-full h-full'>
      { isLoadingPVoucher ? 
          <>
                  <Bars
                height="50"
                width="50"
                radius="9"
                color="green"
                ariaLabel="loading"
                wrapperStyle
                wrapperClass
                
              />
          </> 
                  :
      <div className="grid grid-cols-2 lg:grid-cols-3 xl:grid-cols-7 w-full gap-2">
           {lstPVoucher?.map((pVouc,i) => 
          <Card className="border-2 border-green-500" onClick={()=> funEditVoucher(pVouc.id)}>
            <Link to={`/purchase-details/${pVouc.id}`} state={{voucher: pVouc}} key={pVouc.link} className='flex flex-col items-center text-center'>
            <div className='flex-row justify-end w-full'>
              <Typography variant="h6" color="dark-green" className="flex-1 text-end p-1">
                {pVouc.buy_date}
              </Typography>
            </div>
            <img src={`https://warehouse.futuretechmm.net/pos-vendor-backend/public/${pVouc.img}`} alt="img-blur-shadow" className='object-scale-down w-2/3 h-2/3'/>
            
            
              <Typography variant="h6" color="blue-gray" className="my-2">
                Voucher Id - {pVouc.id}
              </Typography>
              </Link>
        </Card>
        )}
        </div>

        }
        </div>
        

      
      {/* Create Dialog Section */}
      <Dialog
        open={createVc}
        keepMounted
        onClose={()=> setCreateVc(false)}
        fullWidth={true}
        maxWidth={'md'}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle className='text-blue'>Voucher Info</DialogTitle>
        <DialogContent>
          <div className='flex-1 flex-col justify-center w-full p-6'>
              <div className="flex-1 m-2">
                      <Select label="Supplier" size="md" onChange={(e) => setSupplierId(e)}>
                      {
                          isSuccessSuppli? lstSupplier.map((suppli, i) => (
                          <Option value={suppli.id} key={suppli.id}>{suppli.name}</Option>
                          )): <Option>No Supplier to Show</Option>
                          
                          }
                      </Select>
                  </div>
              <div className="flex-1 m-2 w-full">
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DatePicker format="YYYY-MM-DD" selected={purchaseDate} onChange={(date) => setPurchaseDate(date.format('YYYY-MM-DD'))} label="Purchase Date" className="pr-2" slotProps={{ textField: { size: 'small' } }}/>
                      </LocalizationProvider>
                  </div>
                  <label
                      for="dropzone-file"
                      class="flex flex-col items-center justify-center w-full h-96 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600"
                    >
                      {preview != null ? <img src={preview} alt="Preview Image" className="w-full h-full object-scale-down p-4"/> : <div class="flex flex-col items-center justify-center pt-5 pb-6">
                        <svg
                          aria-hidden="true"
                          class="w-10 h-10 mb-3 text-gray-400"
                          fill="none"
                          stroke="currentColor"
                          viewBox="0 0 24 24"
                          xmlns="https://www.w3.org/2000/svg"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
                          ></path>
                        </svg>
                        <p class="mb-2 text-sm text-gray-500 dark:text-gray-400">
                          <span class="font-semibold">Click to upload</span> or
                          drag and drop
                        </p>
                        <p class="text-xs text-gray-500 dark:text-gray-400">
                          SVG, PNG, JPG or GIF (MAX. 800x800px)
                        </p>
                      </div>}
                      <input id="dropzone-file" type="file" class="hidden" onChange={(e)=> PreviewUpload(e)}/>
                    </label>
              <div className="flex-1 flex-row justify-around m-2">
                  
              </div>
          </div>
        </DialogContent>
        <DialogActions>
        <Button
                  type="submit"
                  variant="outlined"
                  className="flex items-center gap-3 px-4 py-2 m-1"
                  onClick={()=> setCreateVc(false)}
                  >
                  Cancle
                  </Button>
                  <Button
                  type="submit"
                  variant="outlined"
                  className="flex items-center gap-3 px-4 py-2 m-1"
                  onClick={()=> handleVoucher()}
                  >
                  {result.isLoading ? <Spinner/> :"Save"}
                  </Button>
        </DialogActions>
      </Dialog>



    </div>
    
    
    
  )
}

export default Purchase