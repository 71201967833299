import React, { useState, useRef, useEffect } from "react";
import { Button, Input, Card } from "@material-tailwind/react";
import { useLocation } from "react-router-dom";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import SkeletonTable from "../../components/SkeletonTable";
import { useGetPurchaseVoucherByIdQuery } from "../../api/purchaseSlice";
import { useReactToPrint } from "react-to-print";
import PrintPurchase from "./PrintPurchase";

const PurchaseHistory = () => {
  //get object parameter from previous page
  const { state } = useLocation();

  const { data, isLoading, isSuccess, refetch } =
    useGetPurchaseVoucherByIdQuery(state.id);

  const [voucher, setVoucher] = useState(null);
  const [lstProduct, setLstProduct] = useState([]);

  const componentRef = useRef();
  const componentRefA4 = useRef();
  const componentRefA5 = useRef();

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const handlePrintA5 = useReactToPrint({
    content: () => componentRefA5.current,
  })

  const handlePrintA4 = useReactToPrint({
    content: () => componentRefA4.current,
    // pageStyle: () => getPageMargins,
  });


  useEffect(() => {
    if (isSuccess) {
      setVoucher();
      setLstProduct([]);
      setVoucher(data?.data);
      console.log(data?.data);
      
      let pdt = data?.data.purchase.map((pdt, index) =>
        isSuccess
          ? {
              ...pdt,
              no: index + 1,
              pname: pdt?.name?.name,
              barcode: pdt?.name?.barcode,
            }
          : { ...pdt }
      );
      setLstProduct(pdt);
      console.log("List of pdt");
      console.log(lstProduct);
    }
  }, [data?.data]);

  //Table columns
  const columns = isSuccess
    ? [
        { field: "no", headerName: "No.", width: 100 },
        {
          field: "pname",
          headerName: "Product Name",
          flex: 1,
          editable: false,
        },
        {
          field: "barcode",
          headerName: "Barcode",
          width: 150,
          editable: false,
        },
        {
          field: "price",
          headerName: "Price",
          type: "number",
          sortable: true,
          editable: true,
          width: 150,
        },
        {
          field: "qty",
          headerName: "Qty",
          editable: false,
          width: 100,
        },
        {
          field: "amount",
          headerName: "Total",
          type: "number",
          width: 150,
          editable: false,
        },
        {
          field: "expire_date",
          headerName: "Expired Date",
          width: 150,
          editable: false,
        },
      ]
    : [];

  return (
    <div className="flex flex-col gap-4 px-16">
      {/* Caption */}
      <div className="flex flex-row w-full justify-between items-center">
        <p className="px-4 py-2 bg-[#57626c] rounded-lg text-white font-bold">
          Voucher Id - {data?.data.id} / Date - {voucher?.buy_date}
        </p>
        
        <div>
        <Button type="submit"
                size='sm'
                className='flex-none px-4 py-2 bg-green-500 rounded-lg text-white font-bold'
                onClick={handlePrint}
              >
                Printer
        </Button>
          {/* <p className="px-4 py-2 bg-green-800 rounded-lg text-white font-bold">
            Print
          </p> */}
        </div>
      </div>

      {/* Add New Section */}
      <div className="p-2 h-22 border-2 border-gray-200 xl:flex xl:flex-row gap-1 items-end grid grid-cols-1 justify-center rounded-xl">
        <div className="flex-1">
          <Input
            size="sm"
            label="Supplier"
            value={voucher?.supplier.name}
            readOnly
          />
        </div>
        <div className="flex-1">
          <Input
            size="sm"
            label="Discount(%)"
            value={voucher?.discount}
            readOnly
          />
        </div>
        <div className="flex-1">
          <Input
            size="sm"
            label="Cash"
            value={voucher?.net - voucher?.bill}
            readOnly
          />
        </div>
        <div className="flex-1">
          <Input size="sm" label="Credit" value={voucher?.bill} readOnly />
        </div>

        <div className="flex-1">
          <Input size="sm" label="Total" value={voucher?.net} readOnly />
        </div>
      </div>

      {/* Shop List Table */}
      <div className="w-full">
        <Card className="overflow-scroll h-full w-full">
          {isLoading ? (
            <SkeletonTable />
          ) : isSuccess ? (
            <DataGrid
              rows={lstProduct}
              columns={columns}
              rowHeight={80}
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 10,
                  },
                },
              }}
              density="compact"
              pageSizeOptions={[5, 10, 25, 50, 100]}
              disableRowSelectionOnClick
              slots={{ toolbar: GridToolbar }}
              slotProps={{
                toolbar: {
                  showQuickFilter: true,
                  quickFilterProps: { debounceMs: 200 },
                },
              }}
            />
          ) : (
            <></>
          )}
        </Card>
      </div>
      {/* Printing Section */}
      <div style={{ display: "none" }}> 
       {/* {voucher != null ? <PrintPurchase ref={componentRef} voucher={{ voc: voucher, cashier:"Admin", total: voucher.net , cash: voucher.net-voucher.bill , net: voucher.net, bill: voucher.bill }} items={lstProduct}/>: <></>} */}
        {/* <PrintPurchase ref={componentRef} voucher={{ voc: state.voucher, cashier:"Admin", total: totalAmount, cash: refCash.current.value , net: net, bill: bill  }} items={cartProduct}/> */}
        {/* { voucher != null ? <PrintDistribute ref={componentRef} voucher={{ voc: voucher, cashier:"Admin",  }} items={lstProduct}/>: <></>} */}
      </div>
    </div>
  );
};


// const PrintComponent = forwardRef((props, ref) => {
//   const { voucher, items, summary } = props;
//   console.log(props);
//   console.log("Testing");
//   // const [i, setI] = useState({});
//   var today = new Date();
//   const date =
//     today.getFullYear() +
//     "-" +
//     (today.getMonth() + 1) +
//     "-" +
//     today.getDate() +
//     "-" +
//     today.getHours() +
//     ":" +
//     today.getMinutes() +
//     ":" +
//     today.getSeconds();

//   const shop = JSON.parse(localStorage.getItem("shopdetails"));

//   function currencyFormat(num) {
//     return num.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
//   }

//   const phoneList = shop.phone.split(",");

//   return (
//     <>
//       <div
//         id="divToPrint"
//         className="flex flex-col w-[303px] h-fit text-center"
//         ref={ref}
//       >
//         <div className="flex flex-row w-full h-fit pt-2 justify-center items-center">
//           {shop.id == 1 ? (
//             <img src={Logo} alt="slip" width={150} height={50} />
//           ) : (
//             <>
//               <img src={Logo} alt="slip" width={150} height={50} />
//             </>
//           )}
//         </div>

//         <div className="flex flex-col w-full h-fit py-2 justify-center items-center">
//           {/* <p className="font-bold font-poppins text-base">ပေါ်ပြူလာ</p> */}
//           <p className="font-bold font-poppins text-xs px-8">
//             စပါးရိတ်သိမ်းခြွေလှေ့စက် ၊ ထွန်စက် နှင့် အပိုပစ္စည်း အရောင်းဆိုင်
//           </p>
//           {/* <p className="font-bold font-poppins text-xs">
//             ဆိုင်အမှတ် ပ-၂၄၅၊ နေပြည်တော်မြို့မဈေးမြောက်ဘက်တန်း၊
//           </p> */}

//           <div className="grid grid-cols-3 gap-1">
//             {phoneList.map((phone) => (
//               <p className="font-poppins text-xs text-balance w-full text-center justify-center items-center mx-auto" key={uuidv4().toString()}>
//                 {phone}
//               </p>
//             ))}
//           </div>
//           <p className="font-bold font-poppins text-sm">{shop.address}</p>
//         </div>
//         <div className="flex flex-row w-full justify-start items-start">
//           <div className="flex-1 flex-col h-fit px-2 justify-start items-start">
//             <p className="font-poppins text-xs text-start">
//               <span className="text-xs pr-2">အမည်၊</span>
//               {voucher.cashier}
//             </p>
//             <p className="font-poppins text-xs text-start">
//               <span className="text-xs pr-2">နေရပ်၊</span>
//               {voucher.adds}
//             </p>
//           </div>

//           <div className="flex flex-col w-fit h-fit px-2 justify-end items-end">
//             <p className="font-poppins text-xs">
//               <span className="text-xs">ရက်စွဲ၊ </span>
//               {today.getDate() +
//                 "-" +
//                 (today.getMonth() + 1) +
//                 "-" +
//                 today.getFullYear()}
//             </p>
//           </div>
//         </div>

//         <div className="flex flex-row w-full h-fit px-2 pt-4">
//           <div className="flex-1 text-xs text-start">အမျိုးအမည်</div>
//           <div className="w-8 text-xs">ဦးရေ</div>
//           <div className="text-xs w-16">နှုန်း</div>
//           <div className="text-xs w-20">သင့်ငွေ</div>
//         </div>
//         <div>
//           --------------------------------------------------------------
//         </div>

//         {items.map((item, index) => (
//           <div className="flex flex-row w-full h-fit py-2 pr-2" key={uuidv4()}>
//             <div className="flex-1 text-xs">{item.product.name}</div>
//             <div className="w-8 text-xs">{item.qty}</div>
//             <div className="text-xs w-16">{item.sell_price}</div>
//             <div className="text-xs w-20">{item.qty * item.sell_price}</div>
//           </div>
//         ))}

//         <div>-------------------------------------------------------------</div>
//         <div className="flex flex-row w-full px-2 pr-2 justify-between font-poppins text-xs">
//           <span>ကျသင့်ငွေ </span>
//           {summary.total} ကျပ်
//         </div>
//         <div className="flex flex-row w-full px-2 pr-2 justify-between font-poppins text-xs">
//           <span>လျော့ပေးငွေ </span>
//           {summary.discount} ကျပ်
//         </div>
//         <div className="flex flex-row w-full px-2 pr-2 justify-between font-poppins text-xs">
//           <span>ပေးချေငွေ </span>
//           {Math.floor(summary.cash)} ကျပ်
//         </div>
//         <div className="flex flex-row w-full px-2 pr-2 justify-between font-poppins text-xs">
//           <span>ယခင် အကြွေးကျန် </span>
//           {summary.oldBill} ကျပ်
//         </div>
//         <div className="flex flex-row w-full px-2 pr-2 justify-between font-poppins text-xs">
//           <span>ယခု အကြွေးကျန် </span>
//           {summary.bill} ကျပ်
//         </div>

//         <div className="flex flex-row w-full px-2 pr-2 justify-between font-poppins text-xs">
//           <span>စုစုပေါင်း အကြွေးကျန် </span>- ကျပ်
//         </div>
//         <div className="w-full h-fit text-xs text-center mt-4">
//           ⚠️ ဝယ်ယူပြီး ပစ္စည်းပြန်မလဲပေးပါ။ ⚠️
//         </div>
//         <div className="w-full h-fit text-xs text-center mt-4">
//           🤝 ဝယ်ယူအားပေးမှုကို အထူးကျေးဇူးတင်ရှိပါသည်။ 🤝
//         </div>
//         <div className="flex flex-row w-full py-2 px-2 justify-between font-poppins text-xs mb-10">
//           <span>Print Time </span>
//           {date}
//         </div>
//         <div>
//           --------------------------
//           <span className="transform rotate-270 font-bold">✂</span>
//           ---------------------------------
//         </div>
//       </div>
//     </>
//   );
// });

// const PrintComponentA4 = forwardRef((props, ref) => {
//   const { voucher, items, summary } = props;
//   const user = JSON.parse(localStorage.getItem("user"));
//   console.log(props);
//   console.log("Testing");
//   // const [i, setI] = useState({});
//   var today = new Date();
//   const date =
//     today.getFullYear() +
//     "-" +
//     (today.getMonth() + 1) +
//     "-" +
//     today.getDate() +
//     "-" +
//     today.getHours() +
//     ":" +
//     today.getMinutes() +
//     ":" +
//     today.getSeconds();

//   const shop = JSON.parse(localStorage.getItem("shopdetails"));

//   function currencyFormat(num) {
//     return num.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1");
//   }

//   const phoneList = shop.phone.split(",");
//   const getPageMargins = () => {
//     return `@page { margin: 20px !important; }`;
//   };

//   return (
//     <>
//       <div
//         id="divToPrint"
//         className="flex flex-col h-fit text-center relative"
//         ref={ref}
//       >
//         <style>{getPageMargins()}</style>
//         <div className="flex flex-col w-full h-fit py-2 justify-center items-center sm:text-xs">
//           <div className="flex flex-row w-full justify-center items-center">
//             {/* <img src={ttMap} alt="logo" width={80} height={80} className="" /> */}
//             <div className="w-full">
//               <div className="flex flex-row w-full h-fit justify-center items-center">
//                 <img src={Logo} alt="slip" className="h-32 w-full" />
//               </div>
//               <div className="flex flex-row w-full h-fit justify-center items-center">
//                 <img src={subTitle} alt="slip" className=" h-16 w-full" />
//               </div>
//               {/* <p className="font-bold font-poppins my-2 text-3xl">
//                 စပါးရိတ်သိမ်းခြွေလှေ့စက်၊ထွန်စက်နှင့်အပိုပစ္စည်းအရောင်းဆိုင်
//               </p> */}
//               <div className="grid grid-cols-4 gap-1 mt-4">
//                 {phoneList.map((phone) => (
//                   <p key={uuidv4().toString()} className="font-bold font-poppins text-[1.3rem] text-balance w-full text-center justify-center items-center mx-auto">
//                     {phone}
//                   </p>
//                 ))}
//               </div>
//               <p className="font-bold font-poppins text-xl pt-1">
//                 {shop.address}
//               </p>
//             </div>
//           </div>
//         </div>

//         <div className="flex flex-row w-full h-fit justify-between items-start">
//           <div className="flex flex-col justify-between items-start w-1/3">
//             <p className="font-poppins text-sm flex flex-row w-full  justify-between items-center">
//               <span className="text-sm pr-2">Voucher ID</span>
//               <p>{voucher.id}</p>
//             </p>
//             <p className="font-poppins text-sm flex flex-row w-full justify-between items-center">
//               <span className="text-sm pr-2">Cashier  </span>
//               {user.name}
//             </p>
//             <p className="font-poppins text-sm flex flex-row w-full justify-between items-center">
//               <span className="text-sm">Date  </span>
//               <p>
//                 {today.getFullYear() +
//                   "-" +
//                   (today.getMonth() + 1) +
//                   "-" +
//                   today.getDate()}
//               </p>
//             </p>
//           </div>

//           <div className="flex flex-row justify-end grow-0">
//             <div className="flex flex-col">
//               <p className="font-poppins text-sm flex flex-row w-full justify-end items-center">
//                 <span className="text-sm pr-6 w-24 text-start">အမည်</span>
//               </p>
//               <p className="font-poppins text-sm flex flex-row w-full justify-end items-center">
//                 <span className="text-sm pr-6 w-24 text-start">လိပ်စာ</span>
//               </p>
//               <p className="font-poppins text-sm flex flex-row w-full justify-end items-center">
//                 <span className="text-sm pr-6 w-24 text-start">
//                   ဖုန်းနံပါတ်
//                 </span>
//               </p>
//             </div>
//             <div className="flex-1 flex-col justify-between">
//               <p className="font-poppins text-sm flex flex-row w-full justify-end items-center">
//                 {voucher.cashier}
//               </p>
//               <p className="font-poppins text-sm flex flex-row w-full justify-end items-center">
//                 {voucher.adds}
//               </p>
//               <p className="font-poppins text-sm flex flex-row w-full justify-end items-center">
//                 {voucher.phone}
//               </p>
//             </div>
//           </div>
//         </div>

//         <div className="w-full mt-4">
//         <table className="w-full text-sm text-left text-black border border-black border-collapse py-5">
//             <thead className="text-lg text-center text-white uppercase bg-blue-600 my-header">
//               <tr>
//                 <th
//                   scope="col"
//                   className="px-2 py-3 text-lg border border-black border-collapse"
//                 >
//                     No.
//                 </th>
//                 <th
//                   scope="col"
//                   className="px-6 py-3 text-lg border border-black border-collapse"
//                 >
//                   Product Name
//                 </th>
//                 <th
//                   scope="col"
//                   className="px-6 py-3 text-lg border border-black border-collapse"
//                 >
//                   Qty
//                 </th>
//                 <th
//                   scope="col"
//                   className="px-3 py-3 border border-black border-collapse text-lg"
//                 >
//                   Price
//                 </th>
                
//                 <th
//                   scope="col"
//                   className="px-6 py-3 border border-black border-collapse text-lg"
//                 >
//                   Amount
//                 </th>
//               </tr>
//             </thead>
//             <tbody>
//               {items.map((item, index) => (
//                 <tr className="border border-black border-collapse" key={uuidv4().toString()}>
//                   <td
//                     scope="row"
//                     className="px-2 py-1 border border-black border-collapse text-center"
//                   >
//                     {index + 1}
//                   </td>
//                   <td className="px-2 py-1 border border-black border-collapse">
//                     {item.product.name}
//                   </td>
//                   <td className="px-2 py-1 border border-black border-collapse text-center">
//                     {item.qty}
//                   </td>
//                   <td className="px-2 py-1 border border-black border-collapse text-right">
//                     {currencyFormat(parseInt(item.sell_price))}
//                   </td>
                  
//                   <td className="px-2 py-1 border border-black border-collapse text-right">
//                     {currencyFormat(parseInt(item.qty * item.sell_price))}
//                   </td>
//                 </tr>
//               ))}
//             </tbody>
//           </table>
//         </div>

//         <div className=" flex flex-row w-full justify-end text-base">
//           <div className=" flex flex-col w-1/2 justify-end items-end">
//             <div className="flex flex-row w-full px-2 pr-2 justify-between font-poppins text-base">
//               <span className="text-start w-[60%]">စုစုပေါင်းငွေ </span>{" "}
//               {summary.total}
//             </div>
//             <div className="flex flex-row w-full px-2 pr-2 justify-between font-poppins text-base ">
//               <span className="text-start w-[60%]">လျော့ပေးငွေ </span>
//               {summary.discount}
//             </div>
//             <div className="flex flex-row w-full px-2 pr-2 justify-between font-poppins text-base">
//               <span className="text-start w-[60%]">ပေးချေငွေ </span>
//               {summary.cash}
//             </div>
//             <div className="flex flex-row w-full px-2 pr-2 justify-between font-poppins text-base">
//               <span className="text-start w-[60%]"> ယခင်ကြွေးကျန် </span>{" "}
//               {summary.oldBill}
//             </div>
//             <div className="flex flex-row w-full px-2 pr-2 justify-between font-poppins text-base ">
//               <span className="text-start w-[60%]"> ယခုကြွေးကျန် </span>
//               {summary.bill}
//             </div>
//             <div className="flex flex-row w-full px-2 pr-2 justify-between font-poppins text-base ">
//               <span className="text-start w-[60%]">စုစုပေါင်းကြွေးကျန် </span>{" "}
//               {" - "}
//             </div>
//           </div>
//         </div>

//         <div className="w-full h-fit text-lg text-center mt-4">
//           🤝 ဝယ်ယူအားပေးမှုကို အထူးကျေးဇူးတင်ရှိပါသည်။ 🤝
//         </div>

//         <div className="w-full h-fit text-lg text-center mt-4">
//           ⚠️ ဝယ်ယူပြီး ပစ္စည်းပြန်မလဲပေးပါ။ ⚠️
//         </div>

//         <div className="flex flex-row w-full justify-between font-poppins text-base mb-10">
//           <span>Print Time </span>
//           {date}
//         </div>
//         <div>
//           --------------------------
//           <span className="transform rotate-270 font-bold">✂</span>
//           ---------------------------------
//         </div>
//       </div>
//     </>
//   );
// });

// const PrintComponentA5 = forwardRef((props, ref) => {
//   const { voucher, items, summary } = props;
//   const user = JSON.parse(localStorage.getItem("user"));
//   console.log(props);
//   console.log("Testing");
//   // const [i, setI] = useState({});
//   var today = new Date();
//   const date =
//     today.getFullYear() +
//     "-" +
//     (today.getMonth() + 1) +
//     "-" +
//     today.getDate() +
//     "-" +
//     today.getHours() +
//     ":" +
//     today.getMinutes() +
//     ":" +
//     today.getSeconds();

//   const shop = JSON.parse(localStorage.getItem("shopdetails"));

//   function currencyFormat(num) {
//     return num.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1");
//   }

//   const phoneList = shop.phone.split(",");
//   const getPageMargins = () => {
//     return `@page { margin: 20px !important; }`;
//   };

//   return (
//     <>
//       <div
//         id="divToPrint"
//         className="flex flex-col h-fit text-center relative"
//         ref={ref}
//       >
//         <style>{getPageMargins()}</style>
//         <div className="flex flex-col w-full h-fit py-2 justify-center items-center sm:text-xs">
//           <div className="flex flex-row w-full justify-center items-center">
//             {/* <img src={ttMap} alt="logo" width={80} height={80} className="" /> */}
//             <div className="w-full">
//               <div className="flex flex-row w-full h-fit justify-center items-center">
//                 <img src={Logo} alt="slip" className="h-24 w-full" />
//               </div>
//               {/* <p className="font-bold font-poppins text-[20px]">
//                 စပါးရိတ်သိမ်းခြွေလှေ့စက်၊ထွန်စက်နှင့်အပိုပစ္စည်းအရောင်းဆိုင်
//               </p> */}
//               <div className="flex flex-row w-full h-fit justify-center items-center">
//                 <img src={subTitle} alt="slip" className=" h-10 w-full" />
//               </div>
//               <div className="grid grid-cols-4 gap-1 mt-4">
//                 {phoneList.map((phone) => (
//                   <p key={uuidv4().toString()} className="font-bold font-poppins text-[1rem] text-balance w-full text-center justify-center items-center mx-auto">
//                     {phone}
//                   </p>
//                 ))}
//               </div>
//               <p className="font-bold font-poppins text-xl pt-1">
//                 {shop.address}
//               </p>
//             </div>
//           </div>
//         </div>

//         <div className="flex flex-row w-full h-fit justify-between items-start">
//           <div className="flex flex-col justify-between items-start w-1/3">
//             <p className="font-poppins text-sm flex flex-row w-full  justify-between items-center">
//               <span className="text-sm pr-2">Voucher ID</span>
//               <p>{voucher.id}</p>
//             </p>
//             <p className="font-poppins text-sm flex flex-row w-full justify-between items-center">
//               <span className="text-sm pr-2">Cashier - </span>
//               {user.name}
//             </p>
//             <p className="font-poppins text-sm flex flex-row w-full justify-between items-center">
//               <span className="text-sm">Date - </span>
//               <p>
//                 {today.getFullYear() +
//                   "-" +
//                   (today.getMonth() + 1) +
//                   "-" +
//                   today.getDate()}
//               </p>
//             </p>
//           </div>

//           <div className="flex flex-row justify-end grow-0">
//             <div className="flex flex-col">
//               <p className="font-poppins text-sm flex flex-row w-full justify-end items-center">
//                 <span className="text-sm pr-6 w-24 text-start">အမည်</span>
//               </p>
//               <p className="font-poppins text-sm flex flex-row w-full justify-end items-center">
//                 <span className="text-sm pr-6 w-24 text-start">လိပ်စာ</span>
//               </p>
//               <p className="font-poppins text-sm flex flex-row w-full justify-end items-center">
//                 <span className="text-sm pr-6 w-24 text-start">
//                   ဖုန်းနံပါတ်
//                 </span>
//               </p>
//             </div>
//             <div className="flex-1 flex-col justify-between">
//               <p className="font-poppins text-sm flex flex-row w-full justify-end items-center">
//                 {voucher.cashier}
//               </p>
//               <p className="font-poppins text-sm flex flex-row w-full justify-end items-center">
//                 {voucher.adds}
//               </p>
//               <p className="font-poppins text-sm flex flex-row w-full justify-end items-center">
//                 {voucher.phone}
//               </p>
//             </div>
//           </div>
//         </div>

//         <div className="w-full mt-4">
//         <table className="w-full text-sm text-left text-black border border-black border-collapse py-5">
//             <thead className="text-base text-center text-white uppercase bg-blue-600 my-header">
//               <tr>
//                 <th
//                   scope="col"
//                   className="px-4 py-3 border border-black border-collapse"
//                 >
//                     No.
//                 </th>
//                 <th
//                   scope="col"
//                   className="px-6 py-3 border border-black border-collapse text-base"
//                 >
//                   Product Name
//                 </th>
//                 <th
//                   scope="col"
//                   className="px-6 py-3 border border-black border-collapse"
//                 >
//                   Qty
//                 </th>
//                 <th
//                   scope="col"
//                   className="px-3 py-3 border border-black border-collapse"
//                 >
//                   Price
//                 </th>
                
//                 <th
//                   scope="col"
//                   className="px-6 py-3 border border-black border-collapse"
//                 >
//                   Amount
//                 </th>
//               </tr>
//             </thead>
//             <tbody>
//               {items.map((item, index) => (
//                 <tr className="border border-black border-collapse" key={uuidv4().toString()}>
//                   <td
//                     scope="row"
//                     className="px-4 py-1 border border-black border-collapse text-center"
//                   >
//                     {index + 1}
//                   </td>
//                   <td className="px-2 py-1 border border-black border-collapse">
//                     {item.product.name}
//                   </td>
//                   <td className="px-2 py-1 border border-black border-collapse text-center">
//                     {item.qty}
//                   </td>
//                   <td className="px-2 py-1 border border-black border-collapse text-right">
//                     {currencyFormat(parseInt(item.sell_price))}
//                   </td>
                  
//                   <td className="px-2 py-1 border border-black border-collapse text-right">
//                     {currencyFormat(parseInt(item.qty * item.sell_price))}
//                   </td>
//                 </tr>
//               ))}
//             </tbody>
//           </table>
//         </div>

//         <div className=" flex flex-row w-full justify-end text-base">
//           <div className=" flex flex-col w-1/2 justify-end items-end">
//             <div className="flex flex-row w-full px-2 pr-2 justify-between font-poppins text-base">
//               <span className="text-start w-[60%]">စုစုပေါင်းငွေ </span>{" "}
//               {summary.total}
//             </div>
//             <div className="flex flex-row w-full px-2 pr-2 justify-between font-poppins text-base ">
//               <span className="text-start w-[60%]">လျော့ပေးငွေ </span>
//               {summary.discount}
//             </div>
//             <div className="flex flex-row w-full px-2 pr-2 justify-between font-poppins text-base">
//               <span className="text-start w-[60%]">ပေးချေငွေ </span>
//               {summary.cash}
//             </div>
//             <div className="flex flex-row w-full px-2 pr-2 justify-between font-poppins text-base">
//               <span className="text-start w-[60%]"> ယခင်ကြွေးကျန် </span>{" "}
//               {summary.oldBill}
//             </div>
//             <div className="flex flex-row w-full px-2 pr-2 justify-between font-poppins text-base ">
//               <span className="text-start w-[60%]"> ယခုကြွေးကျန် </span>
//               {summary.bill}
//             </div>
//             <div className="flex flex-row w-full px-2 pr-2 justify-between font-poppins text-base ">
//               <span className="text-start w-[60%]">စုစုပေါင်းကြွေးကျန် </span>{" "}
//               {" - "}
//             </div>
//           </div>
//         </div>

//         <div className="w-full h-fit text-lg text-center mt-4">
//           🤝 ဝယ်ယူအားပေးမှုကို အထူးကျေးဇူးတင်ရှိပါသည်။ 🤝
//         </div>

//         <div className="w-full h-fit text-lg text-center mt-4">
//           ⚠️ ဝယ်ယူပြီး ပစ္စည်းပြန်မလဲပေးပါ။ ⚠️
//         </div>

//         <div className="flex flex-row w-full justify-between font-poppins text-base mb-10">
//           <span>Print Time </span>
//           {date}
//         </div>
//         <div>
//           --------------------------
//           <span className="transform rotate-270 font-bold">✂</span>
//           ---------------------------------
//         </div>
//       </div>
//     </>
//   );
// });

export default PurchaseHistory;
