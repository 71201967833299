import React, { useState, useEffect, useRef } from "react";
import Select from "react-select";
import { useAddLocationMutation, useEditLocationMutation, useGetProductQuery, useDeleteLocationMutation, useGetLocationQuery } from "../../api/productSlice";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { BiTrash, BiDetail } from "react-icons/bi";
import { FaEdit } from "react-icons/fa";
import Swal from "sweetalert2";
import {
  Drawer,
  Button,
  Input,
  Option,
  Spinner,
  IconButton,
  Typography,
  Tooltip
} from "@material-tailwind/react";
import { XMarkIcon } from "@heroicons/react/24/outline";

const ProductLocation = () => {

  const { data, isSuccess, isLoading } = useGetProductQuery();
  const [ addLocation, resultLocation] = useAddLocationMutation();
  const [ editLocation, resultEditLocation] = useEditLocationMutation();
  const [ deleteLocation, resultDeleteLocation] = useDeleteLocationMutation();
  const { data:location, isSuccess: locationSuccess,isLoading:locationLoading ,refetch} = useGetLocationQuery();

  const [loading,setLoading] = useState();
  const [lstProduct, setLstProduct] = useState([]);
  const [locationList, setLocationList] = useState([])
  const [productId, setProductId] = useState();
  const [open, setOpen] = useState(false);
  const locRef = useRef("");
  const qtyRef = useRef("");

  const editLocRef = useRef("");
  const editQtyRef = useRef("");

  const closeDrawer = ()=> setOpen(false);

  const openDrawer = (params)=> {
    editLocRef.current.value = params.row.location;
    editQtyRef.current.value = params.row.alert_qty;

    setOpen(true);
  };

  const columns = [
    { 
      field: "no",
      headerName: "No.", 
      width: 100,
      headerAlign: 'center',
      align: 'center', 
    },
    { 
      field: "name",
      headerName: "Product Name.", 
      flex:1,
      headerAlign: 'center',
      align: 'center', 
    },
    { 
      field: "alert_qty",
      headerName: "Alert Qty", 
      width: 100,
      headerAlign: 'center',
      align: 'center', 
    },
    { 
      field: "location",
      headerName: "Location", 
      flex:1,
      headerAlign: 'center',
      align: 'center', 
    },
    {
      field: "prodId",
      headerName: "Action",
      width:150,
      editable: false,
      renderCell: (params) => (
        <div className="flex flex-row gap-2">
          <Tooltip content="Edit" className="flex-1 bg-blue-500">
            <IconButton
              onClick={() => {
                openDrawer(params);
              }}
              className="w-6 h-6 bg-blue-200 p-4"
            >
              <FaEdit className="h-4 w-4 text-blue-600 font-extrabold" />
            </IconButton>
          </Tooltip>
          <Tooltip content="Delete" className="flex-1 bg-red-500">
            <IconButton className="w-6 h-6 bg-red-200 p-4" onClick={()=>confirmDelete(params.row.id)}>
              <BiTrash className="h-4 w-4 text-red-600" />
            </IconButton>
          </Tooltip>
        </div>
      ),
    },
  ];

  const confirmDelete = (id) =>{}

  const addLocationProduct = async()=>{
    if(locRef.current.value == "" || qtyRef.current.value == "" || productId == 0){
      return;
    }
    const body = {
      product_id:productId,
      location: locRef.current.value,
      alert_qty: qtyRef.current.value,
    };

    // add data
    await addLocation(body);
    await refetch();
    //clear remain text fields

    locRef.current.value = '';
    qtyRef.current.value = '';
    setProductId(0);

  }

  const editLocationProduct = async(id)=>{

    const body = {
      id:id,
      productId: productId,
      location: locRef.current.value,
      alert_qty: qtyRef.current.value,
      status: 0,
    }
    await editLocation(body);
    refetch();
  }

  const deleteLocationProduct = async(id)=>{
    await deleteLocation(id);
    refetch();
  }

  useEffect(()=>{
    if(resultLocation.isSuccess){
      setLocationList(location.data.map((e,i) => ({...e,no:i+1, name: e.proudct.name})));
    }
  },[resultLocation]);

  useEffect(()=>{

  },[resultEditLocation])

  useEffect(()=>{
    if(locationSuccess){
      setLocationList(location.data.map((e,i) => ({...e,no:i+1, name: e.product.name})));
    }
  },[locationSuccess])

  useEffect(() => {
    if (isSuccess) {
      data.data.map((shop, i) => {
        setLstProduct((current) => [
          ...current,
          { value: shop, label: shop.name },
        ]);
      });
    }
  }, []);


  return (
    <div className="flex flex-col gap-4">

      {/* Drawer for Edit */}

      <Drawer size={300} placement="right" open={open} onClose={closeDrawer}>
          
          <div className="mb-2 flex items-center justify-between p-4">
            <Typography variant="lead" color="blue-gray">
              Edit Product
            </Typography>
            <IconButton variant="text" color="blue-gray" onClick={closeDrawer}>
              <XMarkIcon strokeWidth={2} className="h-5 w-5" />
            </IconButton>
          </div>
          <div className="h-full overflow-y-auto pb-20">
          <form className="flex flex-col gap-6 p-4 items-center">
            <div className="w-full">
              <Select
              classNamePrefix="select"
              className="mb-4 w-full"
              name="Product"
              options={lstProduct}
              defaultValue={"product"}
              onChange={(e) => {
                console.log(e.value.id);
                setProductId(e.value.id);
              }}
            />
            </div>
          
            <Input
              type="text"
              label="ALert Qty"
              defaultValue={open ? editLocRef.current.value : ""}
              inputRef={editLocRef}
            />
            <Input
              type="text"
              label="Purchase Price"
              defaultValue={open ? editQtyRef.current.value : ""}
              inputRef={editQtyRef}
            />
            
            <Button onClick={editLocationProduct}>
              {resultEditLocation.isLoading ? <Spinner /> : "Save"}
            </Button>
          </form>
        </div>
      </Drawer>

      {/* Caption */}
      <div className="flex flex-row w-full justify-start">
        <p className="px-4 py-2 bg-[#57626c] rounded-lg text-white font-bold">
          Stock Core Value
        </p>
      </div>
      <div>
        {/* Caption */}
        <div className="flex flex-row w-full gap-2">
          <div className="w-1/2 p-2 rounded-lg border-2 border-gray-400">
            <Select
              classNamePrefix="select"
              className="mb-4"
              name="Product"
              options={lstProduct}
              onChange={(e) => {
                console.log(e.value.id);
                setProductId(e.value.id);
              }}
            />
            <div className="mb-4">
              <Input
                variant="outlined"
                size="lg"
                label="Location"
                inputRef={locRef}
                required
              />
            </div>
            <div className="mb-4">
              <Input
                variant="outlined"
                size="lg"
                label="Alert Qty"
                inputRef={qtyRef}
                required
                type="number"
              />
            </div>
            <div className="mb-4">
              <Button onClick={addLocationProduct}>Add Location</Button>
            </div>
          </div>
          <div className="flex flex-row w-full p-2 rounded-lg border-2 border-gray-400">
          {
            <DataGrid
               rows={locationList}
               columns={columns}
               initialState={{
                 pagination: {
                   paginationModel: {
                     pageSize: 10,
                   },
                 },
               }}
               density="compact"
               pageSizeOptions={[5, 10, 25, 50, 100]}
               disableRowSelectionOnClick
               slots={{ toolbar: GridToolbar }}
               loading={locationLoading}
               slotProps={{
                 toolbar: {
                   showQuickFilter: true,
                   quickFilterProps: { debounceMs: 500 },
                 },
               }}
             />
            }
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductLocation;
