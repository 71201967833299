import { ApiSlice } from "./ApiSlice";

export const AuthApiSlice = ApiSlice.injectEndpoints({
    endpoints: (builder) => ({
    
        login: builder.mutation({
            query: (body) => ({
                url: '/login',
                method: 'POST',
                body,
                headers:{
                    "Access-Control-Allow-Origin" : '*',
                    'Accept': `Application/json`
                }
            }),
           
        }),

        changePassword:builder.mutation({
            query: (body) => ({
                url: '/staff/change-password',
                method: 'POST',
                body,
                headers: {
                    Authorization: `Bearer ${body.token}`,
                    "Access-Control-Allow-Origin" : '*',
                    'Accept': `Application/json`
                },
            }),
        }),

        logOut: builder.mutation({
            query: (body) =>({
                url: `/staff/logout/${body.id}`,
                method: 'POST',
                body,
                headers: {
                    "Access-Control-Allow-Origin" : '*',
                    'Accept': `Application/json`
                },
            }),
        }),

    })
});

export const {
    useLoginMutation,
    useLogOutMutation,
    useChangePasswordMutation,
} = AuthApiSlice