import { createSlice, current } from "@reduxjs/toolkit";

const initialState = {
    lstCategory: [],
    lstCurrency: [],
    lstShop: [],
    lstSupplier: [],
    lstUnit: [],
    lstUser: [],
}

export const storeSlice = createSlice({
    name: 'store',
    initialState,
    reducers: {
        addLstCategory: (state, action) =>{
            console.log(action.payload);
            state.lstCategory = action.payload.map(obj => obj);
        },
        addLstCurrency: (state, action) =>{
            console.log(action.payload);
            state.lstCurrency = action.payload.map(obj => obj);
        },
        addLstShop: (state, action) =>{
            console.log(action.payload);
            state.lstShop = action.payload.map(obj => obj);
        },
        addLstSupplier: (state, action) =>{
            console.log(action.payload);
            state.lstSupplier = action.payload.map(obj => obj);
        },
        addLstUnit: (state, action) =>{
            console.log(action.payload);
            state.lstUnit = action.payload.map(obj => obj);
        },
        addLstUser: (state, action) =>{
            console.log(action.payload);
            state.lstUser = action.payload.map(obj => obj);
        },
    },
})

export const { 
    addLstCategory,
    addLstCurrency,
    addLstShop,
    addLstSupplier,
    addLstUnit,
    addLstUser,
} = storeSlice.actions;

export default storeSlice.reducer;