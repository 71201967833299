import { ApiSlice } from "./ApiSlice";

export const UnitJoinSlice = ApiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getUnitJoin: builder.query({
            query: () => '/unit-joins',
            providesTags: ['unitJoin'],
        }),

        getUnitJoinById: builder.query({
            query: (id) => `/unit-joins/${id}`,
            providesTags: ['unitJoin'],
        }),

        addUnitJoin: builder.mutation({
            query: (body) =>({
                url: '/unit-joins',
                method: 'POST',
                body
            }),
            invalidatesTags: ['unitJoin'],
        }),

        editUnitJoin: builder.mutation({
            query: (body) => ({
                url: `/unit-joins/${body.id}`,
                method: 'POST',
                body
            }),
            invalidatesTags: ['unitJoin'],
        }),

        deleteUnitJoin: builder.mutation({
            query: (id) => ({
                url: `/unit-joins/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['unitJoin'],
        }),


    })
}

)

export const {
    useGetUnitJoinQuery,
    useGetUnitJoinByIdQuery,
    useAddUnitJoinMutation,
    useEditUnitJoinMutation,
    useDeleteUnitJoinMutation,
} = UnitJoinSlice

