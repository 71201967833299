import React, {useEffect, useRef} from "react";
import { Input, Select, Option, Button, Spinner } from "@material-tailwind/react";
import { useState } from "react";
// import { BsPlusSquare } from "react-icons/bs";
import Alert from '@mui/material/Alert';
import Collapse from '@mui/material/Collapse';
import { AiOutlineCloseCircle } from "react-icons/ai";
// import Radio from '@mui/material/Radio';
// import RadioGroup from '@mui/material/RadioGroup';
// import { useGetCategoryQuery } from "../../api/categorySlice";
// import { useGetUnitQuery } from "../../api/unitSlice";
// import { useAddProductMutation, useGetProductQuery } from "../../api/productSlice";
import { useSelector, useDispatch} from 'react-redux';
// import { appendNewProduct } from "../../api/productListSlice";
import { Link, useNavigate } from 'react-router-dom';
import { useGetShopQuery } from "../../api/shopSlice";
import { addLstShop } from "../../api/storeSlice";
import Box from '@mui/material/Box';
import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';
// import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
// import FormHelperText from '@mui/material/FormHelperText';
import Checkbox from '@mui/material/Checkbox';
import { useAddUserMutation } from "../../api/userSlice";

const AddUser = () => {
  const { data, isLoading, isSuccess, refetch } = useGetShopQuery();
  const [addUser, result] = useAddUserMutation();
  const rtkLstShop = useSelector((state)=> state.store.lstShop);
  const dispatch = useDispatch();
  // Api Calling

  //Select Image

  const [openAlert, setOpenAlert] = useState(false);
  const [alertInfo, setAlertInfo] = useState("User name already exists.");
  const [shopId, setShopId ] = useState(0);
  const refUname = useRef();
  const refPhone = useRef();
  const refPassword = useRef();

  

  const [lstPermission, setLstPermission] = React.useState([{
      name: "Supplier",
      value: false,
    },
    {
      name: "Shop",
      value: false,
    },
    {
      name: "Category",
      value: false,
    },
    {
      name: "Unit",
      value: false,
    },
    {
      name: "Product",
      value: false,
    },
    {
      name: "Purchase",
      value: false,
    },
    {
      name: "Distribute",
      value: false,
    },
    {
      name: "Stock",
      value: false,
    },
    {
      name: "Currency",
      value: false,
    },
    {
      name: "Report",
      value: false,
    },
    {
      name: "User",
      value: false,
    },
    {
      name: "Setting",
      value: false,
    },
    ]);

  const handleChange = (event) => {
    console.log(event.target.name);
    const lstTemp = lstPermission.map(element => element.name == event.target.name?
      {...element, value: !element.value} :
      {...element}
    );
    setLstPermission(lstTemp);
    
  };


  const changeRolePermisson = () =>{ 
    
  }

  useEffect(()=>{
    if(!isLoading){
      // const rows = [{"id":0,"name":"Warehouse/Yangon"},...data?.data];
      dispatch(addLstShop(data?.data));
    }
  },[data?.data])
  

  const userHandler = async (e)  => {
    e.preventDefault();
    const permissionJson = {
      "shopId": shopId,
      "supplier":lstPermission[0].value,
      "shop":lstPermission[1].value,
      "category":lstPermission[2].value,
      "unit":lstPermission[3].value,
      "product":lstPermission[4].value,
      "purchase":lstPermission[5].value,
      "distribute":lstPermission[6].value,
      "stock":lstPermission[7].value,
      "currency":lstPermission[8].value,
      "report":lstPermission[9].value,
      "user":lstPermission[10].value,
      "setting":lstPermission[11].value
  }
    console.log(permissionJson);
    let body = {
      name: refUname.current.value,
      phone: refPhone.current.value,
      password: refPassword.current.value,
      role_id: shopId == 1 ? 2: 3,
      permissions: permissionJson,
      status: 1,
      saas: "futuretechmm",
    };
    console.log(body)

    await addUser(body);

    
  }
  
  useEffect(()=>{
    if(result.isSuccess == true){
      refUname.current.value = "";
      refPassword.current.value = "";
      refPhone.current.value = "";

      const lstTemp = lstPermission.map(element => { return {...element, value: false}});
      setLstPermission([...lstTemp]);
    }
  },[result]) 

  

  return (
    <div className="flex flex-col gap-4">
      <Collapse in={openAlert}>
        <Alert 
          severity="warning" 
          action={
            <div className='flex-1 items-center justify-centerw-12'  onClick={() => {
              setOpenAlert(false);
            }}>
                <AiOutlineCloseCircle size={25}/>
              </div>
          }>{alertInfo}</Alert>
      </Collapse>
      {/* Caption */}
      <div className="flex flex-row w-full justify-between">
        <p className="px-4 py-2 bg-[#57626c] rounded-lg text-white font-bold">
          New User
        </p>
        <Link to={`/user`}className='flex flex-col items-center text-center'>
          <Button 
                variant="outlined">
                {"User Table"}
          </Button>
        </Link>
      </div>

      {/* Add New Section */}
      <form onSubmit={userHandler} encType="multipart/form-data">
        <div className="flex flex-col w-full border-2 rounded-lg shadow-xs p-8">
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-8 w-full">
            
            <div className="flex-1 flex-col">
            <div className="mb-4">
                <Select variant="standard" label="Select Shop" size="lg" onChange={(e)=>{
                  setShopId(e)
                  }}>
                  {
                    rtkLstShop.map((shop, i) => (
                      <Option value={shop.id}>{shop.name}</Option>
                    ))
                    }
                </Select>
              </div>
              <div className="mb-4">
                <Input variant="standard" size="lg" label="Username" inputRef={refUname} required />
              </div>
              <div className="mb-4">
                <Input variant="standard" size="lg" label="Phone" inputRef={refPhone} required/>
              </div>
              <div className="mb-4">
                <Input variant="standard" size="lg" label="Password" inputRef={refPassword} required/>
              </div>
              
            </div>

            <div className="flex-1 flex-col">
              <div className="m-5" style={shopId != 1? {pointerEvents: "none", opacity: "0.4"} : {}}>
              <CheckboxesGroup/>
              </div>
              
            </div>
          </div>
          <div className="flex flex-row w-full justify-end items-center">
            <Button
              type="submit"
              variant="outlined"
              className="flex items-center gap-3"
              onClick={userHandler}
            >
              "Add User"
              {/* {result.isLoading ? <Spinner/> :"Add Product"} */}
            </Button>
          </div>
        </div>
      </form>
    </div>
  );
  
  function CheckboxesGroup() {
  return (
    <Box sx={{ display: 'flex' }}>
      <FormControl component="fieldset" variant="standard">
        <FormLabel component="legend">Details Permissions</FormLabel>
        <div className="grid grid-cols-3">
          {
            lstPermission.map(permit =>
              <FormControlLabel
                control={
                  <Checkbox checked={permit.value} onChange={handleChange} name={permit.name} />
                }
                label={permit.name}
              />
            )
            
          }
        </div>
        {/* <FormHelperText>Be careful</FormHelperText> */}
      </FormControl>
      
    </Box>
  );
}
};



export default AddUser