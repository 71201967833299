import React, { useState, useRef, useEffect } from 'react'
import {
    Button,
    Input,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    Typography,
    Drawer,
    IconButton,
    Spinner,
    Tooltip,
    Avatar,
    Select,
    Option,
  } from "@material-tailwind/react";

import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { IoReceiptSharp } from "react-icons/io5";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { BiTrash } from "react-icons/bi";
import { FaEdit } from "react-icons/fa";
import withReactContent from "sweetalert2-react-content";
import  SkeletonTable from "../../components/SkeletonTable";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Link, useNavigate } from 'react-router-dom';


import { useGetProductQuery } from '../../api/productSlice';
import { useGetSupplierQuery } from '../../api/supplierSlice';
import { useGetDistributeVoucherQuery, useAddDistributeVoucherMutation } from '../../api/distributeVoucherSlice';
import { useSelector, useDispatch} from 'react-redux';
import { addVoucher } from '../../api/purchaseDetailsSlice';

const DistributeRecords = () => {
    const {data, isLoading, isSuccess, refetch} = useGetDistributeVoucherQuery();

    const funShowVoucher = () =>{
        return false;
    }
    const [lstVoucher, setLstVoucher] = useState([]);
    useEffect(()=>{
        if(isSuccess){
            let voc = data?.data.filter(obj => {return obj.status == 1});
            const rows = voc?.map((obj, index) =>
            isSuccess
            ? {
                ...obj,
                no: index + 1,
                shop: obj.shop.name,
                }
            : { ...obj }
        );

            setLstVoucher(rows);
        }
        

    },[data?.data])

    //Table columns
    const columns = isSuccess
    ? [
        { field: "no", headerName: "No.", width: 100 },
        {
            field: "id",
            headerName: "Voucher No",
            flex: 1,
            editable: false,
        },
        {
            field: "shop",
            headerName: "Supplier",
            flex: 1,
            editable: false,
        },
        {
            field: "vouchercode",
            headerName: "Date",
            flex: 1,
            editable: false,
        },
        {
            field: "prodId",
            headerName: "Action",
            flex: 1,
            editable: false,
            renderCell: (params) => (
            <div className="flex flex-row gap-2">
                <Tooltip content="Details" className="flex-1 bg-green-500">
                    <Link to={`/distribute-history/${params.row.id}`} state={{id: params.row.id}} key={params.row.id} className='flex flex-col items-center text-center'>
                        <IconButton className="w-6 h-6 bg-green-200 p-4">
                            <IoReceiptSharp className="h-4 w-4 text-green-600 font-extrabold" />
                        </IconButton>
                    </Link>
                </Tooltip>
                <Tooltip content="Delete" className="flex-1 bg-red-500">
                    <IconButton variant="text" className="w-6 h-6 bg-red-200 p-4">
                        <BiTrash className="h-4 w-4 text-red-600" />
                    </IconButton>
                </Tooltip>
            </div>
            ),
        },
        ]
    : [];
  return (
    <div className="flex flex-col gap-4 px-16">
      {/* Caption */}
      <div className="flex flex-row w-full justify-start">
        <p className="px-4 py-2 bg-[#57626c] rounded-lg text-white font-bold">
          Purchase Records
        </p>
      </div>


      {/* Supplier List Table */}
      <div className="w-full h-full">
        {isSuccess ? (
          <DataGrid
            rows={lstVoucher}
            columns={columns}
            rowHeight={80}
            onRowDoubleClick={(params)=> <Link to={`/purchase-details/${params.row.id}`} key={params.row.id}></Link>}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 10,
                },
              },
            }}
            density="compact"
            pageSizeOptions={[5,10,25,50,100]}
            disableRowSelectionOnClick
            slots={{ toolbar: GridToolbar }}
            slotProps={{
              toolbar: {
                showQuickFilter: true,
                quickFilterProps: { debounceMs: 200 },
              },
            }}
          />
        ) : (
          <></>
        )}
      </div>

    </div>
  )
}

export default DistributeRecords