import React,{useState, useEffect} from 'react'
import { useSelector, useDispatch} from 'react-redux';
import {addLstProduct} from '../../api/productListSlice';
import { useGetProductQuery } from '../../api/productSlice';
import CircularProgress from '@mui/material/CircularProgress';
import { Bars } from 'react-loader-spinner';
import { Navigate } from 'react-router-dom';
import { useGetCurrencyQuery } from '../../api/currencySlice';
import { addLstCurrency } from "../../api/storeSlice";
import axios from "axios";

const Splash = () => {
  const { data:dataCury, isLoading:isLoadingCury , isSuccess:isSuccessCury, refetch:refetchCury} = useGetCurrencyQuery();
  const { data, isLoading, isSuccess, refetch } = useGetProductQuery();
  // useEffect(() => {
  //   axios.get("https://pos-vendor.fruitysense.com/pos-vendor-backend/public/api/product").then((response) => {
  //     console.log(response)
  //     const rows = response.data?.data.map((p, index) =>
  //       true
  //         ? {
  //             ...p,
  //             no: index + 1,
  //             categoryName: p.category.name,
  //             unitName: p.unit.name,
  //             prodId: p.id,
  //             sell_price: p.last_purchase != null ? ((p.last_purchase.price + ( p.last_purchase.price * (p.remark/100)))* dataCury?.data[p.last_purchase.note-1].ChangePrice): 0,
  //           }
  //         : { ...p }
  //     );
  //     dispatch(addLstProduct(rows));
  //   });
  // }, []);
  
  const lstProduct = useSelector((state) => state.productList.lstProduct);
  const dispatch = useDispatch();

  // Create list of product data to show on table
  useEffect(() => {
    if (isSuccessCury) {

      dispatch(addLstCurrency(dataCury?.data));
    }
  }, [dataCury?.data]);

  // Create list of product data to show on table
  useEffect(() => {
    if (isSuccess) {
      const rows = data?.data.map((p, index) =>
        true
          ? {
              ...p,
              categoryName: p.category.name,
              unitName: p.unit.name,
              prodId: p.id,
              locate: p.location != null ? p.location.location: "Unknown",
              // sell_price: p.last_purchase != null ? ((p.last_purchase.price + ( p.last_purchase.price * (p.remark/100)))* p.currency): 0,
              sell_price: p.last_purchase != null ? (Math.ceil(((p.last_purchase.price + ( p.last_purchase.price * (p.remark/100)))* p.currency)/100)*100): 0,
              
            }
          : { ...p }
      );
      dispatch(addLstProduct(rows));
    }
  }, [data?.data]);
  return (
    <div className='flex flex-col justify-center items-center w-full h-full'>
      {lstProduct.length == 0 ? 
        <>
          <Bars
        height="80"
        width="80"
        radius="9"
        color="green"
        ariaLabel="loading"
        wrapperStyle
        wrapperClass
        
      />
      <p className='text-green-500'>Loading data... Please wait.</p>
      </>
      
      :
      <div>
        success
      <Navigate replace to="/dashboard" />
      </div>}
    </div>
  )
}

export default Splash