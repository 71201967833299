import { configureStore,  } from "@reduxjs/toolkit";
import { ApiSlice } from '../api/ApiSlice';
import  purchaseDetailsReducer  from "../api/purchaseDetailsSlice";
import distributeDetailsReducer from "../api/distributeDetailsSlice";
import productListReducer from "../api/productListSlice";
import  currencyStoreReducer from "../api/currencyStoreSlice";
import storeReducer from '../api/storeSlice';

const store =  configureStore({
    reducer:{
        [ApiSlice.reducerPath] : ApiSlice.reducer,
        store: storeReducer,
        purchaseDetails: purchaseDetailsReducer,
        distributeDetails: distributeDetailsReducer,
        productList: productListReducer,
        currencyStore: currencyStoreReducer,
        
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(ApiSlice.middleware),
});

export default store