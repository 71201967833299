import React, { useState, useRef, useEffect } from "react";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { useSelector, useDispatch } from "react-redux";
import { useGetDailyReportByIdMutation } from "../../api/reportSlice";
import { useGetShopQuery } from "../../api/shopSlice";
import Select from 'react-select';
import { BiEdit } from "react-icons/bi";
import { FaEdit } from "react-icons/fa";
import  SkeletonTable from "../../components/SkeletonTable";
import {
  Drawer,
  Button,
  Avatar,
  Tooltip,
  Input,
  Option,
  Spinner,
  IconButton,
  Typography,
  Card,
} from "@material-tailwind/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import {
  LinearProgress,
  Dialog,
  AppBar,
  Toolbar,
  Slide,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
} from '@mui/material';
const ClientDailyReport = () => {
    const { data:dataShop, isLoading:isLoadingShop, isSuccess:isSuccessShop, refetch:refetchShop } = useGetShopQuery();
    const [trigger , {data, isLoading, isSuccess, refetch}] = useGetDailyReportByIdMutation();
    

    const [lstShop, setLstShop] = useState([]);
    const [lstProduct, setLstProduct] = useState([]);
    const [loading, setLoading] = useState(true);
    const [shopId, setShopId] = useState();

    useEffect(()=>{
        setLstShop([]);
        dataShop?.data.map((shop,i)=> {
        setLstShop(current => [...current, {value: shop, label: shop.name}])

      });

      
    },[dataShop?.data]);

      //Create list of product data to show on table
//   useEffect(() => {
//     if(isSuccessStock){
//         setLstProduct([]);
//         console.log(dataStock?.data)
//         const rows = dataStock?.data.map((p, index) =>
//         true
//             ? {
//                 ...p,
//                 id: p.product_id,
//                 no: index + 1,
//                 img: p.product.img,
//                 name:p.product.name,
//                 barcode: p.product.barcode,
//                 sell_price1: p.product.sell_price1,
//                 categoryName: p.product.category.name,
//                 unitName: p.product.unit.name,
//                 prodId: p.product.id,
//             }
//             : { ...p }
//         );
//         setLstProduct([...rows]);
//         setLoading(false)
//     }
//     },[dataStock?.data]);

    

const [lstDailyReport, setLstDailyReport] = useState([]);

useEffect(()=>{
    if(isSuccess){
      const rows = data?.data.map((r, index) =>
      r.expense.length
        ? {
            ...r,
            id: index + 1,
            saleamount: parseInt(r.saleamount),
            billamount: parseInt(r.billamount),
            income: parseInt(r.expense[0].income_amount),
            expense: parseInt(r.expense[0].expense_amount),
            remain: (parseInt(r.saleamount) + parseInt(r.expense[0].income_amount)) - (parseInt(r.billamount) + parseInt(r.expense[0].expense_amount) + parseInt(r.discountamount)),
          }
        : { 
          ...r ,
          id: index + 1,
          saleamount: parseInt(r.saleamount),
            billamount: parseInt(r.billamount),
          income: 0,
          expense: 0,
          remain: parseInt(r.saleamount) - parseInt(r.billamount)
          }
    );
      setLstDailyReport(rows);
    }
  

},[data?.data])

//Table columns
const columns = isSuccess
  ? [
      { field: "id",
        headerName: "No", 
        align: 'center',
        headerAlign: 'center',
        flex: 1,
      },
      {
        field: "days",
        headerName: "Day",
        flex: 1,
        editable: false,
        align: 'center',
        headerAlign: 'center'
      },
      {
          field: "vouchercount",
          headerName: "Voucher Count",
          flex: 1,
          editable: false,
          align: 'center',
          headerAlign: 'center'
          },
          {
          field: "saleamount",
          headerName: "Sale Amount",
          type: 'number',
          flex: 1,
          editable: false,
          align: 'center',
          headerAlign: 'center'
          },
          {
          field: "billamount",
          headerName: "Bill Amount",
          type: 'number',
          flex: 1,
          editable: false,
          align: 'center',
          headerAlign: 'center'
          },
          {
              field: "discountamount",
              headerName: "Discount",
              type: 'number',
              flex: 1,
              editable: false,
              align: 'center',
              headerAlign: 'center'
              },
          {
              field: "income",
              headerName: "Income",
              type: 'number',
              flex: 1,
              editable: false,
              align: 'center',
              headerAlign: 'center'
              },
              {
                  field: "expense",
                  headerName: "Expense",
                  type: 'number',
                  flex: 1,
                  editable: false,
                  align: 'center',
                  headerAlign: 'center'
                  },
                  {
                      field: "remain",
                      headerName: "Remain",
                      type: 'number',
                      flex: 1,
                      editable: false,
                      align: 'center',
                      headerAlign: 'center'
                      },

      {
        field: "action",
        headerName: "Action",
        flex: 1,
        editable: false,
        align: 'center',
        headerAlign: 'center',
        renderCell: (params) => (
          <div className="flex flex-row gap-2 justify-center items-center">
            {/* <Tooltip content="Edit" className="flex-1 bg-green-500">
              <IconButton

                onClick={() => {
                  // editCategoryRef.current.value = params.row.name;

                  // openDrawer(params.row);
                }}
                className="w-6 h-6 bg-green-200 p-4"
              >
                <FaEdit className="h-4 w-4 text-green-600 font-extrabold" />
              </IconButton>
            </Tooltip> */}
            
          </div>
        ),
      },
    ]
  : [];


      
  
    return (
      <div className="flex flex-col gap-4">
        
        
        {/* Caption */}
        <div className="flex flex-row w-full justify-start">
          <p className="px-4 py-2 bg-[#57626c] rounded-lg text-white font-bold">
            Client Shop Daily Report
          </p>
        </div>

        {/* Select Shop Section */}
      <div className="w-full px-6 py-2 h-20 border-2 border-gray-200 xl:flex xl:flex-row gap-2 items-end grid grid-cols-1 justify-center rounded-xl">
        {/* <form className="flex flex-row items-end" action={supplierHandler}> */}
        <div className="flex-1 m-2">
            <Select
                classNamePrefix="select"
                name="Shop"
                options={lstShop}
                onChange={(e)=> {
                    setLstProduct([])
                    setLoading(true)
                    console.log(e.value)
                    setShopId(e.value.id)
                    trigger(e.value.id)
                }}
                />
          </div>
          
        {/* </form> */}
      </div>
  

      {/* Category List Table */}
      <div className="w-full">
        <Card className="overflow-scroll h-full w-full">
          {isLoading ? (
            <SkeletonTable/>
          ) : (
            isSuccess ? (
              <DataGrid
                rows={lstDailyReport}
                columns={columns}
                rowHeight={80}
                density="compact"
                initialState={{
                  pagination: {
                    paginationModel: {
                      pageSize: 10,
                    },
                  },
                }}
                pageSizeOptions={[5,10,25,50,100]}
                disableRowSelectionOnClick
                slots={{ toolbar: GridToolbar }}
                slotProps={{
                  toolbar: {
                    showQuickFilter: true,
                    quickFilterProps: { debounceMs: 200 },
                  },
                }}
              />
            ) : (
              <></>
            )
          )}
        </Card>
      </div>
    </div>
  );
};

export default ClientDailyReport