import React, { useState, useRef, useEffect } from 'react'
import {
    Button,
    Input,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    Typography,
    Drawer,
    IconButton,
    Spinner,
    Tooltip,
    Avatar,
    Select,
    Option,
  } from "@material-tailwind/react";
import { useParams,useLocation } from 'react-router-dom'
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { IoReceiptSharp } from "react-icons/io5";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { BiTrash } from "react-icons/bi";
import { FaEdit } from "react-icons/fa";
import withReactContent from "sweetalert2-react-content";
import  SkeletonTable from "../../components/SkeletonTable";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Link, useNavigate } from 'react-router-dom';


import { useGetProductQuery } from '../../api/productSlice';
import { useGetSupplierQuery } from '../../api/supplierSlice';
import { useGetPurchaseVoucherQuery, useAddPurchaseVoucherMutation } from '../../api/purchaseSlice';
import { useSelector, useDispatch} from 'react-redux';
import { addVoucher } from '../../api/purchaseDetailsSlice';
import PrintDistribute from './PrintDistribute';
import { useReactToPrint } from 'react-to-print';
import { useGetDistributeVoucherByIdQuery } from '../../api/distributeVoucherSlice';

const DistributionHistory = () => {
  //get object parameter from previous page
  const {state} = useLocation();
  const {data, isLoading, isSuccess, refetch} = useGetDistributeVoucherByIdQuery(state.id);

  const [voucher, setVoucher] = useState(null);
  const [lstProduct, setLstProduct] = useState([]);
  useEffect(()=>{
      if(isSuccess)
      {
          setVoucher();
          setLstProduct([]);
          setVoucher(data?.data);
          let pdt = data?.data.details.map((pdt, index) =>isSuccess ? {
              ...pdt,
              no: index +1,
              name: pdt.product.name,
              barcode: pdt.product.barcode,
              amount: pdt.qty * pdt.price,
          }:
          {...pdt}
          );
          setLstProduct(pdt);
          console.log("List of pdt");
          console.log(lstProduct);
      }
      
  }, [data?.data]);

  //Table columns
const columns = isSuccess
? [
    { field: "no", headerName: "No.", width: 100 },
    {
      field: "name",
      headerName: "Product Name",
      flex: 1,
      editable: false,
    },
    {
      field: "barcode",
      headerName: "Barcode",
      width: 150,
      editable: false,
    },
    {
      field: "price",
      headerName: "Price",
      sortable: true,
      width: 150,
      editable: false,
    },
    {
      field: "qty",
      headerName: "Qty",
      width: 150,
      editable: false,
    },
    {
      field: "amount",
      headerName: "Total",
      width: 150,
      editable: false,
    }
  ]
: [];

const componentRef = useRef();
    const handlePrint = useReactToPrint({
      content: () => componentRef.current,
    });



return (
  <div className="flex flex-col gap-4 px-16">
    
    {/* Caption */}
    <div className="flex flex-row w-full justify-between items-center">
      <p className="px-4 py-2 bg-green-500 rounded-lg text-white font-bold">
        Voucher Id - {data?.data.id}/ Date - {voucher?.vouchercode}
      </p>
      <Button type="submit"
                size='sm'
                className='flex-none px-4 py-2 bg-green-500 rounded-lg text-white font-bold'
                onClick={handlePrint}
              >
                Print
        </Button>
    </div>

    {/* Add New Section */}
    <div className="p-2 h-22 border-2 border-gray-200 xl:flex xl:flex-row gap-1 items-end grid grid-cols-1 justify-center rounded-xl">
      <div className="flex-1">
          <Input
            size="sm"
            label="Shop"
            value={voucher?.shop.name}
            readOnly
          />
        </div>

        <div className="flex-1">
          <Input
            size="sm"
            label="Address"
            value={voucher?.shop.address}
            readOnly
          />
        </div>
        <div className="flex-1">
          <Input
            size="sm"
            label="Phone"
            value={voucher?.shop.phone}
            readOnly
          />
        </div>

        
    </div>

    {/* Shop List Table */}
    <div className="w-full">
      <Card className="overflow-scroll h-full w-full">
        {isLoading ? (
          <SkeletonTable/>
        ) : (
          isSuccess ? (
            <DataGrid
              rows={lstProduct}
              columns={columns}
              rowHeight={80}
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 10,
                  },
                },
              }}
              density="compact"
              pageSizeOptions={[5,10,25,50,100]}
              disableRowSelectionOnClick
              slots={{ toolbar: GridToolbar }}
              slotProps={{
                toolbar: {
                  showQuickFilter: true,
                  quickFilterProps: { debounceMs: 200 },
                },
              }}
            />
          ) : (
            <></>
          )
        )}
      </Card>
    </div>
    {/* Printing Section */}
    <div style={{ display: "none" }}> // This make ComponentToPrint show   only while printing
        { voucher != null ? <PrintDistribute ref={componentRef} voucher={{ voc: voucher, cashier:"Admin",  }} items={lstProduct}/>: <></>}
      </div>
  </div>
)
}

export default DistributionHistory