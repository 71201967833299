import { ApiSlice } from "./ApiSlice";

export const supplierSlice = ApiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getSupplier: builder.query({
            query: () => '/supplier',
            providesTags: ['supplier'],
        }),

        getSupplierById: builder.query({
            query: (id) => `/supplier/${id}`,
            providesTags: ['supplier'],
        }),

        getSupplierStatement: builder.mutation({
            query: (id) => ({
                url: `/supplier-statement/${id}`,
                method: 'GET',
            }),
            providesTags: ['supplierStatement'],
        }),

        addSupplier: builder.mutation({
            query: (body) => ({
                url: '/supplier',
                method: 'POST',
                body,
            }),
            invalidatesTags: ['supplier'],
        }),

        editSupplier: builder.mutation({
            query: (body) =>({
                url: `/supplier/${body.id}`,
                method: 'POST',
                body
            }),
            invalidatesTags: ['supplier'],
        }),

        deleteSupplier: builder.mutation({
            query: (id) =>({
                url: `/supplier/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['supplier'],
        }),

    })
});

export const {
    useGetSupplierQuery,
    useGetSupplierByIdQuery,
    useGetSupplierStatementMutation,
    useAddSupplierMutation,
    useEditSupplierMutation,
    useDeleteSupplierMutation,
} = supplierSlice