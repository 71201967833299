import React, { useState, useRef, useEffect } from "react";
import {
  useGetShopQuery,
  useAddShopMutation,
  useEditShopMutation,
  useDeleteShopMutation,
} from "../../api/shopSlice";
import Swal from "sweetalert2";

import {
  Button,
  Input,
  Card,
  Typography,
  Drawer,
  IconButton,
  Spinner,
  Tooltip,
} from "@material-tailwind/react";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { IoAddCircleOutline } from "react-icons/io5";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { BiTrash } from "react-icons/bi";
import { FaEdit } from "react-icons/fa";
import withReactContent from "sweetalert2-react-content";
import  SkeletonTable from "../../components/SkeletonTable";
import { useSelector, useDispatch } from "react-redux";
import { addLstShop } from "../../api/storeSlice";

const Shop = () => {
  // Api Calling
  const { data, isLoading, isSuccess, refetch } = useGetShopQuery();
  const [addShop, result] = useAddShopMutation();
  const [editShop, editResult] = useEditShopMutation();
  const [deleteShop, deleteResult] = useDeleteShopMutation();
  const dispatch = useDispatch();

  const [lstShop, setLstShop] = useState([]);
  useEffect(()=>{
    if(!isLoading){
      setLstShop(data?.data)
      dispatch(addLstShop(data?.data));
    }
  },[data?.data])

  //Table columns
  const columns = isSuccess
    ? [
        { field: "id",
          headerName: "No", 
          align: 'center',
          headerAlign: 'center'
        },
        {
          field: "name",
          headerName: "Name",
          flex: 1,
          editable: false,
          align: 'center',
          headerAlign: 'center'
        },
        {
          field: "phone",
          headerName: "Phone",
          flex: 1,
          editable: false,
          align: 'center',
          headerAlign: 'center'
        },
        {
          field: "address",
          headerName: "Address",
          sortable: true,
          editable: false,
          flex: 1,
          align: 'center',
          headerAlign: 'center'
        },
        {
          field: "action",
          headerName: "Action",
          flex: 1,
          editable: false,
          align: 'center',
          headerAlign: 'center',
          renderCell: (params) => (
            <div className="flex flex-row gap-2 justify-center items-center">
              <Tooltip content="Edit" className="flex-1 bg-green-500">
                <IconButton

                  onClick={() => {
                    editShopNameRef.current.value = params.row.name;
                    editShopPhoneRef.current.value = params.row.phone;
                    editShopAddressRef.current.value = params.row.address;

                    openDrawer(params.row);
                  }}
                  className="w-6 h-6 bg-green-200 p-4"
                >
                  <FaEdit className="h-4 w-4 text-green-600 font-extrabold" />
                </IconButton>
              </Tooltip>
              <Tooltip content="Delete" className="flex-1 bg-red-500">
                <IconButton onClick={() => confirmDelete(params.row.id)} variant="text" className="w-6 h-6 bg-red-200 p-4">
                  <BiTrash className="h-4 w-4 text-red-600" />
                </IconButton>
              </Tooltip>
            </div>
          ),
        },
      ]
    : [];

  //Open Drawer to edit Shop
  const [editShopData, setEditShopData] = useState();
  const [open, setOpen] = useState(false);
  const [addNew, setAddNew] = useState(false);
  const openDrawer = (shop) => {
    setEditShopData(shop);
    editShopNameRef.current.value = shop.name;
    editShopPhoneRef.current.value = shop.phone;
    editShopAddressRef.current.value = shop.address;
    setOpen(true);
  };
  const closeDrawer = () => setOpen(false);

  //add Shop
  const shopNameRef = useRef();
  const shopPhoneRef = useRef();
  const shopAddressRef = useRef();
  const shopHandler = async () => {
    let body = {
      name: shopNameRef.current.value,
      phone: shopPhoneRef.current.value,
      address: shopAddressRef.current.value,
    };

    await addShop(body);
    shopNameRef.current.value = "";
    shopPhoneRef.current.value = "";
    shopAddressRef.current.value = "";

    refetch();
  };

  const pressEnterAddShop = (event) =>{
    if(shopAddressRef.current.value == ""){
      return;
    }
    if(event.key == 'Enter'){
      shopHandler();
    }
  }

  //Edit Shop
  const editShopNameRef = useRef();
  const editShopPhoneRef = useRef();
  const editShopAddressRef = useRef();
  const editShopHandler = async () => {
    let body = {
      id: editShopData.id,
      name: editShopNameRef.current.value,
      phone: editShopPhoneRef.current.value,
      address: editShopAddressRef.current.value,
      status: editShopData.status,
      note: editShopData.note,
    };

    await editShop(body);
    closeDrawer();
    refetch();
  };

  //Delete Shop
  const sweetAlert = withReactContent(Swal);
  const deleteShopHandler = async (id) => {
    await deleteShop(id);
    refetch();
  };

  const confirmDelete = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        deleteShopHandler(id);
        Swal.fire("Deleted!", "Your Shop has been deleted.", "success");
      }
    });
  };
  return (
    <div className="flex flex-col gap-4 px-16">
      <React.Fragment>
        <Drawer placement="right" open={open} onClose={closeDrawer}>
          <div className="mb-2 flex items-center justify-between p-4">
            <Typography variant="h5" color="blue-gray">
              Edit Shop
            </Typography>
            <IconButton variant="text" color="blue-gray" onClick={closeDrawer}>
              <XMarkIcon strokeWidth={2} className="h-5 w-5" />
            </IconButton>
          </div>
          <form className="flex flex-col gap-6 p-4">
            <Input
              type="text"
              label="Name"
              defaultValue={open ? editShopData.name : ""}
              inputRef={editShopNameRef}
            />
            <Input
              type="text"
              label="Phone"
              defaultValue={open ? editShopData.phone : ""}
              inputRef={editShopPhoneRef}
            />
            <Input
              type="text"
              label="Address"
              defaultValue={open ? editShopData.address : ""}
              inputRef={editShopAddressRef}
            />
            <Button onClick={editShopHandler}>
              {editResult.isLoading ? <Spinner /> : "Save"}
            </Button>
          </form>
        </Drawer>
      </React.Fragment>
      {/* Caption */}
      <div className="flex flex-row w-full justify-between">
        <p className="px-4 py-2 bg-[#57626c] rounded-lg text-white font-bold">
          Shop
        </p>
        <Button 
              variant="outlined" onClick={()=> setAddNew(!addNew)}>
              {addNew ? "Close": "Add New"}
        </Button>
      </div>

      {/* Add New Section */}
      <div className={`${addNew==  true ? 'w-full px-6 py-2 h-20 border-2 border-gray-200 xl:flex xl:flex-row gap-2 items-end grid grid-cols-1 justify-center rounded-xl': 'hidden'}`}>
        {/* <form className="flex flex-row items-end" action={shopHandler}> */}
        <div className="flex-1 m-2">
            <Input
              size="lg"
              label="Shop Name"
              inputRef={shopNameRef}
              required
            />
          </div>
          <div className="flex-1 m-2">
            <Input
              size="lg"
              label="Phone"
              inputRef={shopPhoneRef}
              required
            />
          </div>
          <div className="flex-1 m-2">
            <Input
              size="lg"
              label="Address"
              inputRef={shopAddressRef}
              onKeyDown={pressEnterAddShop}
              required
            />
          </div>
          <div className="flex-none m-2">
            <Button
            onClick={shopHandler}
              type="submit"
              variant="outlined"
              className="flex items-center gap-3 px-4 py-2 m-1"
            >
              {result.isLoading ? (
                <Spinner color="indigo" />
              ) : (
                <IoAddCircleOutline strokeWidth={2} className="h-5 w-5" />
              )}
              Add
            </Button>
          </div>
        {/* </form> */}
      </div>

      {/* Shop List Table */}
      <div className="w-full">
        <Card className="overflow-scroll h-full w-full">
          {isLoading ? (
            <SkeletonTable/>
          ) : (
            isSuccess ? (
              <DataGrid
                rows={lstShop}
                columns={columns}
                rowHeight={80}
                density="compact"
                initialState={{
                  pagination: {
                    paginationModel: {
                      pageSize: 10,
                    },
                  },
                }}
                pageSizeOptions={[5,10,25,50,100]}
                disableRowSelectionOnClick
                slots={{ toolbar: GridToolbar }}
                slotProps={{
                  toolbar: {
                    showQuickFilter: true,
                    quickFilterProps: { debounceMs: 200 },
                  },
                }}
              />
            ) : (
              <></>
            )
          )}
        </Card>
      </div>
    </div>
  )
}

export default Shop