import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

// const baseUrl = `${localStorage.getItem('url')}/api`;
const baseUrl = 'https://warehouse.futuretechmm.net/pos-vendor-backend/public/api'
// const baseUrl = `https://dev.futuretechmm.net/backend/public/api`;

// const baseUrl = `http://127.0.0.1:8000/api`;

// const auth = JSON.parse(localStorage.getItem("auth"));
// const auth = window.localStorage.getItem("token");
// const header = {
//     'Authorization' : auth==null ? "" : `Bearer ${auth.token}`,
//     'Accept':'*/*',
//     'Content-Type':'application/json',
//     'enctype':'multipart/form-data',
// }

// const createRequest = (url) => ({url, headers: header});

export const ApiSlice = createApi({
    reducerPath: 'api',
    baseQuery: fetchBaseQuery({ 
        baseUrl,
        prepareHeaders:(headers, { getState }) => {
            // console.log(auth)
            
            
            const token = (window.localStorage.getItem("token"));
            // If we have a token set in state, let's assume that we should be passing it.
            if (token) {
                headers.set('authorization', `Bearer ${token}`)
            }
            // headers.set("Access-Control-Allow-Origin" , "*");
            // headers.set("Content-Type",'Application/json');
            headers.set('Accept', `Application/json`);
            headers.set('enctype', `multipart/form-data`);
            // headers.set('Accept', `Application/json`);
            // headers.set('Content-Type',"multipart/form-data; charset=utf-8; boundary=" + Math.random().toString().substr(2));
           
            return headers
        },
    }),
    tagTypes: ['supplier', 'shop', 'unit', 'currency', 'unitJoin', 'product','purchaseVoc', 'distributeVoc', 'supplierStatement', 'daily-report', 'monthly-report'],
    endpoints: (build) => ({
        
    })
});