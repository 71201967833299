import React, { useState, useRef, useEffect } from 'react'
import {
    Button,
    Input,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    Typography,
    Drawer,
    IconButton,
    Spinner,
    Tooltip,
    Avatar,
    Select,
    Option,
  } from "@material-tailwind/react";

import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { IoAddCircleOutline } from "react-icons/io5";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { BiTrash } from "react-icons/bi";
import { FaEdit } from "react-icons/fa";
import {MdOutlineReceiptLong} from 'react-icons/md'
import withReactContent from "sweetalert2-react-content";
import  SkeletonTable from "../../components/SkeletonTable";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Link, useNavigate } from 'react-router-dom';


import { useGetProductQuery } from '../../api/productSlice';
import { useGetShopQuery } from '../../api/shopSlice';
import { useGetDistributeVoucherQuery, useAddDistributeVoucherMutation } from '../../api/distributeVoucherSlice';
import { useSelector, useDispatch} from 'react-redux';
import { addVoucher } from '../../api/distributeDetailsSlice';

const Distribution = () => {

    //Api Calling
    const { data:dataVoucher, isLoading:isLoadingVoucher, isSuccess:isSuccessVoucher, refetch:refetchVoucher } = useGetDistributeVoucherQuery();
    const { data:dataShop, isLoading:isLoadingShop, isSuccess:isSuccessShop, refetch:refetchShop } = useGetShopQuery();
    const [addDistributeVoucher, result] = useAddDistributeVoucherMutation();
    const [shopId, setShopId] = useState();
    const [distributeDate, setDistributeDate] = useState();
    const [preview, setPreview] = useState(null);
    const [selectedImage, setSelectedImage] = useState();
    const [createVc, setCreateVc] = useState(false);
  
    const dispatch = useDispatch();
  
    // const [lstProduct, setLstProduct] = useState([]);
    //   //Create list of product data to show on table
    //   useEffect(() => {
    //   if (!isLoading) {
  
    //       const rows = data?.data.map((p) =>
    //       isSuccess
    //           ? {
    //               ...p,
    //               categoryName: p.category.name,
    //               unitName: p.unit.name,
    //               prodId: p.id,
    //           }
    //           : { ...p }
    //       );
    //       setLstProduct([...rows]);
    //       console.log(rows);
    //   }
    //   }, [data?.data]);
  
  
    const navigate = useNavigate();
  
    const funEditVoucher = (vId) =>{
      const voucher = {
          voucherId: vId,
          lstProduct: []
      }
      dispatch(addVoucher(voucher));
  
  
  }
  
  
    //Create FormData to send data with file
    let formData = new FormData()
    const handleVoucher = async() =>{
      console.log(distributeDate)
      formData.append('shop_id', shopId)
      formData.append('vouchercode',distributeDate)
      formData.append('total', 0)
  
      await addDistributeVoucher(formData)
      setCreateVc(false)
      refetchVoucher()
    }
    
    const [lstVoucher, setlstVoucher] = useState([]);
    const [lstShop, setLstShop] = useState([]);
      //Create list of product data to show on table
      useEffect(() => {
          if (!isLoadingShop) {
              setLstShop(dataShop?.data);
          }
      },[dataShop?.data]);
     
    //filter the voucher
    useEffect(()=>{
      let voc = dataVoucher?.data.filter(obj => {
        return obj.status == 0;
      });
      setlstVoucher(voc);
    }, [dataVoucher?.data])
  
      //set Preview Image and set selected Image
    const PreviewUpload = (e)=>{
      const file = e.target.files[0]
      setSelectedImage(file);
      setPreview(URL.createObjectURL(file));
    }
  
  
  
    return (
      <div className="flex flex-col gap-4 px-16">
        {/* Caption */}
        <div className="flex flex-row w-full justify-start">
          <p className="px-4 py-2 bg-[#57626c] rounded-lg text-white font-bold">
            Product Distribution
          </p>
        </div>
  
        {/* Add New Section */}
        <div className="flex flex-col w-full px-6 py-2 h-22 border-2 border-gray-200 gap-2 items-center justify-center rounded-xl">
          <div className='flex w-full'>
            <Button
                  type="submit"
                  variant="outlined"
                  className="flex items-center gap-3 px-4 py-2 m-1 w-48"
                  onClick={()=> setCreateVc(true)}
                  >
                  {(
                      <IoAddCircleOutline strokeWidth={2} className="h-5 w-5" />
                  )}
                  Create Voucher
                  </Button>
          </div>
        </div>
  
        {/* Shop List Table */}
        <div className="grid grid-cols-2 lg:grid-cols-3 xl:grid-cols-7 w-full gap-2">
            { isSuccessVoucher ?  lstVoucher?.map((voc,i) => 
            <Link to={`/distribute-details/${voc.id}`} state={{voucher: voc}} key={voc.link}>
              <Card onClick={()=> funEditVoucher(voc.id)} className="flex flex-col items-center mt-6 border-2 border-green-500">
                  <div className='flex-1 flex-row justify-end w-full'>
                    <Typography variant="h6" color="dark-green" className="flex-1 text-end p-1">
                      {voc.vouchercode}
                    </Typography>
                  </div>
                  <MdOutlineReceiptLong className='w-2/3 h-2/3'/>
                  <Typography variant="h6" color="blue-gray text-center" className="mb-0">
                    {voc.shop.name}
                  </Typography>
                  <Typography variant="h6" color="blue-gray text-center" className="mb-0">
                    Voucher Id - {voc.id}
                  </Typography>
                  
            </Card>
            </Link>
            ) : <></>}
          
  
        </div>
  
        {/* Create Dialog Section */}
        <Dialog
          open={createVc}
          keepMounted
          onClose={()=> setCreateVc(false)}
          fullWidth={true}
          maxWidth={'md'}
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle className='text-blue'>Voucher Info</DialogTitle>
          <DialogContent>
            <div className='flex-1 flex-col justify-center w-full p-6'>
                <div className="flex-1 m-2">
                        <Select label="Shop" size="md" onChange={(e) => setShopId(e)}>
                        {
                            isSuccessShop? lstShop.map((shp, i) => (
                            <Option value={shp.id} key={shp.id}>{shp.name}</Option>
                            )): <Option>No Shop to Show</Option>
                            
                            }
                        </Select>
                    </div>
                <div className="flex-1 m-2 w-full">
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker format="YYYY-MM-DD" selected={distributeDate} onChange={(date) => setDistributeDate(date.format('YYYY-MM-DD'))} label="Purchase Date" className="pr-2" slotProps={{ textField: { size: 'small' } }}/>
                        </LocalizationProvider>
                    </div>
                <div className="flex-1 flex-row justify-around m-2">
                    
                </div>
            </div>
          </DialogContent>
          <DialogActions>
          <Button
                    type="submit"
                    variant="outlined"
                    className="flex items-center gap-3 px-4 py-2 m-1"
                    onClick={()=> setCreateVc(false)}
                    >
                    Cancle
                    </Button>
                    <Button
                    type="submit"
                    variant="outlined"
                    className="flex items-center gap-3 px-4 py-2 m-1"
                    onClick={()=> handleVoucher()}
                    >
                    {result.isLoading ? <Spinner/> :"Save"}
                    </Button>
          </DialogActions>
        </Dialog>
  
  
  
      </div>
      
      
      
    )
}

export default Distribution