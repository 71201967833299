import { ApiSlice } from "./ApiSlice";

export const productSlice = ApiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getProduct: builder.query({
            query: () => '/product',
            providesTags: ['product'],
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`,
                "Access-Control-Allow-Origin" : '*',
                'Accept': `Application/json`
            },
        }),

        getProductById: builder.mutation({
            query: (id) => ({
                url: `/product/${id}`,
                method: 'GET'
            }),
            providesTags: ['product']
        }),

        addProduct: builder.mutation({
            query: (body) => ({
                url: '/product',
                method: 'POST',
                body,
            }),
            invalidatesTags: ['product']
        }),

        editProduct: builder.mutation({
            query: (body) =>({
                url: `/product/${body.get("id")}`,
                method: 'POST',
                body
            }),
            invalidatesTags: ['product']
        
        }),

        deleteProduct: builder.mutation({
            query: (id) =>({
                url: `/product/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['product']
        }),

        getStockEachShop: builder.mutation({
            query: (id) =>({
                url: `/stock-product/${id}`,
                method: 'POST',
            }),
        }),

        addProductTransfer: builder.mutation({
            query: (body) => ({
                url: '/product-transfer',
                method: 'POST',
                body,
            }),
        }),

        getProductTransfer: builder.query({
            query: () => '/product-transfer',
            providesTags: ['product-transfer']
        }),

        editProductChainQty: builder.mutation({
            query: (body) =>({
                url: '/pchain-update-qty',
                method: 'POST',
                body
            })
        }),

        editProductFromStock: builder.mutation({
            query: (body) =>({
                url: `/product-from-stock/${body.get("id")}`,
                method: 'POST',
                body
            }),
            invalidatesTags: ['product']
        
        }),

        changeProfitPercent: builder.mutation({
            query: (body) => ({
                url: '/change-profit-percent',
                method: 'POST',
                body
            }),
            invalidatesTags: ['product']
        }),
        getLocation: builder.query({
            query: () => '/admin/location',
            providesTags: ['location'],
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`,
                "Access-Control-Allow-Origin" : '*',
                'Accept': `Application/json`
            },
        }),
        addLocation: builder.mutation({
            query: (body) => ({
                url: '/admin/location',
                method: 'POST',
                body,
            }),
        }),
        editLocation: builder.mutation({
            query: (body) =>({
                url: `/admin/location/${body.id}`,
                method: 'POST',
                body
            })
        }),
        deleteLocation: builder.mutation({
            query:(id) => ({
                url: `/admin/location/${id}`,
                method: 'DELETE',
            })
        }),
    })
});

export const {
    useGetProductQuery,
    useGetProductByIdMutation,
    useEditProductMutation,
    useAddProductMutation,
    useDeleteProductMutation,
    useGetStockEachShopMutation,
    usePrefetch,
    useAddProductTransferMutation,
    useGetProductTransferQuery,
    useEditProductChainQtyMutation,
    useEditProductFromStockMutation,
    useChangeProfitPercentMutation,
    useGetLocationQuery,
    useAddLocationMutation,
    useEditLocationMutation,
    useDeleteLocationMutation,
} = productSlice