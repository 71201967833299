import React,{ useEffect, useState} from 'react'
import { DataGrid, GridToolbar } from "@mui/x-data-grid";

const ShopCoreValue = () => {

    const [valueList,setValueList] = useState([]);
    const [loading,setLoading] = useState(false);

    const columns = [
        { 
            field: "id", 
            headerName: "No.", 
            width: 100,
            headerAlign: 'center',
            align: 'center', 
        },
        {
          field: "name",
          headerName: "Shop Name",
          flex: 1,
          editable: false,
        },
        {
          field: "valueamount",
          headerName: "Value Total Amount (Ks)",
          flex:1,
          editable: false,
          renderCell: (params) => (
            <div className='bg-green-100 text-green-600 px-4 py-1 rounded-lg leading-6 tracking-widest font-semibold'>
                { params.row.value[0]?.total ?? 0}
            </div>
          )
        },
      ];

    const getValueList = async ()=> {
        setLoading(true);
        const body = {};
       await fetch(
            "https://warehouse.futuretechmm.net/pos-vendor-backend/public/api/shop-price",
            {
              method: "POST",
              body: JSON.stringify(body),
              headers: {
                "Content-type": "application/json; charset=UTF-8",
              },
            }
          )
            .then((response) => response.json())
            .then((json) => {
                setValueList(json.data);
                setLoading(false);
            });
    }

    useEffect(() =>{
        getValueList();
    },[])

  return (
    <div className="flex flex-col gap-4">
      {/* Caption */}
      <div className="flex flex-row w-full justify-start">
          <p className="px-4 py-2 bg-[#57626c] rounded-lg text-white font-bold">
            Stock Core Value
          </p>
        </div>

        {/* Data Grid Panel */}
        <div className='flex flex-col border-2 rounded-lg'>
            {
            <DataGrid
               rows={valueList}
               columns={columns}
               initialState={{
                 pagination: {
                   paginationModel: {
                     pageSize: 10,
                   },
                 },
               }}
               density="compact"
               pageSizeOptions={[5, 10, 25, 50, 100]}
               disableRowSelectionOnClick
               slots={{ toolbar: GridToolbar }}
               loading={loading}
               slotProps={{
                 toolbar: {
                   showQuickFilter: true,
                   quickFilterProps: { debounceMs: 500 },
                 },
               }}
             />
            }
        </div>
    </div>
  )
}

export default ShopCoreValue
