import { ApiSlice } from "./ApiSlice";

export const unitSlice =  ApiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getUnit: builder.query({
            query: () => '/unit',
            providesTags: ['unit'],
        }),

        getUnitById: builder.query({
            query: (id) => '/unit/{id}',
            providesTags: ['unit'],
        }),

        addUnit: builder.mutation({
            query: (body) => ({
                url: '/unit',
                method: 'POST',
                body,
            }),
            invalidatesTags: ['unit'],
        }),

        editUnit: builder.mutation({
            query: (body) =>({
                url: `/unit/${body.id}`,
                method: 'POST',
                body
            }),
            invalidatesTags: ['unit'],
        }),

        deleteUnit: builder.mutation({
            query: (id) =>({
                url: `/unit/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['unit'],
        }),

    })
})

export const {
    useGetUnitQuery,
    useGetUnitByIdQuery,
    useAddUnitMutation,
    useEditUnitMutation,
    useDeleteUnitMutation,

} = unitSlice