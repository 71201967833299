import React from 'react'
import { useParams,useLocation } from 'react-router-dom'
import { useState, useEffect, useRef, forwardRef } from 'react';
import {  Button,Card, Drawer,Input, IconButton,  Spinner, Tooltip, CardHeader, CardBody, CardFooter } from '@material-tailwind/react';
import Select from 'react-select';
import Alert from '@mui/material/Alert';
import Collapse from '@mui/material/Collapse';
import { AiOutlineCloseCircle } from "react-icons/ai";
import { BiTrash } from "react-icons/bi";
import  SkeletonTable from "../../components/SkeletonTable";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { BsPlusSquare } from "react-icons/bs";
import { useGetProductQuery } from '../../api/productSlice';
import { useSelector, useDispatch} from 'react-redux';
import { addProduct2Voucher, removeProductFromVoucher,removeVoucher } from '../../api/distributeDetailsSlice';
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
//Date picker
import dayjs from 'dayjs';
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useAddDistributeCheckoutMutation } from '../../api/distributeCheckoutSlice';
import { useGetDistributeVoucherQuery } from '../../api/distributeVoucherSlice';
import { addLstProduct, reduceStock } from '../../api/productListSlice';
import { useReactToPrint } from "react-to-print";
import PrintDistribute from './PrintDistribute';

const DistributeDetails = () => {
  
    // Api Calling
    const { data, isLoading, isSuccess, refetch } = useGetProductQuery();
    const [ addDistributeItems, result] = useAddDistributeCheckoutMutation();
    const { data:dataVoucher, isLoading:isLoadingVoucher, isSuccess:isSuccessVoucher, refetch:refetchVoucher } = useGetDistributeVoucherQuery();
    //get object parameter from previous page
    const {state} = useLocation();

    const rtkLstProduct = useSelector((state)=> state.productList.lstProduct);

    const lstVoucher = useSelector((state) => state.distributeDetails.lstVoucher);
    const [cartProduct, setCartProduct] = useState([]);
    useEffect(()=>{
      
        const lstCart = lstVoucher.find(obj => {return obj.voucherId === state.voucher.id});
        console.log(lstCart)
        if(lstCart !== undefined)
        {
          setCartProduct(lstCart.lstProduct)
          let tAmount = 0;
          lstCart.lstProduct.map(obj => {
            tAmount += obj.amount
          })
          setTotalAmount(tAmount)
        }
        
        
    },[lstVoucher])

    useEffect(()=>{

    },[])

    const distributeCheckoutHandler = async() =>{
      
      let body = {
        "store_voucher_id": state.voucher.id,
        "shop_id": state.voucher.shop_id,
        "status": 1,
        "lstProduct": cartProduct
      }
      await addDistributeItems(body);
      refetchVoucher();
    


    }

    const componentRef = useRef();
    const handlePrint = useReactToPrint({
      content: () => componentRef.current,
    });

    useEffect(()=>{
      if(result.isSuccess){
        handlePrint();
        dispatch(removeVoucher(state.voucher.id));
        setTotalAmount(0);
        setCartProduct([]);
      }
    },[result])

    

    // useEffect(()=>{
    //   if(cartProduct.length > 0){
    //     const newList = cartProduct.map((obj,index) => {return {...obj, no:index +1}})
    //     setCartProduct(newList)
    //   }
      
    //   console.log(cartProduct)
    // },[cartProduct])
    const dispatch = useDispatch();

    const [openAlert, setOpenAlert] = useState(false);
    const [alertInfo, setAlertInfo] = useState("Not Enough Stock");

    const [shopId, setShopId] = useState(state.voucher.shop_id);
    const [distributeDate, setDistributeDate] = useState(state.voucher.vouchercode);
    const [selectedProduct, setSelectedProduct] = useState();
    
    
    //Get expired date from user
    const [totalAmount, setTotalAmount] = useState(0);

    const refPBarcode = useRef(0);
    const refPName = useRef(0);
    const refPCate = useRef(0);
    const refPUnit = useRef(0);
    const refStock = useRef(0);
    const refPrice = useRef(0);
    const refQty = useRef(0);


    useEffect(()=>{
      console.log(state.voucher)
      refQty.current.value = 1;
    },[])

   


    const funaddProductList = () =>{
      const stock = parseInt(selectedProduct.stock,10);

      //Check no stock
      if(stock == 0){
        setAlertInfo("No Stock in warehouse");
        setOpenAlert(true);
        return;
      }

      //Check not enouch stock
      if(refQty.current.value > stock){
        setAlertInfo("Not Enough Stock.");
        setOpenAlert(true);
        return;
      }

      const pdt = {
        voucherId: state.voucher.id,
        product: {...selectedProduct, no: cartProduct.length+1, qty: refQty.current.value,price: selectedProduct.last_purchase.price ,  amount: refQty.current.value * selectedProduct.last_purchase.price }
      }
      
      dispatch(reduceStock(pdt));
      dispatch(addProduct2Voucher(pdt));
      
        


    }


    
    const [lstProduct, setLstProduct] = useState([]);
    useEffect(()=>{
      setLstProduct([]);
      rtkLstProduct.map((product,i)=> {
      setLstProduct(current => [...current, {value: product, label: product.name + ", " + product.barcode+ ", " + product.unit.name}])

    });
  },[rtkLstProduct]);


    useEffect(()=>{
      if(isSuccess){
        const rows = data?.data.map((p, index) =>
        true
          ? {
              ...p,
              categoryName: p.category.name,
              unitName: p.unit.name,
              prodId: p.id,
              sell_price: p.last_purchase != null ? ((p.last_purchase.price + ( p.last_purchase.price * (p.remark/100)))* p.currency): 0,
            }
          : { ...p }
      );
        dispatch(addLstProduct(rows))
        
      }
    },[data?.data])


    //Table columns
  const columns = [
      { field: "no", headerName: "No", width: 80 },
      {
        field: "name",
        headerName: "Product Name",
        flex: 1,
      },
      {
        field: "price",
        headerName: "Buy Price",
        width: 100,
      },
      {
        field: "qty",
        headerName: "Qty",
        width:50,
      },
      {
        field: "amount",
        headerName: "Amount",
        width: 100,
      },
      {
        field: "id",
        headerName: "Action",
        width:70,
        editable: false,
        renderCell: (params) => (
          <div className="flex flex-row justify-center gap-2">
            <Tooltip content="Delete" className="flex-1 bg-red-500">
              <IconButton className="w-6 h-6 bg-red-200 p-4" onClick={()=>{
                
                const pdt = {
                  voucherId: state.voucher.id,
                  product: params.row
              }
                dispatch(removeProductFromVoucher(pdt))
                }}>
                <BiTrash className="h-4 w-4 text-red-600" />
              </IconButton>
            </Tooltip>
          </div>
        ),
      },
    ];
    
  return (
    <div className="flex flex-col gap-4 px-2">
      <Collapse in={openAlert}>
        <Alert 
          severity="warning" 
          action={
            <div className='flex-1 items-center justify-centerw-12'  onClick={() => {
              setOpenAlert(false);
            }}>
                <AiOutlineCloseCircle size={25}/>
              </div>
          }>{alertInfo}</Alert>
      </Collapse>


      {/* Caption */}
      <div className="flex flex-row w-full justify-start">
        <p className="px-4 py-2 bg-[#57626c] rounded-lg text-white font-bold">
          Purchase Voucher
        </p>
      </div>

      {/* Add New Section */}
      <div className="w-full px-6 py-2 h-22 border-2 border-gray-200 xl:flex xl:flex-row gap-2 items-end grid grid-cols-1 justify-center rounded-xl">
        {/* <form className="flex flex-row items-end" action={shopHandler}> */}
        <div className="flex-1 m-2">
            <Input 
                size="lg"
                label="Shop"
                value={state.voucher.shop.name}
                readOnly/>
          </div>
          <div className="flex-1 m-2">
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker format="YYYY-MM-DD" value={dayjs(distributeDate)} onChange={(date) => setDistributeDate(date.format('YYYY-MM-DD'))} label="Distribute Date" className="w-full" slotProps={{ textField: { size: 'small' } }}/>
            </LocalizationProvider>
          </div>
          <div className="flex-1 m-2">
            <Input 
              size="lg" 
              label="Total amount" 
              value={totalAmount} 
              defaultValue={0} 
              className='flex-1'/>
          </div>
          <div className="flex-none m-2">
            <Button
                type="submit"
                variant="outlined"
                className="flex-none"
                onClick={distributeCheckoutHandler}
              >
                {result.isLoading ? <Spinner/> :"Checkout"}
              </Button>
          </div>
        {/* </form> */}
      </div>

      {/* Supplier List Table */}
      <div className="w-full grid grid-cols-1 lg:grid-cols-2 gap-2">
        <div className='flex flex-col border-2 border-gray-200 rounded-xl p-4 items-center'>
            <div className="flex-initial w-32 xl:w-96 m-4">
                <Select
                classNamePrefix="select"
                defaultValue={lstProduct[0]}
                name="basicUnit"
                options={lstProduct}
                onChange={(e)=> {
                    setSelectedProduct(e.value);
                    refPBarcode.current.value = e.value.barcode;
                    refPName.current.value = e.value.name;
                    refPCate.current.value = e.value.category.name;
                    refPUnit.current.value = e.value.unit.name;
                    refStock.current.value = e.value.stock;
                    refPrice.current.value = e.value.last_purchase != null? e.value.last_purchase.price: 1;

                }}
                />
            </div>

            <div className="flex-initial flex-col w-full gap-2 py-2">
                <div className="flex lg:flex-row flex-col gap-4 mb-4 justify-between">
                    <Input variant="standard" size="lg" label="qty" type="number" inputRef={refQty}/>
                    <Button variant="outlined" onClick={funaddProductList}>Add</Button>
                </div>
            </div>

            <div className="flex-initial flex-col w-full gap-2">
                <div className='w-32 text-bold border border-dashed border-gray-500 rounded-lg p-2 mb-2'>
                    Product Details
                </div>
                <div className="flex lg:flex-row flex-col gap-4 mb-2 justify-between">
                    <Input variant="standard" size="lg" label="Stock" type="number" ref={refStock} defaultValue={refStock.current.value}/>
                    <Input variant="standard" size="lg" label="Price" type="number" ref={refPrice} defaultValue={refPrice.current.value}/>
                </div>
                <div className="flex lg:flex-row flex-col gap-4 mb-2 justify-between">
                    <Input variant="standard" size="lg" label="Barcode" ref={refPBarcode} defaultValue={refPBarcode.current.value}/>
                    <Input variant="standard" size="lg" label="Product Name" ref={refPName} defaultValue={refPName.current.value}/>
                </div>
                <div className="flex lg:flex-row flex-col gap-4 mb-2 justify-between">
                    <Input variant="standard" size="lg" label="Category" ref={refPCate} defaultValue={refPCate.current.value}/>
                    <Input variant="standard" size="lg" label="Unit" ref={refPUnit} defaultValue={refPUnit.current.value}/>
                </div>
                
            </div>
            
            
        </div>
        <div className=''>
          { cartProduct?.length > 0 ?  (
              <DataGrid
                rows={cartProduct}
                columns={columns}
                rowHeight={80}
                density="compact"
                initialState={{
                  pagination: {
                    paginationModel: {
                      pageSize: 10,
                    },
                  },
                }}
                pageSizeOptions={[5,10,25,50,100]}
                disableRowSelectionOnClick
                slots={{ toolbar: GridToolbar }}
                slotProps={{
                  toolbar: {
                    showQuickFilter: true,
                    quickFilterProps: { debounceMs: 200 },
                  },
                }}
              />
            ) : (
              <div className='flex flex-row w-full h-full justify-center items-center border-2 border-gray rounded-lg'>No items in current voucher</div>
            )
          }
        </div>
        
      </div>

      {/* Printing Section */}
      <div style={{ display: "none" }}> // This make ComponentToPrint show   only while printing
        <PrintDistribute ref={componentRef} voucher={{ voc: state.voucher, cashier:"Admin",  }} items={cartProduct}/>
      </div>
    </div>
  )
}







export default DistributeDetails